import React, { Fragment, useEffect, useState } from "react";
import { Layout } from "../Layout/Layout";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import FilterHeader from '../Header/FilterHeader';
import UploadExcel from './UploadFacilityDataExcel';
import NavHeader from "../Header/NavHeader";
import { IconButton, Tooltip } from '@mui/material';
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';

var moment = require('moment');

const tableRows = [
    "existingNetStorageArea", "netStorageAreaOccupied", "occupancyByArea", "totalUnits", "averageUnitSizeAvailable", "unitsOccupied", "occupancyByUnits", "averageUnitSizeOccupied", "varianceInUnitsOccupied", "monthlyStorageUnitIncome", "monthlyAverageStorageFeeRateActual", "averageStorageFeeRateActual", "avgRackRateIfFull", "varianceInDollar", "varianceInPercent", "lateFee", "adminCleaningFee", "merchandise", "insurance", "other", "totalOtherIncome", "grossStorageIncome", "otherIncomePerformance"
]

const rowHeadings = {
    "existingNetStorageArea": "Total Net Storage Area",
    "netStorageAreaOccupied": "Net Storage Area Occupied",
    "occupancyByArea": "Occupancy by Area",
    "totalUnits": "Total Number of Units",
    "averageUnitSizeAvailable": "Average Unit Size Available",
    "unitsOccupied": "No. of Units Occupied",
    "occupancyByUnits": "Occupancy by Units",
    "averageUnitSizeOccupied": "Average Unit Size Occupied",
    "varianceInUnitsOccupied": "Variance in Units Occupied",
    "monthlyStorageUnitIncome": "Monthly Effective Storage Unit Income",
    "monthlyAverageStorageFeeRateActual": "Monthly Average Storage Fee Rate - Actual",
    "averageStorageFeeRateActual": "Average Storage Fee Rate - Actual",
    "avgRackRateIfFull": "Gross Potential Rate",//"Average Rack Rate - If Full",
    "varianceInDollar": "Variance ($)",
    "varianceInPercent": "Variance (%)",
    "lateFee": "Late Fee",
    "adminCleaningFee": "Admin / Cleaning Fee",
    "merchandise": "Merchandise",
    "insurance": "Insurance",
    "other": "Other",
    "totalOtherIncome": "Total Other Income",
    "grossStorageIncome": "Gross Storage Income",
    "otherIncomePerformance": "Other Income Performance"
}

const usedFormula = {
    "existingNetStorageArea": "Uploaded Data", // C4
    "netStorageAreaOccupied": "Uploaded Data", // C5
    "occupancyByArea": "Total Net Storage Area / Net Storage Area Occupied", // C5/C4
    "totalUnits": "Uploaded Data", // C8
    "averageUnitSizeAvailable": "Total Net Storage Area / Total Number of Units",
    "unitsOccupied": "Uploaded Data", // C10
    "occupancyByUnits": "No. of Units Occupied / Total Number of Units",
    "averageUnitSizeOccupied": "Net Storage Area Occupied / No. of Units Occupied", // C5/C10
    "varianceInUnitsOccupied": "Average Unit Size Occupied - Average Unit Size Available ", // C12-C9
    "monthlyStorageUnitIncome": "Monthly Effective Storage Unit Income",
    "monthlyAverageStorageFeeRateActual": "Monthly Effective Storage Unit Income / Net Storage Area Occupied", // C15/C5
    "averageStorageFeeRateActual": "Monthly Average Storage Fee Rate - Actual * 12", // C16*12
    "avgRackRateIfFull": "Uploaded Data", // C18
    "varianceInDollar": "Monthly Average Storage Fee Rate - Actual - Gross Potential Rate ", // C16-C18
    "varianceInPercent": "(Variance ($) / Monthly Average Storage Fee Rate - Actual)*100", // C19/C16
    "lateFee": "Uploaded Data", // C22
    "adminCleaningFee": "Uploaded Data", // C23
    "merchandise": "Uploaded Data", // C24
    "insurance": "Uploaded Data", // C25
    "other": "Uploaded Data", // C26
    "totalOtherIncome": " Late Fee + Admin / Cleaning Fee + Merchandise + Insurance + Other", //SUM(C22:C26)
    "grossStorageIncome": "Total Other Income + Monthly Effective Storage Unit Income ",  // C27+C15
    "otherIncomePerformance": "(Total Other Income / Gross Storage Income )*100"
}

const sumHeadings = [
    "monthlyStorageUnitIncome", "lateFee", "adminCleaningFee", "merchandise", "insurance", "other", "totalOtherIncome", "grossStorageIncome", "otherIncomePerformance"
];

export default function ApiData() {
    const [apiData, setApiData] = useState([]);
    const [avgData, setAvgData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const [showExcelForm, setShowExcelForm] = useState(false);
    const [currentFacility, setCurrentFacility] = useState(null);
    const handleCloseExcel = () => setShowExcelForm(false);
    const handleUpload = () => {
        setShowExcelForm(true);
    }

    useEffect(() => {
        const portfolioId = localStorage.getItem('selectedPortfolio');
        const facilityId = localStorage.getItem('selectedFacility');

        if (portfolioId && portfolioId !=='') {
            setSelectedPortfolio(JSON.parse(portfolioId));
        }

        if (facilityId && facilityId !== '') {
            setSelectedFacility(JSON.parse(facilityId));
            getFacilityDetails(facilityId);
        }
    }, []);

    const initialValues = {
        portfolioId: '',
        id: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
    });

    const getFacilityDetails = async (facilityId) => {
        setLoading(true)
        try {
            const response = await axios.get(`api/apiData?facilityId=${facilityId}`);
            setLoading(false)
            const result = response.data;
            if (result.success) {
                // setApiData(result.data);
                // setAvgData(result.avgData);
                setCurrentFacility(result.facility || {})
                setApiData(result.data || []);
                setAvgData(result.avgData || []);
            } else {
                Swal.fire({
                    icon: 'error',
                    text: response.data.message || 'An error occurred while fetching api data!',
                });
            }
        } catch (err) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Server Error',
                text: err.message || err || 'Failed to connect to the server. Please try again later.',
            });
        }
    }


    const formatter = (value) => {
        const numericValue = parseFloat(value);
        const hasSixDigitsBeforeDecimal = Math.floor(Math.abs(numericValue)).toString().length >= 3;

        return hasSixDigitsBeforeDecimal ? formatterWithoutDecimals.format(value) : formatterWithDecimals.format(value)
    };

    const formatterWithoutDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const formatterWithDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const calculateSum = (heading) => {
        return apiData.reduce((sum, data) => {
            if (data[heading]) {
                return sum + parseFloat(data[heading]);
            }
            return sum;
        }, 0);
    };

    const calculateValue = (heading) => {
        const values = apiData.map(data => parseFloat(data[heading])).filter(value => !isNaN(value));
        const sum = values.reduce((acc, value) => acc + value, 0);
        if (["otherIncomePerformance"].includes(heading)) {
            return values.length ? (sum / values.length).toFixed(2) : 0;
        }
        return sum;
    };

    const [hideMonthsAfterThree, setHideMonthsAfterThree] = useState(true);
    const toggleVisibilityAfterThree = () => {
        setHideMonthsAfterThree((prevState) => !prevState);
    };
    const getVisibilityIcon = () => {
        return (
            <Tooltip
                title={hideMonthsAfterThree ? 'Show months 4-12' : 'Hide months 4-12'}
                style={{
                    color: 'white',
                    backgroundColor: '#2c383d',
                    padding: '4px',
                    marginLeft: '7px',

                }}
            >
                <IconButton className={hideMonthsAfterThree ? 'showMonths' : 'hideMonths'} onClick={toggleVisibilityAfterThree}>
                    {hideMonthsAfterThree ? (
                        <i className="bx bxs-right-arrow cursor-pointer"></i>
                    ) : (
                        <i className="bx bxs-left-arrow cursor-pointer"></i>
                    )}
                </IconButton>
            </Tooltip>
        );
    };

    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header
                    pageTitle={localStorage.getItem('selectedFacilityName')}
                    verificationStatus={localStorage.getItem('selectedFacilityVerificationStatus')}
                />
                <div className="container-xxl flex-grow-1 container-p-y">
                    {selectedFacility !== null ?
                        apiData.length > 0 ?
                            <React.Fragment>
                                {/* <NavHeader /> */}
                                <div className="bg-white p-3  mb-3">
                                    <div className="row">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 view">
                                            <div className="mb-2 d-flex justify-content-end">
                                                <div className="mb-2 d-flex justify-content-end me-2">
                                                    {/* <button type="button" className="btn btn-outline-primary btn-sm" onClick={handleUpload}>
                                                        <span className="tf-icons bx bx-upload"></span>  Upload Excel
                                                    </button> */}
                                                    {user.role !== "SuperAdmin" && currentFacility.isLocked === '1' ? (
                                                        <Tooltip 
                                                            title="This facility is locked. The action cannot be completed at this time."
                                                            size="small"
                                                        >
                                                            <span className='cursor-not-allowed-disabled'>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-outline-primary btn-md"
                                                                    disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                >
                                                                    <span className="tf-icons bx bx-upload"></span>&nbsp; Upload Excel
                                                                </button>
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <button
                                                            type="submit"
                                                            className="btn btn-outline-primary btn-sm"
                                                            disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                            onClick={handleUpload}
                                                        >
                                                            <span className="tf-icons bx bx-upload"></span>&nbsp; Upload Excel
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="facilityTable table-responsive wrapper">
                                                <table className="table table-bordered table-sm" id="Rj-scroll-fix">
                                                    <thead>
                                                        <tr key={"headers1"}>
                                                            <th style={{ width: '25%' }} className="sticky-col first-col" scope="col">12-Month Performance</th>
                                                            {apiData.map((values, keyIndex) => {
                                                                const isHidden = hideMonthsAfterThree && keyIndex >= 3 && keyIndex < 12;

                                                                if (isHidden) {
                                                                    if (keyIndex === 11) {
                                                                        return (
                                                                            <th scope="col" className="bg-white" key={"blank_" + keyIndex}>
                                                                                {getVisibilityIcon()} {/* Icon for toggling */}
                                                                            </th>
                                                                        );
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }

                                                                return (
                                                                    <th style={{ width: '10%' }} key={"head_th" + keyIndex}>
                                                                        Month {keyIndex + 1}
                                                                        {keyIndex === 11 && getVisibilityIcon()} {/* Add icon for Month 12 */}
                                                                    </th>
                                                                );
                                                            })}
                                                            <th style={{ width: '10%' }} scope="col">Total Past {apiData.length}</th>
                                                            <th scope="col" className="bg-white"></th>
                                                            <th style={{ width: '10%' }} scope="col">3 Month</th>
                                                            <th style={{ width: '10%' }} scope="col">6 Month</th>
                                                            <th style={{ width: '10%' }} scope="col">12 Month</th>
                                                        </tr>
                                                        <tr key={"headers2"}>
                                                            <th scope="col" className="sticky-col first-col">Month</th>
                                                            {apiData.map((values, keyIndex) => {
                                                                const isHidden = hideMonthsAfterThree && keyIndex >= 3 && keyIndex < 12;

                                                                if (isHidden) {
                                                                    if (keyIndex === 11) {
                                                                        return <th scope="col" className="bg-white" key={"blank_" + keyIndex}></th>;
                                                                    } else {
                                                                        return null;
                                                                    }
                                                                }

                                                                return (
                                                                    <th key={"head_th1" + keyIndex} scope="col">
                                                                        {moment(values.date).format('MMM YY')}
                                                                    </th>
                                                                );
                                                            })}
                                                            <th scope="col">month{apiData.length > 1 ? "s" : ''}</th>
                                                            <th scope="col" className="bg-white"></th>
                                                            <th style={{ width: '10%' }} scope="col">Average</th>
                                                            <th style={{ width: '10%' }} scope="col">Average</th>
                                                            <th style={{ width: '10%' }} scope="col">Average</th>
                                                        </tr>
                                                    </thead>


                                                    <tbody>
                                                        <tr key={`empty_1row`} height="20px;">
                                                            <td colSpan={apiData.length + avgData.length + 3} className="bg-white"></td>
                                                        </tr>
                                                        {tableRows.map((heading, rowIndex) => (
                                                            <Fragment key={rowIndex}>
                                                                <tr key={rowIndex}>
                                                                    <th style={{ width: '35%' }} scope="colspan=2" className="theme-color-bg sticky-col first-col">{rowHeadings[heading]}</th>
                                                                    {
                                                                        apiData.map((data, index) => {
                                                                            const isHidden = hideMonthsAfterThree && index >= 3 && index < 12;

                                                                            if (isHidden) {
                                                                                if (index === 11) {
                                                                                    return <td key={`td_${index}`} className="table-blank-space" style={{ borderBottom: '0px' }}></td>;
                                                                                } else {
                                                                                    return null;
                                                                                }
                                                                            }

                                                                            return (
                                                                                <td
                                                                                    title={usedFormula[heading]}
                                                                                    key={`td_${index}`}
                                                                                    className={`${["occupancyByArea", "varianceInUnitsOccupied", "monthlyStorageUnitIncome", "averageStorageFeeRateActual", "totalOtherIncome", "grossStorageIncome"].includes(heading)
                                                                                        ? "fw-600"
                                                                                        : ""
                                                                                        } ${["existingNetStorageArea", "netStorageAreaOccupied", "totalUnits", "unitsOccupied", "avgRackRateIfFull", "lateFee", "adminCleaningFee", "merchandise", "insurance", "other"].includes(heading) || heading === "monthlyStorageUnitIncome"
                                                                                            ? "bg-table-api"
                                                                                            : ""
                                                                                        }`}
                                                                                >
                                                                                    {
                                                                                        ["occupancyByArea", "occupancyByUnits", "varianceInPercent", "otherIncomePerformance", "varianceInUnitsOccupied"].includes(heading)
                                                                                            ? data[heading] ? data[heading].toFixed(2) + "%" : "0%"
                                                                                            : ["existingNetStorageArea", "netStorageAreaOccupied", "averageUnitSizeAvailable", "averageUnitSizeOccupied", "totalUnits", "unitsOccupied"].includes(heading)
                                                                                                ? ["totalUnits", "unitsOccupied"].includes(heading)
                                                                                                    ? data[heading].toFixed()
                                                                                                    : parseFloat(data[heading]).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                                                : heading == "averageStorageFeeRateActual"
                                                                                                    ? formatterWithDecimals.format(data[heading])
                                                                                                    : formatter(data[heading])
                                                                                    }
                                                                                </td>
                                                                            );
                                                                        })
                                                                    }
                                                                    <td className={`${["occupancyByArea", "varianceInUnitsOccupied", "monthlyStorageUnitIncome", "averageStorageFeeRateActual", "totalOtherIncome", "grossStorageIncome"].includes(heading)
                                                                        ? "fw-600"
                                                                        : ""
                                                                        }`}>
                                                                        {
                                                                            heading === "otherIncomePerformance"
                                                                                ? `${calculateValue(heading)}%`
                                                                                : sumHeadings.includes(heading)
                                                                                    ? formatter(calculateSum(heading))
                                                                                    : ''
                                                                        }
                                                                    </td>
                                                                    <td className="bg-white">
                                                                        {
                                                                            ["merchandise", "insurance"].includes(heading) ?
                                                                                heading === "merchandise" && (calculateValue('merchandise') > 0 && calculateValue('totalOtherIncome') > 0)
                                                                                    ? `${(calculateValue('merchandise') / calculateValue('totalOtherIncome') * 100).toFixed(2)}%`
                                                                                    : heading === "insurance" && (calculateValue('merchandise') > 0 && calculateValue('totalOtherIncome') > 0) //&& `${(calculateValue('merchandise')/calculateValue('totalOtherIncome')*100).toFixed(2)}%` 
                                                                                        ? `${(calculateValue('merchandise') / calculateValue('totalOtherIncome') * 100).toFixed(2)}%` : "50%"
                                                                                : ''
                                                                        }
                                                                    </td>
                                                                    {
                                                                        avgData.map((avgs, idx) => {
                                                                            return (
                                                                                <td key={`td_${idx}`} className={`${["monthlyStorageUnitIncome", "averageStorageFeeRateActual", "totalOtherIncome", "grossStorageIncome"].includes(heading) ? "fw-600" : ""
                                                                                    }`}>
                                                                                    {
                                                                                        // avgs[heading] 
                                                                                        // ? ["occupancyByArea","occupancyByUnits","varianceInPercent","otherIncomePerformance"].includes(heading) 
                                                                                        //     ? avgs[heading].toFixed(2) +"%" 
                                                                                        //     : formatter.format(avgs[heading].toFixed(2)) 
                                                                                        // : ''

                                                                                        avgs[heading]
                                                                                            ? ["occupancyByArea", "occupancyByUnits", "varianceInPercent", "otherIncomePerformance", "varianceInUnitsOccupied"].includes(heading)
                                                                                                ? heading === "varianceInPercent"
                                                                                                    ? avgs[heading].toFixed(2) + "%"
                                                                                                    : avgs[heading].toFixed(2) + "%"
                                                                                                : ["existingNetStorageArea", "netStorageAreaOccupied", "averageUnitSizeAvailable", "averageUnitSizeOccupied", "totalUnits", "unitsOccupied"].includes(heading)
                                                                                                    ? ["totalUnits", "unitsOccupied"].includes(heading)
                                                                                                        ? avgs[heading].toFixed()
                                                                                                        : avgs[heading].toLocaleString('en-AU', { "maximumFractionDigits": 2, "minimumFractionDigits": 2 })
                                                                                                    : formatter(avgs[heading])
                                                                                            : ''

                                                                                    }
                                                                                </td>
                                                                            );
                                                                        })
                                                                    }
                                                                </tr>
                                                                {["occupancyByArea", "varianceInUnitsOccupied", "varianceInPercent", "totalOtherIncome"].includes(heading) && (
                                                                    <tr key={`empty_${rowIndex}`} height="20px;" style={{ borderTop: '0px', borderRight: '0px', borderTopWidth: '0px' }}>
                                                                        <td colSpan={apiData.length + avgData.length + 3} className="bg-white" style={{ borderTop: '0px', borderRight: '0px', borderTopWidth: '0px' }}></td>
                                                                    </tr>
                                                                )}
                                                            </Fragment>
                                                        ))}
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </React.Fragment>
                            :
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                            <div className="mb-3 mt-3">
                                                <h4 style={{ color: "#697a8d" }}>No Data Available!</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                        <div className="mb-3 mt-3">
                                            <h4 style={{ color: "#697a8d" }}>Please Select a {selectedPortfolio === null ? "Portfolio" : "Facility"}!</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Layout>
            <UploadExcel show={showExcelForm} setShow={setShowExcelForm} handleClose={handleCloseExcel} getFacilityDetails={getFacilityDetails} facilityId={selectedFacility} currentFacility={currentFacility} loading={loading} setLoading={setLoading} />
        </>
    );
}