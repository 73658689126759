
import React, { useEffect, useState } from "react";
import { Layout } from "../Layout/Layout";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import UploadExcel from './UploadExcel';
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import NavHeader from "../Header/NavHeader";
import FilterHeader from '../Header/FilterHeader';
import { Tooltip } from "@mui/material";

var moment = require('moment');

const formatterWithoutDecimals = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const formatterWithDecimals = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const formatter =  new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

const formatValue = (value) => {
    const numericValue = parseFloat(value);
    const hasSixDigitsBeforeDecimal = Math.floor(Math.abs(numericValue)).toString().length >= 3;

    return hasSixDigitsBeforeDecimal
        ? formatterWithoutDecimals.format(value)
        : formatterWithDecimals.format(value);
};

const removeFormatting = (value) => {
    return value.toString().replace(/[^0-9.-]+/g, '');
}

export default function NonStorageInputSheet() {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const [nonOperatingInputResult, setNonOperatingInputResult] = useState([]);
    const [tenancies, setTenancies] = useState(5);
    const [totals, setTotals] = useState({});
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [showExcelForm, setShowExcelForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentFacility, setCurrentFacility] = useState({});
    const handleCloseExcel = () => setShowExcelForm(false);

    const portfolioId = localStorage.getItem('selectedPortfolio') ? parseInt(localStorage.getItem('selectedPortfolio')) : null;
    const facilityId = localStorage.getItem('selectedFacility') ? parseInt(localStorage.getItem('selectedFacility')) : null;

    useEffect(() => {

        if (portfolioId) {
            setSelectedPortfolio(JSON.parse(portfolioId));
        }

        if (facilityId) {
            setSelectedFacility(JSON.parse(facilityId));
            getFacilityDetails(facilityId)
            getCurrentFacilityDetails(facilityId)
        }
    }, [portfolioId, facilityId]);

    const initialValues = {
        portfolioId: '',
        id: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
    });

    const getFacilityDetails = async (facilityId) => {
        setLoading(true)
        if (!facilityId) {
            setLoading(false);
            return;
        }
        try {
            const res = await axios.get(`/api/nonStorageInput?facilityId=${facilityId}`);
            setLoading(false)
            if (res.data.success) {
                const result = res.data.data || [];
                setNonOperatingInputResult(result);
                setTenancies(result.length > 0 ? result.length : 5);
                setTotals(res.data.totals || {});
            } else {
                setNonOperatingInputResult([]);
                setTenancies(0);
                setTotals({});
            }
        } catch (err) {
            console.log(err);
            setLoading(false)
            // Swal.fire(err.message);
        }
    }

    const getCurrentFacilityDetails = (facilityId) => {
        setLoading(true)
        axios.get(`api/facility?id=${facilityId}`).then(res => {
            setLoading(false)
            if (res.data.success) {
                const result = res.data.data;
                setCurrentFacility(result)
            }
        })
    }

    const handleAddTenancy = () => {
        const prevTenancy = nonOperatingInputResult[nonOperatingInputResult.length - 1];
        if (prevTenancy && (prevTenancy.lettableArea <= 0)) {
            Swal.fire('Warning!', 'Please fill in the current tenancy data before adding a new one.', 'warning');
            return;
        }
        setTenancies(prevTenancies => prevTenancies + 1);
        setNonOperatingInputResult(prevResults => [
            ...prevResults,
            {
                name: `Tenancy ${prevResults.length + 1}`,
                lettableArea: 0,
                grossPassingRent: 0,
                grossMarketRentalRate: 0
                // name: `Tenancy ${tenancies + 1}`, 
                // lettableArea:100,
                // grossPassingRent:1000,
                // grossMarketRentalRate:100
            }
        ]);
    };

    const handleInputChange = (index, field, value) => {
        if (!selectedFacility) {
            Swal.fire('Warning!', 'Please Select a Facility!', 'warning');
            return;
        }
        setLoading(true);
        const updatedResults = [...nonOperatingInputResult];

        if (field === 'agentLeasingFee' || field === 'annualIncreases') {
            value = value.replace('%', '').trim();
        }

        if (field !== 'name' && field !== 'description' && field !== 'leaseExpiry' && field !== 'nextRentReview' && field !== 'annualIncreases' && field !== 'agentLeasingFee') {
            const isValidNumber = value === '' || !isNaN(value) ? /^-?\d*\.?\d*$/.test(value) : false;
            if (!isValidNumber) {
                Swal.fire('Error', 'Please enter a valid number', 'error');
                setLoading(false);
                return;
            }

            value = value === '' ? 0 : parseFloat(value);
            updatedResults[index] = { ...updatedResults[index], [field]: value };
            setNonOperatingInputResult(updatedResults);
        } else {
            updatedResults[index] = { ...updatedResults[index], [field]: value };
            setNonOperatingInputResult(updatedResults);
        }

        if (['lettableArea', 'grossPassingRent', 'grossMarketRentalRate', 'calculateAnnualGrossMarketRent'].includes(field)) {
            let grossPassingRent = removeFormatting(parseFloat(field === 'grossPassingRent' ? value : updatedResults[index].grossPassingRent || 0));
            let lettableArea = removeFormatting(parseFloat(field === 'lettableArea' ? value : updatedResults[index].lettableArea || 0));
            let grossPassingRentalRate = (lettableArea !== 0 && lettableArea > 0) ? (grossPassingRent / lettableArea) : 0;
            let grossMarketRentalRate = removeFormatting(parseFloat(field === 'grossMarketRentalRate' ? value : updatedResults[index].grossMarketRentalRate || 0));
            let calculateAnnualGrossMarketRent = lettableArea * grossMarketRentalRate;
            let adoptedGrossMarketRent = calculateAnnualGrossMarketRent > 0 ? calculateAnnualGrossMarketRent : grossPassingRent;
            let rentalShortfall = grossPassingRent - adoptedGrossMarketRent;

            if (field === 'calculateAnnualGrossMarketRent') {
                calculateAnnualGrossMarketRent = !isNaN(parseFloat(value)) ? removeFormatting(parseFloat(value)) : 0;
                grossMarketRentalRate = lettableArea !== 0 && lettableArea > 0 ? calculateAnnualGrossMarketRent / lettableArea : 0;
                adoptedGrossMarketRent = calculateAnnualGrossMarketRent > 0 ? calculateAnnualGrossMarketRent : grossPassingRent;
                rentalShortfall = grossPassingRent - adoptedGrossMarketRent;
            } else {
                calculateAnnualGrossMarketRent = (lettableArea > 0 && grossMarketRentalRate > 0) ? lettableArea * grossMarketRentalRate : 0;
            }

            const totalArea = updatedResults.reduce((total, tenancy) => total + (parseFloat(tenancy.lettableArea) || 0), 0);
            const totalPassingRent = updatedResults.reduce((total, tenancy) => total + (parseFloat(tenancy.grossPassingRent) || 0), 0);
            const totalMarketRent = updatedResults.reduce((total, tenancy) => {
                const grossMarketRentalRate = removeFormatting(parseFloat(tenancy.grossMarketRentalRate) || 0);
                const lettableArea = removeFormatting(parseFloat(tenancy.lettableArea) || 0);
                return total + (grossMarketRentalRate * lettableArea);
            }, 0);
            const varianceFromMarket = totalMarketRent !== 0 ? parseFloat((totalPassingRent - totalMarketRent) / totalMarketRent) : 0;

            updatedResults.forEach((tenancy) => {
                const lettableArea = removeFormatting(parseFloat(tenancy.lettableArea) || 0);
                const percentageOfTotalArea = totalArea > 0 ? ((lettableArea / totalArea) * 100).toFixed(2) : '0.00';
                const remainingLeaseTerm = parseFloat(tenancy.remainingLeaseTerm) || 0;
                const weightedByArea = remainingLeaseTerm * (percentageOfTotalArea / 100);

                tenancy.percentageOfTotalArea = parseFloat(percentageOfTotalArea);
                tenancy.weightedByArea = parseFloat(weightedByArea.toFixed(2));
            });

            updatedResults.forEach((tenancy) => {
                const grossPassingRent = removeFormatting(parseFloat(tenancy.grossPassingRent) || 0);
                const percentageOfTotalAdditionalIncome = totalPassingRent > 0 ? ((grossPassingRent / totalPassingRent) * 100).toFixed(2) : '0.00';
                const remainingLeaseTerm = parseFloat(tenancy.remainingLeaseTerm) || 0;
                const weightedByIncome = remainingLeaseTerm * (percentageOfTotalAdditionalIncome / 100);

                tenancy.percentageOfTotalAdditionalIncome = parseFloat(percentageOfTotalAdditionalIncome);
                tenancy.weightedByIncome = parseFloat(weightedByIncome.toFixed(2));
            });

            const updatedResult = {
                ...updatedResults[index],
                grossPassingRent: parseFloat(grossPassingRent),
                grossPassingRentalRate: parseFloat(grossPassingRentalRate),
                grossMarketRentalRate: parseFloat(grossMarketRentalRate),
                calculateAnnualGrossMarketRent: parseFloat(calculateAnnualGrossMarketRent),
                adoptedGrossMarketRent: parseFloat(adoptedGrossMarketRent),
                rentalShortfall: parseFloat(rentalShortfall),
            };
            updatedResults[index] = updatedResult;
            setNonOperatingInputResult(updatedResults);
        }

        if (['leaseExpiry'].includes(field)) {
            // const currentDate = moment();
            const formattedDate = moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : '';
            const currentDate = moment().format('YYYY-MM-DD');
            // const leaseExpiryDate = moment(updatedResults[index].leaseExpiry, 'YYYY-MM-DD');
            const leaseExpiryDate = moment(formattedDate, 'YYYY-MM-DD');
            const daysOfRemainingLeaseTerm = leaseExpiryDate.diff(currentDate, 'days');
            const calcRemainingLeaseTerm = daysOfRemainingLeaseTerm / 365.25;
            const remainingLeaseTerm = calcRemainingLeaseTerm > 0 ? calcRemainingLeaseTerm : 0;

            updatedResults.forEach((tenancy) => {
                const percentageOfTotalArea = parseFloat(tenancy.percentageOfTotalArea);
                const remainingLeaseTerm = parseFloat(tenancy.remainingLeaseTerm) || 0;
                const weightedByArea = remainingLeaseTerm * percentageOfTotalArea;

                tenancy.percentageOfTotalArea = parseFloat(percentageOfTotalArea.toFixed(2));
                tenancy.weightedByArea = parseFloat(weightedByArea.toFixed(2));
            });

            updatedResults.forEach((tenancy) => {
                const percentageOfTotalAdditionalIncome = parseFloat(tenancy.percentageOfTotalAdditionalIncome) || 0;
                const remainingLeaseTerm = parseFloat(tenancy.remainingLeaseTerm) || 0;
                const weightedByIncome = remainingLeaseTerm * percentageOfTotalAdditionalIncome;

                tenancy.percentageOfTotalAdditionalIncome = parseFloat(percentageOfTotalAdditionalIncome.toFixed(2));
                tenancy.weightedByIncome = parseFloat(weightedByIncome.toFixed(2));
            });

            const updatedResult = {
                ...updatedResults[index],
                leaseExpiry: formattedDate,
                calcRemainingLeaseTerm: parseFloat(calcRemainingLeaseTerm).toFixed(2),
                remainingLeaseTerm: parseFloat(remainingLeaseTerm).toFixed(2),
            };
            updatedResults[index] = updatedResult;
            setNonOperatingInputResult(updatedResults);
        }

        if (['nextRentReview'].includes(field)) {
            const formattedDate = moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : '';
            const currentDate = moment().format('YYYY-MM-DD');
            const nextRentReviewDate = moment(formattedDate, 'YYYY-MM-DD');
            const daysUntilNextRentReview = nextRentReviewDate.diff(currentDate, 'days');
            const calcTimeUntilNextRentReview = daysUntilNextRentReview / 365.25;
            const remainingLeaseTerm = updatedResults[index].remainingLeaseTerm;
            const timeUntilNextRentReview = calcTimeUntilNextRentReview > 0 ? calcTimeUntilNextRentReview : remainingLeaseTerm;
            // const currentDate = moment().format('DD-MMM-YY');
            // const nextRentReviewDate = moment(updatedResults[index].nextRentReview, 'YYYY-MM-DD');        
            const updatedResult = {
                ...updatedResults[index],
                nextRentReview: formattedDate,
                calcTimeUntilNextRentReview: parseFloat(calcTimeUntilNextRentReview).toFixed(2),
                timeUntilNextRentReview: parseFloat(timeUntilNextRentReview).toFixed(2),
            };
            updatedResults[index] = updatedResult;
            setNonOperatingInputResult(updatedResults);
        }

        if (['leaseExpiry', 'lettableArea'].includes(field)) {
            updatedResults.forEach((tenancy) => {
                const percentageOfTotalArea = parseFloat(tenancy.percentageOfTotalArea);
                const remainingLeaseTerm = parseFloat(tenancy.remainingLeaseTerm) || 0;
                const weightedByArea = remainingLeaseTerm * (percentageOfTotalArea / 100);

                tenancy.percentageOfTotalArea = parseFloat(percentageOfTotalArea.toFixed(2));
                tenancy.weightedByArea = parseFloat(weightedByArea.toFixed(2));
            });

            const waleByLettableArea = updatedResults.reduce((total, tenancy) => total + (parseFloat(tenancy.weightedByArea) || 0), 0);
            setTotals(prevTotals => ({
                ...prevTotals,
                waleByLettableArea: parseFloat(waleByLettableArea.toFixed(2)),
            }));
            setNonOperatingInputResult(updatedResults);
        }

        if (['leaseExpiry', 'grossPassingRent'].includes(field)) {
            updatedResults.forEach((tenancy) => {
                const percentageOfTotalAdditionalIncome = parseFloat(tenancy.percentageOfTotalAdditionalIncome);
                const remainingLeaseTerm = parseFloat(tenancy.remainingLeaseTerm) || 0;
                const weightedByIncome = remainingLeaseTerm * (percentageOfTotalAdditionalIncome / 100);

                tenancy.percentageOfTotalAdditionalIncome = parseFloat(percentageOfTotalAdditionalIncome.toFixed(2));
                tenancy.weightedByIncome = parseFloat(weightedByIncome.toFixed(2));
            });

            const waleByIncome = updatedResults.reduce((total, tenancy) => total + (parseFloat(tenancy.weightedByIncome) || 0), 0);
            setTotals(prevTotals => ({
                ...prevTotals,
                waleByIncome: parseFloat(waleByIncome.toFixed(2)),
            }));
            setNonOperatingInputResult(updatedResults);
        }

        if (field === 'lettableArea' && field !== 'grossPassingRent') {
            const totalArea = updatedResults.reduce((total, tenancy) => total + (parseFloat(tenancy.lettableArea) || 0), 0);
            updatedResults.forEach((tenancy) => {
                const lettableArea = parseFloat(tenancy.lettableArea) || 0;
                const percentageOfTotalArea = totalArea > 0 ? (lettableArea / totalArea) * 100: 0; 

                tenancy.percentageOfTotalArea = parseFloat(percentageOfTotalArea); 
            });
            setNonOperatingInputResult(updatedResults);
        }

        if (field === 'grossPassingRent'&& field !== 'lettableArea') {
            const totalPassingRent = updatedResults.reduce((total, tenancy) => total + (parseFloat(tenancy.grossPassingRent) || 0), 0);
            updatedResults.forEach((tenancy) => {
                const grossPassingRent = parseFloat(tenancy.grossPassingRent) || 0;
                const percentageOfTotalAdditionalIncome = totalPassingRent > 0 ? (grossPassingRent / totalPassingRent) * 100: 0; 
                tenancy.percentageOfTotalAdditionalIncome = parseFloat(percentageOfTotalAdditionalIncome); 
            });
            setNonOperatingInputResult(updatedResults);
        }

        // if (field === 'lettableArea') {
        //     const totalArea = updatedResults.reduce((total, tenancy) => total + (parseFloat(tenancy.lettableArea) || 0), 0);
        //     updatedResults.forEach((tenancy) => {
        //         const lettableArea = parseFloat(tenancy.lettableArea) || 0;
        //         tenancy.percentageOfTotalArea = totalArea > 0 ? (lettableArea / totalArea) * 100 : 0;
        //     });
        //     setNonOperatingInputResult([...updatedResults]);
        // } else if (field === 'grossPassingRent') {
        //     const totalPassingRent = updatedResults.reduce((total, tenancy) => total + (parseFloat(tenancy.grossPassingRent) || 0), 0);
        //     updatedResults.forEach((tenancy) => {
        //         const grossPassingRent = parseFloat(tenancy.grossPassingRent) || 0;
        //         tenancy.percentageOfTotalAdditionalIncome = totalPassingRent > 0 ? (grossPassingRent / totalPassingRent) * 100 : 0;
        //     });
        //     setNonOperatingInputResult([...updatedResults]);
        // }
        
        setLoading(false);
    };

    const handleBlur = (index, field, value) => {
        let rawValue = parseFloat(String(value).replace('%', '').trim());
        const formattedValue = !isNaN(rawValue) ? rawValue.toFixed(2) + '%' : '';
        const updatedResults = [...nonOperatingInputResult];
        updatedResults[index] = { ...updatedResults[index], [field]: rawValue }; // Storing raw value
        setNonOperatingInputResult(updatedResults);

        // Update the input field to display the formatted value
        value = formattedValue;
        // console.log("formattedValue-----------",formattedValue);
    };

    const calculateFacilityValuation = async (facilityId) => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/calculateFacilityValuation?facilityId=${facilityId}`);
            setLoading(false)
            let result = response.data;
            if (result.success) {
                console.log("response.data.data", response.data);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false)
            Swal.fire("Error", err.message, "error");
        }
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleSubmit = async (e) => {

        e.preventDefault();
        if (!selectedFacility) {
            Swal.fire('Warning!', 'Please Select a Facility!', 'warning');
            return;
        }

        // const validTenancies = nonOperatingInputResult.filter(tenancy => {
        //     return tenancy.name && tenancy.lettableArea > 0 && tenancy.grossPassingRent > 0 && tenancy.grossMarketRentalRate > 0;
        // });

        // if (validTenancies.length === 0) {
        //     Swal.fire('Nothing to Save!', 'Please fill in data for the tenancies and try again!', 'warning');
        //     return;
        // }
        // console.log("nonOperatingInputResult-----------",nonOperatingInputResult);

        // if( nonOperatingInputResult.length <= 0){
        //     Swal.fire('Nothing to Save!', 'Add data to the tenancies and try again!', 'warning');
        //     return;
        // }
        setLoading(true)

        // const tenanciesData = nonOperatingInputResult.map((tenancy, index) => ({
        //     ...tenancy,
        //     name: `Tenancy ${index + 1}`,
        // }));

        try {
            const response = await axios.put('/api/saveNonStorageInputs', {
                tenanciesData: nonOperatingInputResult,
                facilityId: selectedFacility,
            });
            if (response.data.success) {
                setLoading(false);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message || 'Update Successful!'
                });
                calculateFacilityValuation(selectedFacility)
                getFacilityDetails(selectedFacility);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: response.data.message || 'Update Failed!'
                });
                getFacilityDetails(selectedFacility);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false)
            // Swal.fire('Error', error.message, 'error');
            Toast.fire({
                icon: 'error',
                title: error.response?.data?.message || error.message
            });
        }
    };

    const handleUpload = () => {
        setShowExcelForm(true);
    }

    // These are used only for the purpose of displaying and storing decimal values for the Gross Passing Rental Rate ($/m²) input.
    const [inputValue, setInputValue] = useState({});

    const formatValueNew = (value) => {
        if (value === "" || isNaN(value)) return "$0";
        const parts = value.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `$${parts.join(".")}`; 
    };

    const removeFormattingNew = (value) => {
        return value.toString().replace(/[^0-9.-]+/g, '');
    };
    // end
    
    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header
                    pageTitle={localStorage.getItem('selectedFacilityName')}
                    verificationStatus={localStorage.getItem('selectedFacilityVerificationStatus')}
                />
                <div className="container-xxl  flex-grow-1 container-p-y pt-3">
                    {selectedFacility !== null ?
                        <React.Fragment>
                            <div className="mb-3 d-flex justify-content-end">
                                <div className="mb-2 d-flex justify-content-end">
                                    {user.role !== "SuperAdmin" && currentFacility.isLocked === '1' ? (
                                        <Tooltip 
                                            title="This facility is locked. The action cannot be completed at this time."
                                            size="small"
                                        >
                                            <span className='cursor-not-allowed-disabled'>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary me-2"
                                                    disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                >
                                                    Add Tenancy
                                                </button>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn btn-primary me-2"
                                            disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                            onClick={handleAddTenancy}
                                        >
                                            Add Tenancy
                                        </button>
                                    )}
                                </div>
                                <div className="mb-2 d-flex justify-content-end">
                                    {user.role !== "SuperAdmin" && currentFacility.isLocked === '1' ? (
                                        <Tooltip 
                                            title="This facility is locked. The action cannot be completed at this time."
                                            size="small"
                                        >
                                            <span className='cursor-not-allowed-disabled'>
                                                <button
                                                    type="submit"
                                                    className="btn btn-outline-primary btn-md"
                                                    disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                >
                                                    <span className="tf-icons bx bx-upload"></span>&nbsp; Upload Excel
                                                </button>
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn btn-outline-primary btn-sm"
                                            disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                            onClick={handleUpload}
                                        >
                                            <span className="tf-icons bx bx-upload"></span>&nbsp; Upload Excel
                                        </button>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                        : ""}

                    <div className="bg-white p-3 mb-3">
                        <div className="row">
                            <form id="formNonStorageInputTenancies" method="POST" onSubmit={handleSubmit}>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 view">
                                    <div className="facilityTable table-responsive wrapper mb-2">
                                        <table className="table table-bordered table-sm" id="Rj-scroll-fix">
                                            <thead>
                                                <tr>
                                                    <th className="table-blank-space sticky-col first-col" scope="col"></th>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        // <th key={index} scope="col" name={`name[${index}]`}>Tenancy {index + 1}</th>
                                                        <th key={index} scope="col" name={`name[${index}]`}>
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="text"
                                                                name={`name[${index}]`}
                                                                className="form-control form-control-sm theme-color-bg"
                                                                placeholder="Tenancy Name"
                                                                value={nonOperatingInputResult && nonOperatingInputResult[index] && (nonOperatingInputResult[index].name) ? nonOperatingInputResult[index].name : `Tenancy ${index + 1}`}
                                                                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                                            ></input>

                                                            {/* {
                                                            nonOperatingInputResult && nonOperatingInputResult[index] && (nonOperatingInputResult[index].name) 
                                                                ? nonOperatingInputResult[index].name 
                                                                : `Tenancy ${index + 1}`
                                                            } */}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Description / Tenant</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="text"
                                                                name={`description[${index}]`}
                                                                className="form-control form-control-sm "
                                                                placeholder="Enter description"
                                                                value={nonOperatingInputResult && nonOperatingInputResult[index] && (nonOperatingInputResult[index].description) ? nonOperatingInputResult[index].description : ""}
                                                                onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                                            ></input>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Lettable Area (m²)</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="text"
                                                                name={`lettableArea[${index}]`}
                                                                className="form-control form-control-sm "
                                                                placeholder="100.00"
                                                                value={nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].lettableArea) ? (parseFloat((nonOperatingInputResult[index].lettableArea))).toLocaleString('en-US') : 0}
                                                                // onChange={(e) => handleInputChange(index, 'lettableArea', e.target.value)}
                                                                onChange={(e) => {
                                                                    const rawValue = removeFormatting(e.target.value);
                                                                    if (/^\d*\.?\d*$/.test(rawValue)) { // Validate input
                                                                        handleInputChange(index, 'lettableArea', rawValue);
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    const rawValue = removeFormatting(e.target.value);
                                                                    handleInputChange(index, 'lettableArea', rawValue);
                                                                }}
                                                            ></input>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Gross Passing Rent </td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="text"
                                                                name={`grossPassingRent[${index}]`}
                                                                className="form-control form-control-sm"
                                                                placeholder="$10,000"
                                                                value={nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].grossPassingRent)
                                                                    ? formatterWithoutDecimals.format(nonOperatingInputResult[index].grossPassingRent)
                                                                    : formatterWithoutDecimals.format(0)}
                                                                onChange={(e) => {
                                                                    const rawValue = removeFormatting(e.target.value);
                                                                    if (/^\d*\.?\d*$/.test(rawValue)) { // Validate input
                                                                        handleInputChange(index, 'grossPassingRent', rawValue);
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    const rawValue = removeFormatting(e.target.value);
                                                                    handleInputChange(index, 'grossPassingRent', rawValue);
                                                                }}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Gross Passing Rental Rate ($/m²)</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].grossPassingRentalRate) ? formatter.format(parseFloat(nonOperatingInputResult[index].grossPassingRentalRate)) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Gross Market Rental Rate ($/m²)</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="">
                                                             <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'} 
                                                                type="text"
                                                                name={`grossMarketRentalRate[${index}]`}
                                                                className="form-control form-control-sm fw-600"
                                                                placeholder="$100.00"
                                                                value={inputValue[index] || formatValueNew(nonOperatingInputResult[index]?.grossMarketRentalRate || 0)}
                                                                onChange={(e) => {
                                                                    const rawValue = e.target.value;
                                                                    setInputValue((prevState) => ({
                                                                        ...prevState,
                                                                        [index]: rawValue, 
                                                                    }));  
                                                                }}
                                                                onBlur={(e) => {
                                                                    const rawValue = removeFormattingNew(e.target.value); 
                                                                    setInputValue((prevState) => ({
                                                                        ...prevState,
                                                                        [index]: formatValueNew(rawValue),
                                                                    })); 
                                                                    handleInputChange(index, 'grossMarketRentalRate', rawValue);
                                                                }}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Calculate (or input) Annual Gross Market Rent </td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="text"
                                                                name={`calculateAnnualGrossMarketRent[${index}]`}
                                                                className="form-control form-control-sm"
                                                                placeholder="$0"
                                                                value={nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].calculateAnnualGrossMarketRent)
                                                                    ? formatterWithoutDecimals.format(nonOperatingInputResult[index].calculateAnnualGrossMarketRent)
                                                                    : formatterWithoutDecimals.format(0)}
                                                                onChange={(e) => {
                                                                    const rawValue = removeFormatting(e.target.value);
                                                                    if (/^\d*\.?\d*$/.test(rawValue)) { // Validate input
                                                                        handleInputChange(index, 'calculateAnnualGrossMarketRent', rawValue);
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    const rawValue = removeFormatting(e.target.value);
                                                                    handleInputChange(index, 'calculateAnnualGrossMarketRent', rawValue);
                                                                }}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Adopted Gross Market Rent</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].adoptedGrossMarketRent) ? formatValue(parseFloat(nonOperatingInputResult[index].adoptedGrossMarketRent)) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Annual Increases</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index}>
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="text"
                                                                name={`annualIncreases[${index}]`}
                                                                className="form-control form-control-sm"
                                                                placeholder="0.00%"
                                                                value={nonOperatingInputResult && nonOperatingInputResult[index] && nonOperatingInputResult[index].annualIncreases !== undefined
                                                                    ? (nonOperatingInputResult[index].annualIncreases) + '%'
                                                                    : '0.00%'}
                                                                onChange={(e) => handleInputChange(index, 'annualIncreases', e.target.value)}
                                                                onBlur={(e) => handleBlur(index, 'annualIncreases', e.target.value)}
                                                                onFocus={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim() || '';
                                                                    handleInputChange(index, 'annualIncreases', rawValue);
                                                                }}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Lease Expiry</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="date"
                                                                name={`leaseExpiry[${index}]`}
                                                                className="form-control form-control-sm "
                                                                placeholder="3.00%"
                                                                // value={nonOperatingInputResult && nonOperatingInputResult[index] && nonOperatingInputResult[index].leaseExpiry ? moment(nonOperatingInputResult[index].leaseExpiry).format('YYYY-MM-DD') : ""}
                                                                value={
                                                                    nonOperatingInputResult &&
                                                                        nonOperatingInputResult[index] &&
                                                                        nonOperatingInputResult[index].leaseExpiry
                                                                        ? moment(nonOperatingInputResult[index].leaseExpiry).isValid()
                                                                            ? moment(nonOperatingInputResult[index].leaseExpiry).format('YYYY-MM-DD')
                                                                            : ""
                                                                        : ""
                                                                }
                                                                onChange={(e) => handleInputChange(index, 'leaseExpiry', e.target.value)}
                                                            ></input>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr className="d-none">
                                                    <td className="theme-color-bg sticky-col first-col w-10">Calc: Remaining Lease Term (years)</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].calcRemainingLeaseTerm) ? parseFloat(nonOperatingInputResult[index].calcRemainingLeaseTerm) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Remaining Lease Term (years)</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].remainingLeaseTerm) ? parseFloat(nonOperatingInputResult[index].remainingLeaseTerm) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Next Rent Review</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="date"
                                                                name={`nextRentReview[${index}]`}
                                                                className="form-control form-control-sm " placeholder="01-Jul-25"
                                                                // value={nonOperatingInputResult && nonOperatingInputResult[index] && nonOperatingInputResult[index].nextRentReview ? moment(nonOperatingInputResult[index].nextRentReview).format('YYYY-MM-DD') : ""}
                                                                value={
                                                                    nonOperatingInputResult &&
                                                                        nonOperatingInputResult[index] &&
                                                                        nonOperatingInputResult[index].nextRentReview
                                                                        ? moment(nonOperatingInputResult[index].nextRentReview).isValid()
                                                                            ? moment(nonOperatingInputResult[index].nextRentReview).format('YYYY-MM-DD')
                                                                            : ""
                                                                        : ""
                                                                }
                                                                onChange={(e) => handleInputChange(index, 'nextRentReview', e.target.value)}
                                                            ></input>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr className="d-none">
                                                    <td className="theme-color-bg sticky-col first-col w-10">Calc: Time until next Rent Review</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].calcTimeUntilNextRentReview) ? parseFloat(nonOperatingInputResult[index].calcTimeUntilNextRentReview) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Time until next Rent Review</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].timeUntilNextRentReview) ? parseFloat(nonOperatingInputResult[index].timeUntilNextRentReview).toFixed(2) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Rental Shortfall</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className={`bg-white  ${nonOperatingInputResult && nonOperatingInputResult[index] && nonOperatingInputResult[index].rentalShortfall < 0 ? 'color-red-table' : ''}`}>

                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].rentalShortfall) ? formatValue(parseFloat(nonOperatingInputResult[index].rentalShortfall)) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">PV of Shortfall until next Review</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className={`bg-white  ${nonOperatingInputResult && nonOperatingInputResult[index] && nonOperatingInputResult[index].pvOfShortfallUntilNextReview < 0 ? 'color-red-table' : ''}`}>
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].pvOfShortfallUntilNextReview) ? formatValue(parseFloat(nonOperatingInputResult[index].pvOfShortfallUntilNextReview)) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Apply vacancy allowance</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && nonOperatingInputResult[index].applyVacancyAllowance ? (nonOperatingInputResult[index].applyVacancyAllowance) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">No of months for vacancy allowance</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="number"
                                                                name={`numberOfMonthsForVacancyAllowance[${index}]`}
                                                                className="form-control form-control-sm "
                                                                placeholder="3"
                                                                value={nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].numberOfMonthsForVacancyAllowance) ? (nonOperatingInputResult[index].numberOfMonthsForVacancyAllowance) : 0}
                                                                onChange={(e) => handleInputChange(index, 'numberOfMonthsForVacancyAllowance', e.target.value)}
                                                            ></input>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">No of months for incentive allowance</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="number"
                                                                name={`numberOfMonthsForIncentiveAllowance[${index}]`}
                                                                className="form-control form-control-sm "
                                                                placeholder="3"
                                                                value={nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].numberOfMonthsForIncentiveAllowance) ? (nonOperatingInputResult[index].numberOfMonthsForIncentiveAllowance) : 0}
                                                                onChange={(e) => handleInputChange(index, 'numberOfMonthsForIncentiveAllowance', e.target.value)}
                                                            ></input>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Agent Leasing Fee</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="text"
                                                                name={`agentLeasingFee[${index}]`}
                                                                className="form-control form-control-sm"
                                                                placeholder="0.00%"
                                                                value={nonOperatingInputResult && nonOperatingInputResult[index] && nonOperatingInputResult[index].agentLeasingFee !== undefined
                                                                    ? (nonOperatingInputResult[index].agentLeasingFee) + '%'
                                                                    : '0.00%'}                                                                
                                                                onChange={(e) => handleInputChange(index, 'agentLeasingFee', e.target.value)}
                                                                onBlur={(e) => handleBlur(index, 'agentLeasingFee', e.target.value)}
                                                                onFocus={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim() || '';
                                                                    handleInputChange(index, 'agentLeasingFee', rawValue);
                                                                }}
                                                            />
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr className="d-none">
                                                    <td className="theme-color-bg sticky-col first-col w-10">Calc: Vacancy and Letting up Allowance</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className={`bg-white  ${nonOperatingInputResult && nonOperatingInputResult[index] && nonOperatingInputResult[index].calcVacancyAndLettingUpAllowance < 0 ? 'color-red-table' : ''}`}>
                                                            {nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].calcVacancyAndLettingUpAllowance) ? formatValue(parseFloat(nonOperatingInputResult[index].calcVacancyAndLettingUpAllowance)) : ""}
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Vacancy Allowance</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className={`bg-white  ${nonOperatingInputResult && nonOperatingInputResult[index] && nonOperatingInputResult[index].vacancyAllowance < 0 ? 'color-red-table' : ''}`}>

                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].vacancyAllowance) ? formatValue(parseFloat(nonOperatingInputResult[index].vacancyAllowance)) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">% of Total Area</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].percentageOfTotalArea) ? ((parseFloat(nonOperatingInputResult[index].percentageOfTotalArea))).toFixed(2) + "%" : "0.00"}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">% of Total Additional Income</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].percentageOfTotalAdditionalIncome) ? ((parseFloat(nonOperatingInputResult[index].percentageOfTotalAdditionalIncome))).toFixed(2) + "%" : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Weighted by Area</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].weightedByArea) ? (parseFloat(nonOperatingInputResult[index].weightedByArea)).toFixed(2) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                                <tr>
                                                    <td className="theme-color-bg sticky-col first-col w-10">Weighted by Income</td>
                                                    {[...Array(tenancies)].map((tenancy, index) => (
                                                        <td key={index} className="bg-white ">
                                                            <span>{nonOperatingInputResult && nonOperatingInputResult[index] && !isNaN(nonOperatingInputResult[index].weightedByIncome) ? (parseFloat(nonOperatingInputResult[index].weightedByIncome)).toFixed(2) : ""}</span>
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {selectedFacility !== null ?
                                        <div className="mt-4 mb-4 d-flex justify-content-end">
                                            {/* <button type="submit" className="btn btn-primary me-2">Save Changes</button> */}
                                            {user.role !== "SuperAdmin" && currentFacility.isLocked === '1' ? (
                                                <Tooltip 
                                                    title="This facility is locked. The action cannot be completed at this time."
                                                    size="small"
                                                >
                                                    <span className='cursor-not-allowed-disabled'>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary me-2"
                                                            disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                        >
                                                            Save Changes
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary me-2"
                                                    disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                >
                                                    Save Changes
                                                </button>
                                            )}
                                        </div>
                                        : ""}
                                    <div className="facilityTable table-responsive">
                                        <div className="NewTable-Rj pttable">
                                            <table className="table table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ backgroundColor: '#ffffff' }}></th>
                                                        <th scope="col" style={{ backgroundColor: '#ffffff' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="colspan=2" className="theme-color-bg">Total Area (m²)</th>
                                                        <td className="bg-white">{totals && !isNaN(totals.totalArea) ? parseFloat(totals.totalArea).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="colspan=2" className="theme-color-bg ">Total Passing Rent</th>
                                                        <td className={`bg-white fw-600 ${totals && !isNaN(totals.totalPassingRent) ? totals.totalPassingRent < 0 ? 'color-red-table' : '' : ''}`}>{totals && !isNaN(totals.totalPassingRent) ? formatValue(totals.totalPassingRent) : "$0.00"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="colspan=2" className="theme-color-bg ">Total Market Rent</th>
                                                        <td className={`bg-white fw-600 ${totals && !isNaN(totals.totalMarketRent) ? totals.totalMarketRent < 0 ? 'color-red-table' : '' : ''}`}>{totals && !isNaN(totals.totalMarketRent) ? formatValue(totals.totalMarketRent) : "$0.00"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="colspan=2" className="theme-color-bg">Variance from Market</th>
                                                        <td className="bg-white ">{totals && !isNaN(totals.varianceFromMarket) ? (totals.varianceFromMarket * 100).toFixed(2) + "%" : "0%"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="colspan=2" className="theme-color-bg ">Total Rental Shortfall</th>
                                                        <td className="bg-white  color-red-table">{totals && !isNaN(totals.totalRentalShortfall) ? formatValue(totals.totalRentalShortfall) : "$0.00"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="colspan=2" className="theme-color-bg">Total Vacancy Allowance</th>
                                                        <td className="bg-white  color-red-table">{totals && !isNaN(totals.totalVacancyAllowance) ? formatValue(totals.totalVacancyAllowance) : "$0.00"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="colspan=2" className="theme-color-bg">WALE by Lettable Area (Years) </th>
                                                        <td className="bg-white ">{totals && !isNaN(totals.waleByLettableArea) ? totals.waleByLettableArea : "0.00"}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="colspan=2" className="theme-color-bg">WALE by Income (Years)</th>
                                                        <td className="bg-white ">{totals && !isNaN(totals.waleByIncome) ? totals.waleByIncome : "0.00"}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <UploadExcel show={showExcelForm} setShow={setShowExcelForm} handleClose={handleCloseExcel} getFacilityDetails={getFacilityDetails} facilityId={selectedFacility} loading={loading} setLoading=
                    {setLoading} />
            </Layout>
        </>
    );
}