
import Header from "../Header/Header";
import { useFormik } from "formik";
import { Layout } from "../Layout/Layout";
import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import axios from "axios";
import Swal from "sweetalert2";

export default function CashflowOutput() {
    const [cashFlowOutputData, setCashFlowOutputData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);

    useEffect(() => {
        const portfolioId = localStorage.getItem('selectedPortfolio');
        const facilityId = localStorage.getItem('selectedFacility');

        if (portfolioId) {
            setSelectedPortfolio(JSON.parse(portfolioId));
        }

        if (facilityId) {
            setSelectedFacility(JSON.parse(facilityId));
            getCashFlowOutputData(facilityId);
        }
    }, []);

    const initialValues = {
        portfolioId: '',
        id: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
    });

    const getCashFlowOutputData = async (facilityId) => {
        setLoading(true)
        if (!facilityId) {
            console.error("Invalid facilityId", facilityId);
            setLoading(false);
            return;
        }
        try {
            const response = await axios.get(`api/cashFlow?facilityId=${facilityId}`);
            setLoading(false)
            if (response.data.success) {
                setCashFlowOutputData(response.data.data.CashFlowOutput);
            } else {
                Swal.fire('Error', 'There is no data for this facility', 'error');
            }
        } catch (error) {
            setLoading(false)
            Swal.fire('Error', error.message, 'error');
        }
    }

    const formatterWithoutDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const formatterWithDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatValue = (value) => {
        const numericValue = parseFloat(value);
        const hasSixDigitsBeforeDecimal = Math.floor(Math.abs(numericValue)).toString().length >= 3;

        return hasSixDigitsBeforeDecimal
            ? formatterWithoutDecimals.format(value)
            : formatterWithDecimals.format(value);
    };

    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header
                    pageTitle={localStorage.getItem('selectedFacilityName')}
                    verificationStatus={localStorage.getItem('selectedFacilityVerificationStatus')}
                />
                <div className="container-xxl flex-grow-1 container-p-y">
                    {/*{ selectedFacility !== null ? */}
                    {selectedFacility && (user.role === "SuperAdmin" ? selectedPortfolio !== null : true) ?
                        <React.Fragment>
                            {/* <NavHeader /> */}
                            <div className="bg-white p-3 mt-1 mb-3">
                                <div className="row d-flex align-itrms-center justify-content-center">
                                    <div className="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1">
                                        <div className="facilityTable table-responsive table-bordered">
                                            <table className="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th className="bg-gray-table" scope="col" style={{ width: '28%' }}>Milestones</th>
                                                        <th className="bg-white" scope="col" style={{ width: '12%' }}></th>
                                                        <th className="bg-white" scope="col" style={{ width: '12%' }}></th>
                                                        <th className="bg-white" scope="col" style={{ width: '12%' }}></th>
                                                        <th className="bg-white" scope="col" style={{ width: '12%' }}></th>
                                                        <th className="bg-white" scope="col" style={{ width: '12%' }}></th>
                                                        <th className="bg-white" scope="col" style={{ width: '12%' }}></th>
                                                    </tr>
                                                    <tr>
                                                        <th className="bg-gray-table" scope="col">Expected Month Facility Reaches Maturity</th>
                                                        <td className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedFutureValueAtmonth !== null ? (cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedFutureValueAtmonth) : 0}</td>
                                                        <th className="bg-white" scope="col"></th>
                                                        <th className="bg-white" scope="col"></th>
                                                        <th className="bg-white" scope="col"></th>
                                                        <th className="bg-white" scope="col"></th>
                                                        <th className="bg-white" scope="col"></th>
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">Month (End Of) </th>
                                                        <th scope="col">12</th>
                                                        <th scope="col">24</th>
                                                        <th scope="col">36</th>
                                                        <th scope="col">48</th>
                                                        <th scope="col">60</th>
                                                        <th scope="col">72</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="bg-table">Projected Total Monthly Storage Revenue</td>
                                                        {cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedTotalMonthlyStorageRevenueValues !== null ? cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedTotalMonthlyStorageRevenueValues.map((value, index) => (
                                                            <td key={index} className="bg-white">{formatterWithoutDecimals.format(value)}</td>
                                                        )) : Array(6).fill(0).map((_, index) => (
                                                            <td key={index} className="bg-white">$0</td>
                                                        ))}

                                                    </tr>
                                                    <tr>
                                                        <td className="bg-table">Projected Total Annual Storage Revenue</td>
                                                        {cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedTotalAnnualStorageRevenueValues !== null ? cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedTotalAnnualStorageRevenueValues.map((value, index) => (
                                                            <td key={index} className="bg-white">{formatterWithoutDecimals.format(value)}</td>
                                                        )) : Array(6).fill(0).map((_, index) => (
                                                            <td key={index} className="bg-white">$0</td>
                                                        ))}
                                                    </tr>
                                                    <tr>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="bg-table table-red-bg">Projected Future Value At Month</td>
                                                        <td className="bg-white table-blank-space-facility">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedFutureValueAtmonth !== null ? (cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedFutureValueAtmonth) : 0}</td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="bg-white"></td>
                                                        <td className="bg-white"></td>
                                                        <td className="bg-white"></td>
                                                        <td className="bg-white table-blank-space-facility" ></td>
                                                        <td className="bg-white table-blank-space-facility" ></td>
                                                        <td className="bg-white table-blank-space-facility" ></td>
                                                        <td className="bg-white table-blank-space-facility" ></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="bg-orange">Self Storage Revenue</td>
                                                        <td className="bg-white"></td>
                                                        <td style={{ borderRight: '1px solid #dedede' }} className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.SelfStorageRevenueD25 !== null ? formatterWithoutDecimals.format(cashFlowOutputData.CashFlowOutputMilestonesCalcuation.SelfStorageRevenueD25) : '$0'}</td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="bg-orange">Future Value of Non-Storage Income</td>
                                                        <td className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.FYOfNonStorageIncomeC26 !== null ? formatterWithoutDecimals.format(cashFlowOutputData.CashFlowOutputMilestonesCalcuation.FYOfNonStorageIncomeC26) : '$0'}</td>
                                                        <td style={{ borderRight: '1px solid #dedede' }} className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.FYOfNonStorageIncomeD26 !== null ? formatterWithoutDecimals.format(cashFlowOutputData.CashFlowOutputMilestonesCalcuation.FYOfNonStorageIncomeD26) : '$0'}</td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="bg-orange">Expenses</td>
                                                        <td className="bg-white"></td>
                                                        <td style={{ borderRight: '1px solid #dedede' }} className={`bg-white  ${cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.Expenses !== null && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.Expenses < 0 ? 'color-red-table' : ''}`}>{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.Expenses !== null ? formatterWithoutDecimals.format(cashFlowOutputData.CashFlowOutputMilestonesCalcuation.Expenses) : '$0'}</td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="bg-orange">Net Operating Profit</td>
                                                        <td className="bg-white"></td>
                                                        <td style={{ borderRight: '1px solid #dedede' }} className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.NetOperatingProfit !== null ? formatterWithoutDecimals.format(cashFlowOutputData.CashFlowOutputMilestonesCalcuation.NetOperatingProfit) : '$0'}</td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                    </tr>
                                                    <tr className="bg-white table-blank-space-facility">
                                                        <td className="bg-white"></td>
                                                        <td className="bg-white"></td>
                                                        <td className="bg-white"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="bg-white"></td>
                                                        <td className="theme-color-bg">High</td>
                                                        <td className="theme-color-bg">Current</td>
                                                        <td style={{ borderRight: '1px solid #dedede' }} className="theme-color-bg">Low</td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="theme-color-bg">Cap Rate</td>
                                                        <td className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.CapRates[0] !== null ? (cashFlowOutputData.CashFlowOutputMilestonesCalcuation.CapRates[0]).toFixed(2) + '%' : 0}</td>
                                                        <td className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.CapRates[1] !== null ? (cashFlowOutputData.CashFlowOutputMilestonesCalcuation.CapRates[1]).toFixed(2) + '%' : 0}</td>
                                                        <td style={{ borderRight: '1px solid #dedede' }} className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.CapRates[2] !== null ? (cashFlowOutputData.CashFlowOutputMilestonesCalcuation.CapRates[2]).toFixed(2) + '%' : 0}</td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                    </tr>
                                                    {/* <tr>
                                                    <td className="bg-white"></td>
                                                    <td className="bg-white"></td>
                                                    <td className="bg-white"></td>
                                                    <td className="bg-white"></td>
                                                    <td className="bg-white"></td>
                                                    <td className="bg-white"></td>
                                                    <td className="bg-white"></td>
                                                </tr> */}
                                                    <tr>
                                                        <td className="theme-color-bg">Projected Indicative Value at Maturity</td>
                                                        <td className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedIndicativeValueatMaturityValues[0] !== null ? formatterWithoutDecimals.format(cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedIndicativeValueatMaturityValues[0]) : '$0'}</td>
                                                        <td className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedIndicativeValueatMaturityValues[1] !== null ? formatterWithoutDecimals.format(cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedIndicativeValueatMaturityValues[1]) : '$0'}</td>
                                                        <td style={{ borderRight: '1px solid #dedede' }} className="bg-white">{cashFlowOutputData && cashFlowOutputData.CashFlowOutputMilestonesCalcuation && cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedIndicativeValueatMaturityValues[2] !== null ? formatterWithoutDecimals.format(cashFlowOutputData.CashFlowOutputMilestonesCalcuation.ProjectedIndicativeValueatMaturityValues[2]) : '$0'}</td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                        <td className="bg-white table-blank-space-facility"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                        <div className="mb-3 mt-3">
                                            <h4 style={{ color: "#697a8d" }}>
                                                Please Select a
                                                {user.role === "SuperAdmin"
                                                    ? (selectedPortfolio === null ? " Portfolio!" : " Facility!")
                                                    : " Facility!"}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Layout>
        </>
    );
}