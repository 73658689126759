import * as yup from 'yup';
import { Layout } from "../Layout/Layout";
import { useEffect, useRef, useState } from "react";
import { useFormik } from 'formik';
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";
import Header from "../Header/Header";
import { Tooltip } from '@mui/material';
import ArrowTooltips from '../PortfolioDashboard/PopHover/ToolTip';

var moment = require('moment');

const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const removeFormatting = (value) => {
    if (value == null) {
        return value;
    }
    //         return value.replace(/[^0-9.-]+/g, '');
    return value.toString().replace(/[^0-9.-]+/g, '');
};

export default function ClientInput() {
    const [isFacilityExpandedIn12Months, setIsFacilityExpandedIn12Months] = useState('')
    const [isFacilityTradingAtMaturity, setIsFacilityTradingAtMaturity] = useState('No')
    const [isNetStorageAreaNeedtoBeAmended, setIsNetStorageAreaNeedtoBeAmended] = useState('No')
    const [storeType, setStoreType] = useState('Storman')
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [currentFacility, setCurrentFacility] = useState({});
    const [loading, setLoading] = useState(false);
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);

    const portfolioId = localStorage.getItem('selectedPortfolio') ? parseInt(localStorage.getItem('selectedPortfolio')) : null;
    const facilityId = localStorage.getItem('selectedFacility') ? parseInt(localStorage.getItem('selectedFacility')) : null;

    const currentYear = moment().year();
    const yearOptions = [];
    for (let i = 0; i < 5; i++) {
        yearOptions.push(currentYear - i);
    }

    useEffect(() => {
        if (portfolioId) {
            setSelectedPortfolio(portfolioId);
        }

        if (facilityId) {
            formik.setFieldValue('id', facilityId);
            setSelectedFacility(facilityId);
            getFacilityDetails(facilityId)
        }
    }, [portfolioId, facilityId]);

    const validationSchema = yup.object({
        country: yup.string().required('Base Country is required'),
        financialYear: yup.string().required('Financial Year is required'),
        mostRecentFY: yup.string().required('Most Recent FY is required'),
        adoptedValuation: yup.string().required('Adopted Valuation is required'),
        currency: yup.string().required('Currency is required'),
        titleReference: yup.string().required('Title Reference is required'),
        // storeType: yup.string().required('Store Type is required'),
        // currentSiteValueAssessment: yup.string().required('Current Site Value Assessment is required'),
        // existingNetStorageArea: yup.string().required('Existing Net Storage Area is required'),
        // totalNetStorageAreaCompletion: yup.string().required('Total Net Storage Area Completion is required'),
        netStorageAreaExpansion: isFacilityExpandedIn12Months && isFacilityExpandedIn12Months === 'Yes' && yup.string().required('Net Storage Area Expansion is required')
        .test(
        'is-valid-value',
        'Net Storage Area Expansion cannot be 0',
        (value) => {
            if (!value) return false;
            const normalizedValue = parseFloat(value.replace(/[$,]/g, '').trim());
            return normalizedValue > 0;
        }
        ),
        costToCompleteFurtherStages: isFacilityExpandedIn12Months && isFacilityExpandedIn12Months === 'Yes' && yup.string().required('Cost to Complete Further Stages is required')
        .test(
        'is-valid-value',
        'Cost to Complete Further Stages cannot be 0',
        (value) => {
            if (!value) return false;
            const normalizedValue = parseFloat(value.replace(/[$,]/g, '').trim());
            return normalizedValue > 0;
        }
        ),
        expectedMonthsToCompleteExpansion: isFacilityExpandedIn12Months && isFacilityExpandedIn12Months === 'Yes' && yup.number().typeError('Expected Months to Complete Expansion must be a number').required('Expected Months to Complete Expansion is required')
        .test(
        'is-not-zero',
        'Expected Months to Complete Expansion cannot be 0',
        (value) => value > 0
        ),
        // ratePerSquareMetreConstructionCost: yup.string().required('Rate per square metre of construction cost is required'),
        // .test('not-zero', 'Expected Months to Complete Expansion must be greater than 0', value => value !== 0),
        // // completedExpansionDate: yup.date().typeError('Date Expansion Completion must be a date'),
        // monthsToReachMatureOccupancy: yup.number().typeError('Months to Reach Mature Occupancy must be a number')
        //                 .required('This Field is required'),
        // // dateMatureOccupancyExpected: yup.date().typeError('Date Mature Occupancy Expected must be a date'),
        // stabilisedMaintainableOccupancyAtMaturity: yup.number().typeError('Stabilised Maintainable Occupancy must be a number').required('This Field is required'),
        // stabilisedOccupiedNSAAtMaturity: yup.number().typeError('Stabilised Occupied NSA must be a number').required('This Field is required'),
        // currentAreaOccupied: yup.number().typeError('Current Area Occupied must be a number').required('This Field is required'),
        // // currentOccupancyAcrossNetStorageArea: yup.number().typeError('Current Occupancy Total NSA must be a number'),
        // buildupRateNSAPerMonth: yup.number().typeError('Build Up Rate Per Month must be a number').required('This Field is required'),
        // passingAverageStorageFeeRate: yup.number().required('Passing Average Storage Fee Rate is required'),
        // stabilisedAverageMatureFeeRate: yup.number().required('Stabilised Average Mature Fee Rate is required'),
        // stabilisedOtherIncomePercentage: yup.number().typeError('Stabilised Other Income Percentage must be a number'),
        // essentialCapitalExpenditure: yup.number(),
        // valueAdditionalAreas: yup.number(),
        // otherAdjustmentToValue: yup.number(),
    });
    
    const handleRadioChangeIsExpanded = (e) => {
        const selectedValue = e.target.value;
        setIsFacilityExpandedIn12Months(selectedValue);
        formik.setFieldValue('isFacilityExpandedIn12Months', selectedValue);
    };

    const handleRadioChangeIsAtMaturity = (e) => {
        const selectedValue = e.target.value;
        setIsFacilityTradingAtMaturity(selectedValue);
        formik.setFieldValue('isFacilityTradingAtMaturity', selectedValue);
    };

    const handleRadioChangeAdjustedNetStorageArea = (e) => {
        const selectedValue = e.target.value;
        setIsNetStorageAreaNeedtoBeAmended(selectedValue);
        formik.setFieldValue('isNetStorageAreaNeedtoBeAmended', selectedValue);
        if(selectedValue === 'No'){
            formik.setFieldValue('adjustedCurrentNetStorageArea', 0);
            formik.setFieldValue('adjustedAreaOccupied', 0);  
            formik.setFieldValue('adjustedNetStorageArea', (parseFloat(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.existingNetStorageArea || 0)).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));  
            formik.setFieldValue('adjustedCurrentAreaOccupied', (parseFloat(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.netStorageAreaOccupied  || 0)).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) );

            formik.setFieldValue('existingNetStorageArea', (parseFloat(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.existingNetStorageArea || 0)).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));  
            formik.setFieldValue('currentAreaOccupied', (parseFloat(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.netStorageAreaOccupied  || 0)).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) );

        }
    };

    const handleRadioChangeStoreType = (e) => {
        const selectedValue = e.target.value;
        setStoreType(selectedValue);
        formik.setFieldValue('storeType', selectedValue);
    };

    var CurrentDate = moment().format('YYYY-MM-DD');
    var currentMonth = new Date(CurrentDate).getMonth() + 1; // getMonth() returns month from 0-11, so add 1
    var CurrentFY;

    if (currentMonth <= 6) {
        CurrentFY = new Date(CurrentDate).getFullYear();
    } else {
        CurrentFY = new Date(CurrentDate).getFullYear() + 1;
    }

    var mostRecentFY = CurrentFY - 1;

    const initialValues = {
        id: '',
        country: '',
        financialYear: '',
        mostRecentFY: mostRecentFY,
        adoptedValuation: 'Static',
        currency: '',
        titleReference: '',
        storeType: 'Storman',
        currentSiteValueAssessment: '',
        isFacilityExpandedIn12Months: 'Yes',

        isNetStorageAreaNeedtoBeAmended: 'No',
        // currentNetStorageArea: currentFacility?.apiDataObj?.facilityApiDataArr[0]?.existingNetStorageArea || '',
        adjustedCurrentNetStorageArea: '',
        adjustedNetStorageArea: '',
        // currentAreaOccupiedNew: '',
        adjustedAreaOccupied: '',
        adjustedCurrentAreaOccupied:'',

        // existingNetStorageArea: currentFacility?.apiDataObj?.facilityApiDataArr[0]?.existingNetStorageArea || '',
        existingNetStorageArea: currentFacility?.adjustedCurrentNetStorageArea !== null
            ? (parseFloat(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.existingNetStorageArea || 0) + parseFloat(currentFacility.adjustedCurrentNetStorageArea || 0)).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : (parseFloat(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.existingNetStorageArea || 0)).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        IstheAdoptedRevenuewithin5PercentofPassing: '',
        netStorageAreaExpansion: '',
        totalNetStorageAreaCompletion: '',
        costToCompleteFurtherStages: '',
        ratePerSquareMetreConstructionCost: '',
        expectedMonthsToCompleteExpansion: '',
        completedExpansionDate: moment().add(6, 'months').format('YYYY-MM-DD'),
        isFacilityTradingAtMaturity: 'No',
        monthsToReachMatureOccupancy: '',
        dateMatureOccupancyExpected: moment().add(2, 'years').format('YYYY-MM-DD'),
        stabilisedMaintainableOccupancyAtMaturity: '',
        stabilisedOccupiedNSAAtMaturity: '',
        // currentAreaOccupied: '',
        currentAreaOccupied: currentFacility?.adjustedAreaOccupied  !== null
        ? (parseFloat(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.netStorageAreaOccupied  || 0) + parseFloat(currentFacility.adjustedAreaOccupied  || 0)).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        : (parseFloat(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.netStorageAreaOccupied  || 0)).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        currentOccupancyAcrossNetStorageArea: '',
        buildupRateNSAPerMonth: '',
        passingAverageStorageFeeRate: '',
        stabilisedAverageMatureFeeRate: '',
        stabilisedOtherIncomePercentage: '',
        essentialCapitalExpenditure: '',
        valueAdditionalAreas: '',
        otherAdjustmentToValue: '',
        adoptedAssessmentType: ''
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
            // formik.resetForm();
        }
    });

    const cleanValues = async (values) => {
        const fieldsToFormat = [
            'currentSiteValueAssessment',
            'existingNetStorageArea',
            'netStorageAreaExpansion',
            'totalNetStorageAreaCompletion',
            'costToCompleteFurtherStages',
            'ratePerSquareMetreConstructionCost',
            'stabilisedMaintainableOccupancyAtMaturity',
            'stabilisedOccupiedNSAAtMaturity',
            'currentAreaOccupied',
            'currentOccupancyAcrossNetStorageArea',
            'buildupRateNSAPerMonth',
            'passingAverageStorageFeeRate',
            'stabilisedAverageMatureFeeRate',
            'stabilisedOtherIncomePercentage',
            'essentialCapitalExpenditure',
            'valueAdditionalAreas',
            'otherAdjustmentToValue',
            'adjustedCurrentNetStorageArea',
            'adjustedAreaOccupied'
        ];

        return Object.keys(values).reduce((acc, key) => {
            const value = values[key];
            if (fieldsToFormat.includes(key) && typeof value === 'string' && value) {
                acc[key] = removeFormatting(value);
            } else {
                acc[key] = value;
            }

            return acc;
        }, {});
    }

    const calculateFacilityValuation = async (facilityId) => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/calculateFacilityValuation?facilityId=${facilityId}`);
            setLoading(false)
            let result = response.data;
            if (result.success) {
                console.log("response.data.data", response.data);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false)
            Swal.fire("Error", err.message, "error");
        }
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        values = await cleanValues(values);
        // console.log("values----------------", values)
        // return;
        try {
            const response = await axios.put(`/api/facility/update`, values);

            if (response.data.success) {
                setLoading(false);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message || 'Update Successful!'
                }).then(() => {
                    calculateFacilityValuation(formik.values.id);
                    getFacilityDetails(formik.values.id);
                });
            } else {
                // Toast.fire({
                //     icon: 'error',
                //     title: response.data.message || 'Update Failed!'
                // });
                Swal.fire(response.data.message, '', 'error');
                setLoading(false);
            }
        } catch (error) {
            // Toast.fire({
            //     icon: 'error',
            //     title: error.response?.data?.message || error.message
            // });
            Swal.fire(error.response?.data?.message || error.message, '', 'error');
            setLoading(false);
        }
    };

    const getFacilityDetails = (facilityId) => {
        setLoading(true)
        axios.get(`api/facility?id=${facilityId}`).then(res => {
            setLoading(false)
            if (res.data.success) {
                const result = res.data.data;
                // console.log(result);
                if (result) {
                    const updatedJsonObject = replaceNullsAndFormatCurrency(result);

                    result.mostRecentFY = result.mostRecentFY && result.mostRecentFY === ''
                        ? moment(updatedJsonObject.mostRecentFY, 'YYYY-MM-DD')
                        : mostRecentFY;
                    updatedJsonObject.financialYear = updatedJsonObject.country === 'AUS' ? '1st July - 30th June' : '1st April - 31st March';

                    // formik.setValues(updatedJsonObject);
                    formik.setFieldValue('country', updatedJsonObject.country);
                    formik.setFieldValue('financialYear', updatedJsonObject.financialYear);
                    formik.setFieldValue('mostRecentFY', result.mostRecentFY);
                    formik.setFieldValue('adoptedValuation', updatedJsonObject.adoptedValuation);
                    formik.setFieldValue('currency', updatedJsonObject.currency);
                    formik.setFieldValue('titleReference', updatedJsonObject.titleReference);
                    formik.setFieldValue('storeType', updatedJsonObject.storeType);
                    formik.setFieldValue('currentSiteValueAssessment', updatedJsonObject.currentSiteValueAssessment);
                    formik.setFieldValue('isFacilityExpandedIn12Months', updatedJsonObject.isFacilityExpandedIn12Months);
                    formik.setFieldValue('isNetStorageAreaNeedtoBeAmended', updatedJsonObject.isNetStorageAreaNeedtoBeAmended);
                    // formik.setFieldValue('existingNetStorageArea', res?.data?.data?.apiDataObj?.facilityApiDataArr?.[0]?.existingNetStorageArea ?? 0);
                    
                    const existingNetStorageArea = parseFloat(res?.data?.data?.apiDataObj?.facilityApiDataArr?.[0]?.existingNetStorageArea || 0);
                    const adjustedCurrentNetStorageArea = parseFloat(res?.data?.data?.adjustedCurrentNetStorageArea || 0);
                    formik.setFieldValue(
                        'adjustedCurrentNetStorageArea',
                        adjustedCurrentNetStorageArea === 0
                            ? adjustedCurrentNetStorageArea.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            : (adjustedCurrentNetStorageArea >= 0 ? '+' : '') + 
                              adjustedCurrentNetStorageArea.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    );
                    formik.setFieldValue(
                        'existingNetStorageArea',
                        adjustedCurrentNetStorageArea !== 0
                            ? (existingNetStorageArea + adjustedCurrentNetStorageArea).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            : existingNetStorageArea.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    );

                    const adjustedNetStorageArea = adjustedCurrentNetStorageArea + existingNetStorageArea;
                    formik.setFieldValue('adjustedNetStorageArea', adjustedNetStorageArea.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));

                    formik.setFieldValue('netStorageAreaExpansion', (updatedJsonObject.netStorageAreaExpansion || 0));
                    formik.setFieldValue('totalNetStorageAreaCompletion', updatedJsonObject.totalNetStorageAreaCompletion);
                    formik.setFieldValue('costToCompleteFurtherStages', (updatedJsonObject.costToCompleteFurtherStages || 0));
                    formik.setFieldValue('ratePerSquareMetreConstructionCost', (updatedJsonObject.ratePerSquareMetreConstructionCost || 0));
                    formik.setFieldValue('expectedMonthsToCompleteExpansion', updatedJsonObject.expectedMonthsToCompleteExpansion);
                    // formik.setFieldValue('completedExpansionDate', updatedJsonObject.completedExpansionDate);
                    formik.setFieldValue('completedExpansionDate', moment(updatedJsonObject.completedExpansionDate).format('YYYY-MM-DD'));
                    formik.setFieldValue('isFacilityTradingAtMaturity', updatedJsonObject.isFacilityTradingAtMaturity);
                    formik.setFieldValue('monthsToReachMatureOccupancy', (updatedJsonObject.monthsToReachMatureOccupancy || 0));
                    // formik.setFieldValue('dateMatureOccupancyExpected', updatedJsonObject.dateMatureOccupancyExpected);
                    formik.setFieldValue('dateMatureOccupancyExpected', moment(updatedJsonObject.dateMatureOccupancyExpected).format('YYYY-MM-DD'));
                    formik.setFieldValue('stabilisedMaintainableOccupancyAtMaturity', updatedJsonObject.stabilisedMaintainableOccupancyAtMaturity);
                    formik.setFieldValue('stabilisedOccupiedNSAAtMaturity', (updatedJsonObject.stabilisedOccupiedNSAAtMaturity || 0));
                    // formik.setFieldValue('currentAreaOccupied', res?.data?.data?.apiDataObj?.facilityApiDataArr?.[0]?.netStorageAreaOccupied ?? '');
                    
                    const netStorageAreaOccupied = parseFloat(res?.data?.data?.apiDataObj?.facilityApiDataArr?.[0]?.netStorageAreaOccupied || 0);
                    const adjustedAreaOccupied = parseFloat(res?.data?.data?.adjustedAreaOccupied || 0);
                    
                    formik.setFieldValue(
                        'adjustedAreaOccupied',
                        adjustedAreaOccupied === 0
                            ? adjustedAreaOccupied.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            : (adjustedAreaOccupied >= 0 ? '+' : '') + 
                              adjustedAreaOccupied.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    );
                    formik.setFieldValue(
                        'currentAreaOccupied',
                        adjustedAreaOccupied !== 0
                            ? (netStorageAreaOccupied + adjustedAreaOccupied).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            : netStorageAreaOccupied.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    );
                    const adjustedCurrentAreaOccupied = adjustedAreaOccupied + netStorageAreaOccupied;
                    formik.setFieldValue('adjustedCurrentAreaOccupied', adjustedCurrentAreaOccupied.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));

                    formik.setFieldValue('currentOccupancyAcrossNetStorageArea', updatedJsonObject.currentOccupancyAcrossNetStorageArea);
                    formik.setFieldValue('buildupRateNSAPerMonth', (updatedJsonObject.buildupRateNSAPerMonth || 0));
                    // formik.setFieldValue('passingAverageStorageFeeRate',  res?.data?.data?.apiDataObj?.facilityApiDataArr?.[0]?.averageStorageFeeRateActual ? parseFloat(updatedJsonObject.apiDataObj.facilityApiDataArr[0].averageStorageFeeRateActual).toFixed(2): '');
                    formik.setFieldValue('passingAverageStorageFeeRate', res?.data?.data?.apiDataObj?.facilityApiDataArr?.[0]?.averageStorageFeeRateActual ? parseFloat(updatedJsonObject.apiDataObj.facilityApiDataArr[0].averageStorageFeeRateActual) : 0);
                    formik.setFieldValue('stabilisedAverageMatureFeeRate', updatedJsonObject.stabilisedAverageMatureFeeRate);
                    formik.setFieldValue('stabilisedOtherIncomePercentage', (updatedJsonObject.stabilisedOtherIncomePercentage || 0));
                    formik.setFieldValue('essentialCapitalExpenditure', updatedJsonObject.essentialCapitalExpenditure);
                    formik.setFieldValue('valueAdditionalAreas', updatedJsonObject.valueAdditionalAreas);
                    formik.setFieldValue('otherAdjustmentToValue', updatedJsonObject.otherAdjustmentToValue);
                    formik.setFieldValue('adoptedAssessmentType', updatedJsonObject.adoptedAssessmentType);

                    setCurrentFacility(updatedJsonObject);
                    setIsFacilityExpandedIn12Months(updatedJsonObject.isFacilityExpandedIn12Months);
                    setIsNetStorageAreaNeedtoBeAmended(updatedJsonObject.isNetStorageAreaNeedtoBeAmended);
                    setIsFacilityTradingAtMaturity(updatedJsonObject.isFacilityTradingAtMaturity);
                    setStoreType(updatedJsonObject.storeType);
                }
            }
        })
    }

    function replaceNullsAndFormatCurrency(obj) {
        for (let key in obj) {
            if (obj[key] === null) {
                obj[key] = '';
            } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                replaceNullsAndFormatCurrency(obj[key]);
            } else if (!isNaN(obj[key]) && typeof obj[key] === 'float') {
                obj[key] = `$${obj[key].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
            }
        }
        return obj;
    }

    useEffect(() => {
        const existingNetStorageArea = removeFormatting(formik.values.existingNetStorageArea);
        const netStorageAreaExpansion = removeFormatting(formik.values.netStorageAreaExpansion);
        // if (!isNaN(existingNetStorageArea) && existingNetStorageArea !== '') {
        //     formik.setFieldValue('currentNetStorageArea', NumberFormatter.format(existingNetStorageArea));
        // }
        if (!isNaN(existingNetStorageArea) && !isNaN(netStorageAreaExpansion)) {
            const totalNetStorageAreaCompletion = parseFloat(existingNetStorageArea || 0) + parseFloat(netStorageAreaExpansion || 0);
            formik.setFieldValue('totalNetStorageAreaCompletion', NumberFormatter.format(totalNetStorageAreaCompletion));
        } else {
            formik.setFieldValue('totalNetStorageAreaCompletion', '');
        }
        const costToCompleteFurtherStages = removeFormatting(formik.values.costToCompleteFurtherStages);
        if (!isNaN(costToCompleteFurtherStages) && !isNaN(netStorageAreaExpansion) && (costToCompleteFurtherStages !== 0 || netStorageAreaExpansion !== 0)) {
            const ratePerSquareMetreConstructionCost = parseFloat(costToCompleteFurtherStages) !== 0 && parseFloat(netStorageAreaExpansion) !== 0 ? parseFloat(costToCompleteFurtherStages) / parseFloat(netStorageAreaExpansion) : 0;

            formik.setFieldValue('ratePerSquareMetreConstructionCost', ratePerSquareMetreConstructionCost);
        } else {
            formik.setFieldValue('ratePerSquareMetreConstructionCost', 0);
        }

        const stabilisedMaintainableOccupancyAtMaturity = parseFloat(formik.values.stabilisedMaintainableOccupancyAtMaturity);
        const totalNetStorageAreaCompletion = removeFormatting(formik.values.totalNetStorageAreaCompletion);
        if (!isNaN(stabilisedMaintainableOccupancyAtMaturity) && !isNaN(totalNetStorageAreaCompletion)) {
            const stabilisedOccupiedNSAAtMaturity = parseFloat(totalNetStorageAreaCompletion) * (stabilisedMaintainableOccupancyAtMaturity / 100);
            formik.setFieldValue('stabilisedOccupiedNSAAtMaturity', NumberFormatter.format(stabilisedOccupiedNSAAtMaturity));
        } else {
            formik.setFieldValue('stabilisedOccupiedNSAAtMaturity', 0);
        }

        const currentAreaOccupied = removeFormatting(formik.values.currentAreaOccupied);
        // if (!isNaN(currentAreaOccupied) && currentAreaOccupied !== '') {
        //     formik.setFieldValue('currentAreaOccupiedNew', NumberFormatter.format(currentAreaOccupied));
        // }

        if (!isNaN(currentAreaOccupied) && !isNaN(totalNetStorageAreaCompletion) && (currentAreaOccupied !== 0 || totalNetStorageAreaCompletion !== 0)) {
            const currentOccupancyAcrossNetStorageArea = (currentAreaOccupied / totalNetStorageAreaCompletion) * 100;
            // formik.setFieldValue('currentOccupancyAcrossNetStorageArea', currentOccupancyAcrossNetStorageArea.toFixed(2));
            formik.setFieldValue('currentOccupancyAcrossNetStorageArea', currentOccupancyAcrossNetStorageArea);
        } else {
            formik.setFieldValue('currentOccupancyAcrossNetStorageArea', 0);
        }

        const monthsToReachMatureOccupancy = parseFloat(formik.values.monthsToReachMatureOccupancy);

        const stabilisedOccupiedNSAAtMaturity = !isNaN(stabilisedMaintainableOccupancyAtMaturity) && !isNaN(totalNetStorageAreaCompletion) ? parseFloat(totalNetStorageAreaCompletion) * (stabilisedMaintainableOccupancyAtMaturity / 100) : 0;
        if (!isNaN(stabilisedOccupiedNSAAtMaturity) && !isNaN(currentAreaOccupied) && !isNaN(monthsToReachMatureOccupancy) && (monthsToReachMatureOccupancy !== 0 || stabilisedOccupiedNSAAtMaturity !== 0 || currentAreaOccupied !== 0)) {
            const buildupRateNSAPerMonth = ((parseFloat(stabilisedOccupiedNSAAtMaturity) - parseFloat(currentAreaOccupied)) != 0 && monthsToReachMatureOccupancy != 0) ? (parseFloat(stabilisedOccupiedNSAAtMaturity) - parseFloat(currentAreaOccupied)) / monthsToReachMatureOccupancy : 0;
            formik.setFieldValue('buildupRateNSAPerMonth', buildupRateNSAPerMonth);
        } else {
            formik.setFieldValue('buildupRateNSAPerMonth', 0);
        }
        // formik.setFieldValue('mostRecentFY', moment(formik.values.mostRecentFY).format('YYYY'));
        formik.setFieldValue('mostRecentFY', formik.values.mostRecentFY);

        const completedExpansionDate = moment().add(parseInt(formik.values.expectedMonthsToCompleteExpansion), 'months').format('YYYY-MM-DD')
        formik.setFieldValue('completedExpansionDate', completedExpansionDate);

        const dateMatureOccupancyExpected = moment().add(parseInt(formik.values.monthsToReachMatureOccupancy), 'months').format('YYYY-MM-DD');
        formik.setFieldValue('dateMatureOccupancyExpected', dateMatureOccupancyExpected);


        const adoptedValuation = formik.values.isFacilityExpandedIn12Months === 'No' && formik.values.isFacilityTradingAtMaturity === 'Yes' ? 'Static' : 'BuildUp';
        formik.setFieldValue('adoptedValuation', adoptedValuation);

        const adoptedAssessmentType = formik.values.adoptedValuation === 'Static' && ['', 'ProjectedMed', 'HigherOccupancy', 'HigherFeeRate'].includes(formik.values.adoptedAssessmentType) ? 'Live' : formik.values.adoptedValuation === 'BuildUp' && ['', 'Live', 'High', 'Med', 'Low'].includes(formik.values.adoptedAssessmentType) ? 'ProjectedMed' : formik.values.adoptedAssessmentType
        formik.setFieldValue('adoptedAssessmentType', adoptedAssessmentType);

        const stabilisedAverageMatureFeeRate = `$${parseFloat(removeFormatting(formik.values.stabilisedAverageMatureFeeRate || 0)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        formik.setFieldValue('stabilisedAverageMatureFeeRate', stabilisedAverageMatureFeeRate);

    }, [formik.values.existingNetStorageArea, formik.values.netStorageAreaExpansion, formik.values.costToCompleteFurtherStages, formik.values.monthsToReachMatureOccupancy, formik.values.stabilisedMaintainableOccupancyAtMaturity, formik.values.currentAreaOccupied, formik.values.expectedMonthsToCompleteExpansion, formik.values.isFacilityExpandedIn12Months, formik.values.isFacilityTradingAtMaturity, formik.values.totalNetStorageAreaCompletion, formik.values.buildupRateNSAPerMonth, formik.values.isNetStorageAreaNeedtoBeAmended]);

    const NumberFormatter = new Intl.NumberFormat('en-AU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatValue = (value, inputName = '') => {
        return isNaN(value) ? '' : NumberFormatter.format(value);
    };

    const calculateAdjustedStorageArea = (existingArea, adjustment) => {
        const existingValue = parseFloat(existingArea || 0);
        const adjustmentValue = parseFloat(adjustment || 0);
        const adjustedValue = existingValue + adjustmentValue;

        return adjustedValue.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const calculateAdjustedStorageAreaOccupied = (currentAreaOccupied, adjustment) => {
        const currentAreaOccupiedValue = parseFloat(currentAreaOccupied|| 0);
        const adjustmentValue = parseFloat(adjustment || 0);
        const adjustedValue = currentAreaOccupiedValue + adjustmentValue;
    
        return adjustedValue.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const inputRefs = {
        netStorageAreaExpansion: useRef(null),
        costToCompleteFurtherStages: useRef(null),
        expectedMonthsToCompleteExpansion: useRef(null),
    };

    useEffect(() => {
        for (const fieldName in inputRefs) {
            if (formik.errors[fieldName] && formik.touched[fieldName]) {
                inputRefs[fieldName]?.current?.focus();
                break;
            }
        }
    }, [formik.errors, formik.touched]);

    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header
                    pageTitle={localStorage.getItem('selectedFacilityName')}
                    verificationStatus={localStorage.getItem('selectedFacilityVerificationStatus')}
                />
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="">
                        {selectedFacility && (user.role === "SuperAdmin" ? selectedPortfolio !== null : true) ? (
                            <div>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <p className='text-danger fs-13 mb-0'>
                                                    The accuracy of Storval relies upon the accuracy of the data inputted by you as the user, particularly in this section. <br/>
                                                    Storval recommends that this section is completed with our guidance; and reviewed at least every six-months to maintain relevance. <br/>
                                                    Please contact Storval if you have any questions, or you are unsure of your inputs.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label className="form-label w-50" htmlFor="portfolio">Portfolio</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="portfolio" placeholder="Portfolio Name" aria-label="Portfolio" disabled value={currentFacility && currentFacility.portfolio ? currentFacility.portfolio.name : ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label className="form-label w-50" htmlFor="currentDate">Current Date</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="currentDate" placeholder="DD-MMM-YYYY" aria-label="Current Date" disabled value={moment().format('DD-MMM-YY')} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label className="form-label w-50" htmlFor="facilityName">Facility Name</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="facilityName" placeholder="Facility Name" aria-label="Facility Name" disabled value={currentFacility?.name || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label className="form-label w-50" htmlFor="facilityAddress">Facility Address</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="facilityAddress" placeholder="Facility Address" aria-label="Facility Address" disabled value={currentFacility?.address || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="state" className="form-label w-50">State</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="state" placeholder="State" aria-label="State" disabled value={currentFacility?.state || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="postCode" className="form-label w-50">Post Code</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="postCode" placeholder="Post Code" aria-label="Post Code" disabled value={currentFacility?.postCode || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="country" className="form-label w-50">Country</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="country" placeholder="Country" aria-label="Country" disabled value={currentFacility?.country || ''} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form id="formClientInput" method="POST" onSubmit={formik.handleSubmit}>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label className="form-label w-50" htmlFor="baseCountry">Base Country</label>
                                                        <select
                                                            disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                            id="baseCountry"
                                                            className="select2 form-select form-control form-select-sm form-control-sm w-50"
                                                            name="country"
                                                            value={formik.values.country}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                const selectedCountry = e.target.value;
                                                                if (selectedCountry === "Australia") {
                                                                  formik.setFieldValue("currency", "AUD");
                                                                } else if (selectedCountry === "New Zealand") {
                                                                  formik.setFieldValue("currency", "NZD");
                                                                }
                                                              }}
                                                            onBlur={formik.handleBlur}
                                                        >
                                                            <option value="">Select Base Country</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                        </select>
                                                        {formik.touched.country && formik.errors.country && (
                                                            <div className="text-danger">{formik.errors.country}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label className="form-label w-50" htmlFor="financialYear">Financial Year</label>
                                                        <select id="financialYear" className="select2 form-select form-control form-control-sm w-50"
                                                            name="financialYear"
                                                            value={formik.values.financialYear}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                        >
                                                            <option value="">Select</option>
                                                            {/* <option value='1st January - 31st December'>1st January - 31st December</option> */}
                                                            <option value='1st April - 31st March'>1st April - 31st March</option>
                                                            <option value='1st July - 30th June'>1st July - 30th June</option>
                                                            {/* <option value='1st October - 30th September'>1st October - 30th September</option> */}

                                                            {/* <option value='16th July - 15th July'>16th July - 15th July</option>
                                                            <option value='21st December - 20th December'>21st December - 20th December</option>
                                                            <option value='21st March - 20th March'>21st March - 20th March</option>
                                                            <option value='6th April - 5th April'>6th April - 5th April</option>
                                                            <option value='8th July - 7th July'>8th July - 7th July</option>
                                                            <option value='1st June - 31st May'>1st June - 31st May</option> */}
                                                        </select>

                                                        {formik.touched.financialYear && formik.errors.financialYear && (
                                                            <div className="text-danger">{formik.errors.financialYear}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label className="form-label w-50" htmlFor="mostRecentFY">Most Recent FY</label>
                                                        <input disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'} type="number" className="form-control form-control-sm w-50" id="mostRecentFY" placeholder="Enter Most Recent FY (YYYY)" aria-label="Most Recent FY" name="mostRecentFY" value={formik.values.mostRecentFY} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                        {formik.touched.mostRecentFY && formik.errors.mostRecentFY && (
                                                            <div className="text-danger">{formik.errors.mostRecentFY}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label htmlFor="adoptedValuation" className="form-label w-50">Adopted Valuation</label>
                                                        <input type="text" className="form-control form-control-sm w-50" id="adoptedValuation" placeholder="Enter Adopted Valuation Value" aria-label="Valuation Adopted" name="adoptedValuation" value={formik.values.adoptedValuation} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled />
                                                        {formik.touched.adoptedValuation && formik.errors.adoptedValuation && (
                                                            <div className="text-danger">{formik.errors.adoptedValuation}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='d-flex mb-3'>
                                                        <div className='col-md-6'>
                                                            <label className="form-label" htmlFor="currency">Currency</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input disabled
                                                            // ={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="currency"
                                                                placeholder="Enter Currency"
                                                                aria-label="Currency"
                                                                name="currency"
                                                                value={formik.values.currency}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                
                                                            />
                                                            {formik.touched.currency && formik.errors.currency && (
                                                                <div className="text-danger">{formik.errors.currency}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='d-flex mb-3'>
                                                        <div className='col-md-6'>
                                                            <label className="form-label" htmlFor="titleReference">Title Reference</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="titleReference"
                                                                placeholder="Enter Title Reference"
                                                                aria-label="Title Reference"
                                                                name="titleReference"
                                                                value={formik.values.titleReference}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.titleReference && formik.errors.titleReference && (
                                                                <div className="text-danger">{formik.errors.titleReference}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label htmlFor="currentSiteValueAssessment" className="form-label w-50">Current Site Value Assessment (for Land Tax)</label>
                                                        <input disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                            type="text"
                                                            className="form-control form-control-sm w-50"
                                                            id="currentSiteValueAssessment"
                                                            placeholder="$0"
                                                            aria-label="Current Site Value Assessment (for Land Tax)"
                                                            name="currentSiteValueAssessment"
                                                            value={formatter.format(parseFloat(formik.values.currentSiteValueAssessment || 0).toFixed(2))}
                                                            onChange={(e) => {
                                                                const rawValue = removeFormatting(e.target.value);
                                                                if (/^\d*\.?\d*$/.test(rawValue)) { // Validate input
                                                                    formik.setFieldValue('currentSiteValueAssessment', rawValue);
                                                                }
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.currentSiteValueAssessment && formik.errors.currentSiteValueAssessment && (
                                                            <div className="text-danger">{formik.errors.currentSiteValueAssessment}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex  align-items-center">
                                                        <label className="form-label w-50" htmlFor="storeType">Store Type</label>
                                                        <div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input cursor-pointer"
                                                                    type="radio"
                                                                    name="storeType"
                                                                    id="inlineRadioStormanStoreType"
                                                                    value="Storman"
                                                                    checked={storeType === "Storman"}
                                                                    onChange={handleRadioChangeStoreType}
                                                                    disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                />
                                                                <label className="form-check-label" htmlFor="storeType">Storman</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input cursor-pointer"
                                                                    type="radio"
                                                                    name="storeType"
                                                                    id="inlineRadioSitelinkStoreType"
                                                                    value="Sitelink"
                                                                    checked={storeType === "Sitelink"}
                                                                    onChange={handleRadioChangeStoreType}
                                                                    disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                />
                                                                <label className="form-check-label" htmlFor="inlineRadio2">Sitelink</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3">
                                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                                            <label className="form-label d-block" htmlFor="isNetStorageAreaNeedtoBeAmended">Does the Net Storage Area need to be amended?</label>
                                                            <span className="badge badge-center rounded-pill bg-label-success">
                                                                <ArrowTooltips
                                                                    title={`Use this section when non-storage areas are captured in Storman/Sitelink as units. For example, a telecommunication tower or caretaker’s residence. These areas should be captured in the non-storage income tab.<br/><br/>This section can also be used to remove areas to make way for an expansion that is being included in the assessment.<br/><br/>Be sure to use the negative sign to imply a deduction (e.g. -1000)`}
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                className="form-check-input cursor-pointer"
                                                                type="radio"
                                                                name="isNetStorageAreaNeedtoBeAmended"
                                                                id="inlineRadioNoIsAreaAdjusted"
                                                                value="No"
                                                                checked={isNetStorageAreaNeedtoBeAmended === "No"}
                                                                onChange={handleRadioChangeAdjustedNetStorageArea}
                                                            />
                                                            <label className="form-check-label" htmlFor="isNetStorageAreaNeedtoBeAmended">No</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                className="form-check-input cursor-pointer"
                                                                type="radio"
                                                                name="isNetStorageAreaNeedtoBeAmended"
                                                                id="inlineRadioYesIsAreaAdjusted"
                                                                value="Yes"
                                                                checked={isNetStorageAreaNeedtoBeAmended === "Yes"}
                                                                onChange={handleRadioChangeAdjustedNetStorageArea}
                                                            />
                                                            <label className="form-check-label" htmlFor="inlineRadio2">Yes</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isNetStorageAreaNeedtoBeAmended === 'Yes' && (
                                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                                                        <div className="row">
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="currentNetStorageArea">Current Net Storage Area (m²)</label>
                                                                    <input
                                                                        disabled
                                                                        type="text"
                                                                        className="form-control form-control-sm w-50"
                                                                        id="currentNetStorageArea"
                                                                        placeholder="0.00"
                                                                        aria-label="Current Net Storage Area (m²)"
                                                                        name="currentNetStorageArea"
                                                                        // value={currentFacility?.apiDataObj?.facilityApiDataArr[0]?.existingNetStorageArea || ''}
                                                                        value={formatValue(parseFloat(removeFormatting(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.existingNetStorageArea || ''))) || ''}
                                                                    />
                                                                </div>
                                                            </div> 
                                                            <div className="col-xxl-65 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="currentAreaOccupiedmetreSq.">Current Area Occupied (m²)</label>
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control form-control-sm w-50" 
                                                                        id="currentAreaOccupiedmetreSq." 
                                                                        placeholder="0.00" 
                                                                        aria-label="Current Area Occupied"
                                                                        name="currentAreaOccupiedmetreSq."
                                                                        disabled
                                                                        title='currentAreaOccupiedmetreSq. = Net Storage Area Occupied From API data (latest month)' 
                                                                        // value={currentFacility?.apiDataObj?.facilityApiDataArr[0]?.netStorageAreaOccupied || ''}
                                                                        value={formatValue(parseFloat(removeFormatting(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.netStorageAreaOccupied || ''))) || ''}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="adjustedCurrentNetStorageArea">Adjustment (m²)</label>
                                                                    <input
                                                                        disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                        type="text"
                                                                        className="form-control form-control-sm w-50"
                                                                        id="adjustedCurrentNetStorageArea"
                                                                        placeholder="0.00"
                                                                        aria-label="Adjustment (m²)"
                                                                        name="adjustedCurrentNetStorageArea"
                                                                        value={formik.values.adjustedCurrentNetStorageArea}
                                                                        onChange={(e) => {
                                                                            let rawValue = e.target.value.replace(/,/g, '').trim();

                                                                            // Allow +, - and decimal numbers
                                                                            if (/^[+-]?\d*\.?\d*$/.test(rawValue)) {
                                                                                formik.setFieldValue('adjustedCurrentNetStorageArea', rawValue);

                                                                                // Only calculate if rawValue has at least one digit
                                                                                if (rawValue.match(/[\d]/)) {
                                                                                    const adjustedValue = calculateAdjustedStorageArea(
                                                                                        currentFacility?.apiDataObj?.facilityApiDataArr[0]?.existingNetStorageArea || '',
                                                                                        rawValue
                                                                                    );
                                                                                    formik.setFieldValue('adjustedNetStorageArea', adjustedValue);
                                                                                    formik.setFieldValue('existingNetStorageArea', adjustedValue);
                                                                                } else {
                                                                                    // Reset adjusted values if no valid number is entered
                                                                                    formik.setFieldValue('adjustedNetStorageArea', '');
                                                                                    formik.setFieldValue('existingNetStorageArea', '');
                                                                                }
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            let rawValue = e.target.value.replace(/,/g, '').trim();
                                                                            const parsedValue = parseFloat(rawValue);

                                                                            formik.setFieldValue(
                                                                                'adjustedCurrentNetStorageArea',
                                                                                !isNaN(parsedValue) 
                                                                                    ? (parsedValue > 0 ? '+' : '') + parsedValue.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                                    : ''
                                                                            );
                                                                            formik.handleBlur(e);
                                                                        }}
                                                                        onFocus={(e) => {
                                                                            let rawValue = formik.values.adjustedCurrentNetStorageArea || '';
                                                                            rawValue = rawValue.toString().replace(/,/g, '').trim();
                                                                            
                                                                            if (rawValue.startsWith('+')) {
                                                                                rawValue = rawValue.substring(1);
                                                                            }
                                                                            
                                                                            formik.setFieldValue('adjustedCurrentNetStorageArea', rawValue);
                                                                        }}
                                                                    />
                                                                    {formik.touched.adjustedCurrentNetStorageArea && formik.errors.adjustedCurrentNetStorageArea && (
                                                                        <div className="text-danger">{formik.errors.adjustedCurrentNetStorageArea}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="adjustedAreaOccupied">Adjustment (m²)</label>
                                                                    <input
                                                                        disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                        type="text"
                                                                        className="form-control form-control-sm w-50"
                                                                        id="adjustedAreaOccupied"
                                                                        placeholder="0.00"
                                                                        aria-label="Adjustment (m²)"
                                                                        name="adjustedAreaOccupied"
                                                                        value={formik.values.adjustedAreaOccupied}
                                                                        onChange={(e) => {
                                                                            let rawValue = e.target.value.replace(/,/g, '').trim();

                                                                            if (/^[+-]?\d*\.?\d*$/.test(rawValue)) {
                                                                                formik.setFieldValue('adjustedAreaOccupied', rawValue);
                                                                                if (rawValue.match(/[\d]/)) {
                                                                                const adjustedValue = calculateAdjustedStorageAreaOccupied(currentFacility?.apiDataObj?.facilityApiDataArr[0]?.netStorageAreaOccupied || '', rawValue);
                                                                                formik.setFieldValue('adjustedCurrentAreaOccupied', adjustedValue);
                                                                                formik.setFieldValue('currentAreaOccupied', adjustedValue);
                                                                            } else {
                                                                                formik.setFieldValue('adjustedCurrentAreaOccupied', '');
                                                                                formik.setFieldValue('currentAreaOccupied', '');
                                                                            }
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            let rawValue = e.target.value.replace(/,/g, '').trim();
                                                                            const parsedValue = parseFloat(rawValue);

                                                                            formik.setFieldValue(
                                                                                'adjustedAreaOccupied',
                                                                                !isNaN(parsedValue) 
                                                                                    ? (parsedValue > 0 ? '+' : '') + parsedValue.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                                    : ''
                                                                            );
                                                                            formik.handleBlur(e);
                                                                        }}
                                                                        onFocus={(e) => {
                                                                            let rawValue = formik.values.adjustedAreaOccupied || ''; 
                                                                            rawValue = rawValue.toString().replace(/,/g, '').trim();
                                                                            if (rawValue.startsWith('+')) {
                                                                                rawValue = rawValue.substring(1);
                                                                            } 
                                                                            formik.setFieldValue('adjustedAreaOccupied', rawValue);
                                                                        }}
                                                                    />
                                                                    {formik.touched.adjustedAreaOccupied && formik.errors.adjustedAreaOccupied && (
                                                                        <div className="text-danger">{formik.errors.adjustedAreaOccupied}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="adjustedNetStorageArea">Adjusted Net Storage Area (m²)</label>
                                                                    <input
                                                                        disabled
                                                                        type="text"
                                                                        className="form-control form-control-sm w-50"
                                                                        id="adjustedNetStorageArea"
                                                                        placeholder="0.00"
                                                                        aria-label="Adjusted Net Storage Area (m²)"
                                                                        name="adjustedNetStorageArea"
                                                                        value={formik.values.adjustedNetStorageArea}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="adjustedCurrentAreaOccupied">Adjusted Current Area Occupied (m²)</label>
                                                                    <input 
                                                                        disabled
                                                                        type="text" 
                                                                        className="form-control form-control-sm w-50" 
                                                                        id="adjustedCurrentAreaOccupied" 
                                                                        placeholder="0.00" 
                                                                        aria-label="Current Area Occupied "
                                                                        name="adjustedCurrentAreaOccupied"
                                                                        value={formik.values.adjustedCurrentAreaOccupied}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3">
                                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                                            <label className="form-label d-block" htmlFor="isFacilityExpandedIn12Months">Is the Facility being Expanded within the next 12 months?</label>
                                                            <span className="badge badge-center rounded-pill bg-label-success">
                                                                <ArrowTooltips
                                                                    title={`Use this section to capture an imminent expansion. Note that an imminent expansion is one that is expected to occur inside the next 12 months. It should therefore be Council approved, with construction / fit-out costs known.<br/><br/>All cells must be populated for the expansion to be correctly considered.<br/><br/>Accuracy with the number of months to complete the expansion is important as it defers revenue build up for the expansion area in the assessment.`}
                                                                />
                                                            </span>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input cursor-pointer"
                                                                type="radio"
                                                                name="isFacilityExpandedIn12Months"
                                                                id="inlineRadioNoIsExpanded"
                                                                value="No"
                                                                checked={isFacilityExpandedIn12Months === "No"}
                                                                onChange={handleRadioChangeIsExpanded}
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                            />
                                                            <label className="form-check-label" htmlFor="isFacilityExpandedIn12Months">No</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input cursor-pointer"
                                                                type="radio"
                                                                name="isFacilityExpandedIn12Months"
                                                                id="inlineRadioYesIsExpanded"
                                                                value="Yes"
                                                                checked={isFacilityExpandedIn12Months === "Yes"}
                                                                onChange={handleRadioChangeIsExpanded}
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                            />
                                                            <label className="form-check-label" htmlFor="inlineRadio2">Yes</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isFacilityExpandedIn12Months === 'Yes' && (
                                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                                                        <div className="row">
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="existingNetStorageArea">Existing Net Storage Area (m²)</label>
                                                                        <input
                                                                            disabled
                                                                            type="text"
                                                                            className="form-control form-control-sm w-50"
                                                                            // disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'} 
                                                                            id="existingNetStorageArea"
                                                                            placeholder="0.00"
                                                                            aria-label="Existing Net Storage Area (m²)"
                                                                            name="existingNetStorageArea"
                                                                            value={formatValue(parseFloat(removeFormatting(formik.values.existingNetStorageArea)))}
                                                                            onChange={(e) => {
                                                                                let rawValue = e.target.value.replace(/,/g, '').trim();
                                                                                if (/^\d*\.?\d*$/.test(rawValue)) {
                                                                                    formik.setFieldValue('existingNetStorageArea', rawValue);
                                                                                }
                                                                            }}
                                                                            onBlur={(e) => {
                                                                                let rawValue = e.target.value.replace(/,/g, '').trim();
                                                                                const parsedValue = parseFloat(rawValue);
                                                                                formik.setFieldValue(
                                                                                    'existingNetStorageArea',
                                                                                    !isNaN(parsedValue) ? parsedValue.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
                                                                                );
                                                                                formik.handleBlur(e);
                                                                            }}
                                                                            onFocus={(e) => {
                                                                                let rawValue = formik.values.existingNetStorageArea || ''; 
                                                                                rawValue = rawValue.toString().replace(/,/g, '').trim(); 
                                                                                formik.setFieldValue('existingNetStorageArea', rawValue);
                                                                            }}
                                                                        />
                                                                </div>
                                                                {formik.touched.existingNetStorageArea && formik.errors.existingNetStorageArea && (
                                                                    <div className="text-danger">{formik.errors.existingNetStorageArea}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className={`d-flex justify-content-between align-items-center ${formik.touched.costToCompleteFurtherStages && formik.errors.costToCompleteFurtherStages ? '' : 'mb-3'}`}>
                                                                    <label className="form-label w-50" htmlFor="costToCompleteFurtherStages">Cost to Complete Further Stages (inc. contingency) ex. GST</label>
                                                                    <input 
                                                                        ref={inputRefs["costToCompleteFurtherStages"]}
                                                                        disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'} type="text" 
                                                                        className="form-control form-control-sm w-50" id="costToCompleteFurtherStages" 
                                                                        placeholder="$0" 
                                                                        aria-label="Cost to Complete Further Stages (inc. contingency) ex. GST" 
                                                                        name="costToCompleteFurtherStages"
                                                                        // value={formik.values.costToCompleteFurtherStages}
                                                                        // onChange={formik.handleChange}
                                                                        // onBlur={formik.handleBlur}
                                                                        value={formatter.format(parseFloat(formik.values.costToCompleteFurtherStages))}
                                                                        onChange={(e) => {
                                                                            const rawValue = removeFormatting(e.target.value);
                                                                            if (/^\d*\.?\d*$/.test(rawValue)) { // Validate input
                                                                                formik.setFieldValue('costToCompleteFurtherStages', rawValue);
                                                                            }
                                                                        }}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </div>
                                                                {formik.touched.costToCompleteFurtherStages && formik.errors.costToCompleteFurtherStages && (
                                                                    <div className="text-danger mb-3 text-end">{formik.errors.costToCompleteFurtherStages}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className={`d-flex justify-content-between align-items-center ${formik.touched.costToCompleteFurtherStages && formik.errors.costToCompleteFurtherStages ? '' : 'mb-3'}`}>
                                                                    <label className="form-label w-50" htmlFor="netStorageAreaExpansion">Net Storage Area of expansion area / additional stage (m)</label>
                                                                    <input
                                                                        ref={inputRefs["netStorageAreaExpansion"]}
                                                                        type="text"
                                                                        className="form-control form-control-sm w-50"
                                                                        id="netStorageAreaExpansion"
                                                                        disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'} 
                                                                        placeholder="0.00"
                                                                        aria-label="Net Storage Area of expansion area / additional stage (m)"
                                                                        name="netStorageAreaExpansion"
                                                                        value={formik.values.netStorageAreaExpansion}
                                                                        onChange={(e) => {
                                                                            let rawValue = e.target.value.replace(/,/g, '').trim();
                                                                            if (/^\d*\.?\d*$/.test(rawValue)) {
                                                                                formik.setFieldValue('netStorageAreaExpansion', rawValue);
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            let rawValue = e.target.value.replace(/,/g, '').trim();
                                                                            const parsedValue = parseFloat(rawValue);
                                                                            formik.setFieldValue(
                                                                                'netStorageAreaExpansion',
                                                                                !isNaN(parsedValue) ? parsedValue.toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''
                                                                            );
                                                                            formik.handleBlur(e);
                                                                        }}
                                                                        onFocus={(e) => {
                                                                            let rawValue = formik.values.netStorageAreaExpansion || ''; 
                                                                            rawValue = rawValue.toString().replace(/,/g, '').trim(); 
                                                                            formik.setFieldValue('netStorageAreaExpansion', rawValue);
                                                                        }}
                                                                    />
                                                                </div>
                                                                {formik.touched.netStorageAreaExpansion && formik.errors.netStorageAreaExpansion && (
                                                                    <div className="text-danger mb-3 text-end">{formik.errors.netStorageAreaExpansion}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="ratePerSquareMetreConstructionCost">Rate per square metre of construction cost </label>
                                                                    <input type="text" className="form-control form-control-sm w-50" id="ratePerSquareMetreConstructionCost" placeholder="$0" aria-label="Rate per square metre of construction cost " name="ratePerSquareMetreConstructionCost"

                                                                        value={formatter.format(parseFloat(formik.values.ratePerSquareMetreConstructionCost || 0).toFixed(2))}
                                                                        onChange={(e) => {
                                                                            const rawValue = removeFormatting(e.target.value);
                                                                            if (/^\d*\.?\d*$/.test(rawValue)) { // Validate input
                                                                                formik.setFieldValue('ratePerSquareMetreConstructionCost', rawValue);
                                                                            }
                                                                        }}
                                                                        onBlur={formik.handleBlur}
                                                                        //  value={formik.values.ratePerSquareMetreConstructionCost}  
                                                                        //  onChange={formik.handleChange} 
                                                                        //  onBlur={formik.handleBlur} 
                                                                        disabled title={`ratePerSquareMetreConstructionCost = costToCompleteFurtherStages/netStorageAreaExpansion`}
                                                                    />
                                                                </div>
                                                                {formik.touched.ratePerSquareMetreConstructionCost && formik.errors.ratePerSquareMetreConstructionCost && (
                                                                    <div className="text-danger">{formik.errors.ratePerSquareMetreConstructionCost}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="totalNetStorageAreaCompletion">Total Net Storage Area upon completion (m²)</label>
                                                                    <input type="text" className="form-control form-control-sm w-50" id="totalNetStorageAreaCompletion" placeholder="0.00" aria-label="Total Net Storage Area upon completion (m²)" name="totalNetStorageAreaCompletion"
                                                                        value={formatValue(parseFloat(removeFormatting(formik.values.totalNetStorageAreaCompletion)), 'totalNetStorageAreaCompletion')}
                                                                        onChange={(e) => {
                                                                            const rawValue = e.target.value;
                                                                            formik.setFieldValue('totalNetStorageAreaCompletion', rawValue);
                                                                        }}
                                                                        // value={formik.values.totalNetStorageAreaCompletion}  
                                                                        // onChange={formik.handleChange} 
                                                                        onBlur={formik.handleBlur}
                                                                        disabled
                                                                        title={`totalNetStorageAreaCompletion = existingNetStorageArea(From API) + netStorageAreaExpansion`}
                                                                    />
                                                                </div>
                                                                {formik.touched.totalNetStorageAreaCompletion && formik.errors.totalNetStorageAreaCompletion && (
                                                                    <div className="text-danger">{formik.errors.totalNetStorageAreaCompletion}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className={`d-flex justify-content-between align-items-center ${formik.touched.expectedMonthsToCompleteExpansion && formik.errors.expectedMonthsToCompleteExpansion ? '' : 'mb-3'}`}>
                                                                    <label className="form-label w-50" htmlFor="expectedMonthsToCompleteExpansion">Expected Number of Months to Complete Expansion (Max 12)</label>
                                                                    <input 
                                                                        ref={inputRefs["expectedMonthsToCompleteExpansion"]}
                                                                        disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                        type="number"
                                                                        className="form-control form-control-sm w-50"
                                                                        id="expectedMonthsToCompleteExpansion"
                                                                        placeholder="0"
                                                                        aria-label="Expected Number of Months to Complete Expansion (Max 12)"
                                                                        max={12}
                                                                        name="expectedMonthsToCompleteExpansion"
                                                                        value={formik.values.expectedMonthsToCompleteExpansion}
                                                                        onChange={(e) => {
                                                                            const value = Math.min(Math.max(e.target.value, 0), 12); // Ensure value is between 0 and 12
                                                                            formik.setFieldValue("expectedMonthsToCompleteExpansion", value);
                                                                        }}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                </div>
                                                                {formik.touched.expectedMonthsToCompleteExpansion && formik.errors.expectedMonthsToCompleteExpansion && (
                                                                    <div className="text-danger mb-3 text-end">{formik.errors.expectedMonthsToCompleteExpansion}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="completedExpansionDate">Date Expansion is expected to be completed </label>
                                                                    <input type="date" className="form-control form-control-sm w-50 cursor-pointer" id="completedExpansionDate" placeholder="DD-MMM-YYYY" aria-label="Date Expansion is expected to be completed " name="completedExpansionDate" value={formik.values.completedExpansionDate} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled title='completedExpansionDate = currentDate + expectedMonthsToCompleteExpansion' />
                                                                    {formik.touched.completedExpansionDate && formik.errors.completedExpansionDate && (
                                                                        <div className="text-danger">{formik.errors.completedExpansionDate}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3">
                                                        <div className="d-flex align-items-baseline justify-content-between">
                                                            <label className="form-label d-block" htmlFor="isFacilityTradingAtMaturity">Is the Facility trading at Maturity?</label>
                                                            <div className='d-flex align-items-baseline justify-content-between'>
                                                                <p className='text-danger fs-13 mb-0 me-2'>*It is strongly recommended to refer to Storval for guidance on the inputs in this section.</p>
                                                                <span className="badge badge-center rounded-pill bg-label-success">
                                                                    <ArrowTooltips
                                                                        title={`Use this section when the facility is building occupancy or is trading below market levels.<br/><br/>Note that this section must be populated if you have added an expansion. However, you can leave cells blank if you want Storval to use the passing rate for occupancy, ee rate or ‘other income’.<br/><br/>Note that all trading metrics are blended across all storage areas (storage units, open storage, wine storage etc). The Stabilised Average Mature Fee Rate (Current Market) is the blended fee rate across all storage areas in the facility. Keep in mind that if the facility has a high proportion of ‘open storage’, a lower average mature fee rate to the passing fee rate may be applicable.<br/><br/>Likewise, occupancy and number of months to reach maturity are blended across all storage areas.`}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input cursor-pointer"
                                                                type="radio"
                                                                name="isFacilityTradingAtMaturity"
                                                                id="inlineRadioNoIsAtMaturity"
                                                                value="No"
                                                                checked={isFacilityTradingAtMaturity === "No"}
                                                                onChange={handleRadioChangeIsAtMaturity}
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                            />
                                                            <label className="form-check-label" htmlFor="inlineRadio1">No</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input cursor-pointer"
                                                                type="radio"
                                                                name="isFacilityTradingAtMaturity"
                                                                id="inlineRadioYesIsAtMaturity"
                                                                value="Yes"
                                                                checked={isFacilityTradingAtMaturity === "Yes"}
                                                                onChange={handleRadioChangeIsAtMaturity}
                                                                disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                            />
                                                            <label className="form-check-label" htmlFor="isFacilityTradingAtMaturity">Yes</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isFacilityTradingAtMaturity === 'No' && (
                                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2">
                                                        <div className="row">
                                                            <div className="col-xxl-65 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="currentAreaOccupied">Current Area Occupied </label>
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control form-control-sm w-50" 
                                                                        id="currentAreaOccupied" 
                                                                        placeholder="0" 
                                                                        aria-label="Current Area Occupied "
                                                                        name="currentAreaOccupied"
                                                                        value={formatValue(parseFloat(removeFormatting(formik.values.currentAreaOccupied)), 'currentAreaOccupied')}
                                                                        onChange={(e) => {
                                                                            const rawValue = e.target.value;
                                                                            formik.setFieldValue('currentAreaOccupied', rawValue);
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const rawValue = e.target.value;
                                                                            const parsedValue = parseFloat(removeFormatting(rawValue));
                                                                            formik.setFieldValue('currentAreaOccupied', formatValue(parsedValue, 'currentAreaOccupied in blur'));
                                                                            formik.handleBlur(e);
                                                                        }}
                                                                        // value={formik.values.currentAreaOccupied}  
                                                                        // onChange={formik.handleChange} 
                                                                        // onBlur={formik.handleBlur} 
                                                                        disabled
                                                                        title='currentAreaOccupied = Net Storage Area Occupied From API data (latest month)' />
                                                                    {formik.touched.currentAreaOccupied && formik.errors.currentAreaOccupied && (
                                                                        <div className="text-danger">{formik.errors.currentAreaOccupied}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="currentOccupancyAcrossNetStorageArea">Current Occupancy across total NSA (incl. expansion)</label>
                                                                    <input type="text" className="form-control form-control-sm w-50" id="currentOccupancyAcrossNetStorageArea"
                                                                        placeholder="0.00%" aria-label="Current Occupancy across total NSA (incl. expansion)"
                                                                        name="currentOccupancyAcrossNetStorageArea"
                                                                        // value={formatValue(parseFloat(removeFormatting(formik.values.currentOccupancyAcrossNetStorageArea)), 'currentOccupancyAcrossNetStorageArea')}
                                                                        // onChange={(e) => {
                                                                        //     const rawValue = e.target.value;
                                                                        //     formik.setFieldValue('currentOccupancyAcrossNetStorageArea', rawValue);
                                                                        // }}
                                                                        // onBlur={formik.handleBlur} 
                                                                        value={
                                                                            formik.values.currentOccupancyAcrossNetStorageArea !== ""
                                                                                ? `${formatValue(parseFloat(removeFormatting(formik.values.currentOccupancyAcrossNetStorageArea)), 'currentOccupancyAcrossNetStorageArea')}%`
                                                                                : ""
                                                                        }
                                                                        onChange={(e) => {
                                                                            const rawValue = e.target.value.replace('%', '');
                                                                            formik.setFieldValue('currentOccupancyAcrossNetStorageArea', rawValue);
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const rawValue = e.target.value.replace('%', '').trim();
                                                                            formik.setFieldValue(
                                                                                'currentOccupancyAcrossNetStorageArea',
                                                                                `${rawValue}%`
                                                                            );
                                                                        }}

                                                                        disabled
                                                                        title='currentOccupancyAcrossNetStorageArea = currentAreaOccupied / totalNetStorageAreaCompletion'
                                                                    />
                                                                    {formik.touched.currentOccupancyAcrossNetStorageArea && formik.errors.currentOccupancyAcrossNetStorageArea && (
                                                                        <div className="text-danger">{formik.errors.currentOccupancyAcrossNetStorageArea}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="stabilisedOccupiedNSAAtMaturity">Stabilised Occupied Net Storage Area (NSA) at Maturity </label>
                                                                    <input type="text" className="form-control form-control-sm w-50" id="stabilisedOccupiedNSAAtMaturity" placeholder="0" aria-label="Stabilised Occupied Net Storage Area (NSA) at Maturity" name="stabilisedOccupiedNSAAtMaturity"
                                                                        value={formik.values.stabilisedOccupiedNSAAtMaturity}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                        disabled
                                                                        title='stabilisedOccupiedNSAAtMaturity = totalNetStorageAreaCompletion * stabilisedMaintainableOccupancyAtMaturity'
                                                                    />
                                                                    {formik.touched.stabilisedOccupiedNSAAtMaturity && formik.errors.stabilisedOccupiedNSAAtMaturity && (
                                                                        <div className="text-danger">{formik.errors.stabilisedOccupiedNSAAtMaturity}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="stabilisedMaintainableOccupancyAtMaturity">Stabilised Maintainable Occupancy at Maturity (across entire facility)</label>
                                                                    <input disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                        type="text"
                                                                        className="form-control form-control-sm w-50"
                                                                        id="stabilisedMaintainableOccupancyAtMaturity"
                                                                        placeholder="0.00%"
                                                                        aria-label="Stabilised Maintainable Occupancy at Maturity (across entire facility)"
                                                                        name="stabilisedMaintainableOccupancyAtMaturity"
                                                                        value={
                                                                            formik.values.stabilisedMaintainableOccupancyAtMaturity && !formik.values.stabilisedMaintainableOccupancyAtMaturity.endsWith('%')
                                                                                ? formik.values.stabilisedMaintainableOccupancyAtMaturity + '%'
                                                                                : formik.values.stabilisedMaintainableOccupancyAtMaturity
                                                                        }
                                                                        onChange={(e) => {
                                                                            let rawValue = e.target.value.replace('%', '').trim();
                                                                            formik.setFieldValue('stabilisedMaintainableOccupancyAtMaturity', rawValue);
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            let rawValue = e.target.value.replace('%', '').trim();
                                                                            formik.setFieldValue(
                                                                                'stabilisedMaintainableOccupancyAtMaturity',
                                                                                rawValue ? `${rawValue}%` : ''
                                                                            );
                                                                        }}
                                                                        onFocus={(e) => {
                                                                            let rawValue = formik.values.stabilisedMaintainableOccupancyAtMaturity.replace('%', '').trim();
                                                                            formik.setFieldValue('stabilisedMaintainableOccupancyAtMaturity', rawValue);
                                                                        }}
                                                                    />
                                                                    {formik.touched.stabilisedMaintainableOccupancyAtMaturity && formik.errors.stabilisedMaintainableOccupancyAtMaturity && (
                                                                        <div className="text-danger">{formik.errors.stabilisedMaintainableOccupancyAtMaturity}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="monthsToReachMatureOccupancy">No. of Months to reach Mature Occupancy</label>
                                                                    <input disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'} type="number" className="form-control form-control-sm w-50" id="monthsToReachMatureOccupancy" placeholder="0" aria-label="No. of Months to reach Mature Occupancy" name="monthsToReachMatureOccupancy" value={formik.values.monthsToReachMatureOccupancy} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                                    {formik.touched.monthsToReachMatureOccupancy && formik.errors.monthsToReachMatureOccupancy && (
                                                                        <div className="text-danger">{formik.errors.monthsToReachMatureOccupancy}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="passingAverageStorageFeeRate">Passing Average Storage Fee Rate </label>
                                                                    <input type="text" className="form-control form-control-sm w-50" id="passingAverageStorageFeeRate" placeholder="$0" aria-label="Passing Average Storage Fee Rate "
                                                                        name="passingAverageStorageFeeRate"
                                                                        value={"$" + NumberFormatter.format((removeFormatting(formik.values.passingAverageStorageFeeRate)))}
                                                                        onChange={(e) => {
                                                                            const rawValue = e.target.value;
                                                                            formik.setFieldValue('passingAverageStorageFeeRate', rawValue);
                                                                        }}
                                                                        // onBlur={(e) => {
                                                                        //     const rawValue = e.target.value;
                                                                        //     const parsedValue = parseFloat(removeFormatting(rawValue));
                                                                        //     formik.setFieldValue('passingAverageStorageFeeRate', formatValue(parsedValue));
                                                                        //     formik.handleBlur(e);
                                                                        // }}
                                                                        // value={formik.values.passingAverageStorageFeeRate}  
                                                                        // onChange={formik.handleChange} 
                                                                        onBlur={formik.handleBlur}
                                                                        disabled
                                                                        title='passingAverageStorageFeeRate = Average Storage Fee Rate - Actual From API data (latest month)'
                                                                    />
                                                                    {formik.touched.passingAverageStorageFeeRate && formik.errors.passingAverageStorageFeeRate && (
                                                                        <div className="text-danger">{formik.errors.passingAverageStorageFeeRate}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="buildupRateNSAPerMonth">Build up rate (NSA) per month (m²)</label>
                                                                    <input type="text" className="form-control form-control-sm w-50" id="buildupRateNSAPerMonth" placeholder="0" aria-label="Build up rate (NSA) per month (m²)"
                                                                        name="buildupRateNSAPerMonth"
                                                                        value={formatValue(parseFloat(removeFormatting(formik.values.buildupRateNSAPerMonth)), 'buildupRateNSAPerMonth')}
                                                                        onChange={(e) => {
                                                                            const rawValue = e.target.value;
                                                                            formik.setFieldValue('buildupRateNSAPerMonth', rawValue);
                                                                        }}
                                                                        disabled
                                                                        title='buildupRateNSAPerMonth = (stabilisedOccupiedNSAAtMaturity-currentAreaOccupied)/dateMatureOccupancyExpected' />
                                                                    {formik.touched.buildupRateNSAPerMonth && formik.errors.buildupRateNSAPerMonth && (
                                                                        <div className="text-danger">{formik.errors.buildupRateNSAPerMonth}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="stabilisedAverageMatureFeeRate">
                                                                        Stabilised Average Mature Fee Rate (Current Market)
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-control-sm w-50"
                                                                        id="stabilisedAverageMatureFeeRate"
                                                                        placeholder="$0"
                                                                        aria-label="Stabilised Average Mature Fee Rate (Current Market)"
                                                                        name="stabilisedAverageMatureFeeRate"
                                                                        disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                        value={formik.values.stabilisedAverageMatureFeeRate}
                                                                        onChange={(e) => {
                                                                            const rawValue = removeFormatting(e.target.value);
                                                                            if (/^-?\d*\.?\d{0,2}$/.test(rawValue)) {
                                                                                formik.setFieldValue('stabilisedAverageMatureFeeRate', rawValue);
                                                                            }
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            const rawValue = parseFloat(removeFormatting(e.target.value) || 0).toFixed(2);
                                                                            const formattedValue = `$${rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                                                                            formik.setFieldValue('stabilisedAverageMatureFeeRate', formattedValue);
                                                                        }}
                                                                    />
                                                                    {formik.touched.stabilisedAverageMatureFeeRate && formik.errors.stabilisedAverageMatureFeeRate && (
                                                                        <div className="text-danger">{formik.errors.stabilisedAverageMatureFeeRate}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="dateMatureOccupancyExpected">Date Mature Occupancy of Existing Facility is Expected</label>
                                                                    <input type="date" className="form-control form-control-sm w-50 cursor-pointer" id="dateMatureOccupancyExpected" placeholder="DD-MMM-YYYY" aria-label="Date Mature Occupancy of Existing Facility is Expected" name="dateMatureOccupancyExpected" value={formik.values.dateMatureOccupancyExpected} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled title='dateMatureOccupancyExpected = currentDate + monthsToReachMatureOccupancy' />
                                                                    {formik.touched.dateMatureOccupancyExpected && formik.errors.dateMatureOccupancyExpected && (
                                                                        <div className="text-danger">{formik.errors.dateMatureOccupancyExpected}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                                    <label className="form-label w-50" htmlFor="stabilisedOtherIncomePercentage">Stabilised 'Other Income' (% of Gross Revenue)</label>
                                                                    <input disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                                        type="text"
                                                                        className="form-control form-control-sm w-50"
                                                                        id="stabilisedOtherIncomePercentage"
                                                                        placeholder="0.00%"
                                                                        aria-label="Stabilised Maintainable Occupancy at Maturity (across entire facility)"
                                                                        name="stabilisedOtherIncomePercentage"
                                                                        value={
                                                                            formik.values.stabilisedOtherIncomePercentage && !formik.values.stabilisedOtherIncomePercentage.endsWith('%')
                                                                                ? formik.values.stabilisedOtherIncomePercentage + '%'
                                                                                : formik.values.stabilisedOtherIncomePercentage
                                                                        }
                                                                        onChange={(e) => {
                                                                            let rawValue = e.target.value.replace('%', '').trim();
                                                                            formik.setFieldValue('stabilisedOtherIncomePercentage', rawValue);
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            let rawValue = e.target.value.replace('%', '').trim();
                                                                            formik.setFieldValue(
                                                                                'stabilisedOtherIncomePercentage',
                                                                                rawValue ? `${rawValue}%` : ''
                                                                            );
                                                                        }}
                                                                        onFocus={(e) => {
                                                                            let rawValue = (formik.values.stabilisedOtherIncomePercentage || '').replace('%', '').trim();
                                                                            formik.setFieldValue('stabilisedOtherIncomePercentage', rawValue);
                                                                        }}
                                                                    />
                                                                    {formik.touched.stabilisedOtherIncomePercentage && formik.errors.stabilisedOtherIncomePercentage && (
                                                                        <div className="text-danger">{formik.errors.stabilisedOtherIncomePercentage}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label htmlFor="essentialCapitalExpenditure" className="form-label w-50">Essential Capital Expenditure (other than expansion cost)</label>
                                                        <input disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                            type="text"
                                                            className="form-control form-control-sm w-50"
                                                            id="essentialCapitalExpenditure"
                                                            placeholder="$0"
                                                            aria-label="Essential Capital Expenditure (other than expansion cost)"
                                                            name="essentialCapitalExpenditure"
                                                            value={formatter.format(parseFloat(formik.values.essentialCapitalExpenditure) || 0)}
                                                            onBlur={formik.handleBlur}
                                                            onChange={(e) => {
                                                                const rawValue = removeFormatting(e.target.value);

                                                                if (/^-?\d*\.?\d*$/.test(rawValue)) { // Ensure negative values are allowed
                                                                    const parsedValue = parseFloat(rawValue);
                                                                    formik.setFieldValue('essentialCapitalExpenditure', !isNaN(parsedValue) ? parsedValue : '');
                                                                }
                                                            }}
                                                        />
                                                        {formik.touched.essentialCapitalExpenditure && formik.errors.essentialCapitalExpenditure && (
                                                            <div className="text-danger">{formik.errors.essentialCapitalExpenditure}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label htmlFor="valueAdditionalAreas" className="form-label w-50">Value of Additional Area (Surplus Land, etc)</label>
                                                        <input disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'} type="text" className="form-control form-control-sm w-50" id="valueAdditionalAreas"
                                                            placeholder="$0" aria-label="Value of Additional Area (Surplus Land, etc)" name="valueAdditionalAreas"
                                                            onBlur={formik.handleBlur}
                                                            value={formatter.format(parseFloat(formik.values.valueAdditionalAreas || 0).toFixed(2))}
                                                            onChange={(e) => {
                                                                const rawValue = removeFormatting(e.target.value);
                                                                if (/^-?\d*\.?\d*$/.test(rawValue)) {
                                                                    formik.setFieldValue('valueAdditionalAreas', rawValue);
                                                                }
                                                            }}
                                                        />
                                                        {formik.touched.valueAdditionalAreas && formik.errors.valueAdditionalAreas && (
                                                            <div className="text-danger">{formik.errors.valueAdditionalAreas}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label htmlFor="otherAdjustmentToValue" className="form-label w-50">Any Other Adjustment to Value</label>
                                                        <input disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'} type="text" className="form-control form-control-sm w-50" id="otherAdjustmentToValue" placeholder="$0" aria-label="Any Other Adjustment to Value" name="otherAdjustmentToValue"
                                                            // value={formik.values.otherAdjustmentToValue} 
                                                            //  onChange={formik.handleChange} 
                                                            //  onBlur={formik.handleBlur}
                                                            onBlur={formik.handleBlur}
                                                            value={formatter.format(parseFloat(formik.values.otherAdjustmentToValue || 0).toFixed(2))}
                                                            onChange={(e) => {
                                                                const rawValue = removeFormatting(e.target.value);
                                                                if (/^-?\d*\.?\d*$/.test(rawValue)) {
                                                                    formik.setFieldValue('otherAdjustmentToValue', rawValue);
                                                                }
                                                            }}
                                                        />
                                                        {formik.touched.otherAdjustmentToValue && formik.errors.otherAdjustmentToValue && (
                                                            <div className="text-danger">{formik.errors.otherAdjustmentToValue}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-2 mb-4 me-4 d-flex justify-content-end">
                                            {user.role !== "SuperAdmin" && currentFacility.isLocked === '1' ? (
                                                <Tooltip 
                                                    title="This facility is locked. The action cannot be completed at this time."
                                                    size="small"
                                                >
                                                    <span className='cursor-not-allowed-disabled'>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-primary me-2"
                                                            disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                        >
                                                            Save Changes
                                                        </button>
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary me-2"
                                                    disabled={user.role !== "SuperAdmin" && currentFacility.isLocked === '1'}
                                                >
                                                    Save Changes
                                                </button>
                                            )}

                                            {/* <button type="reset" className="btn btn-outline-secondary">Cancel</button> */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        ) :
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                            <div className="mb-3 mt-3">
                                                <h4 style={{ color: "#697a8d" }}>
                                                    Please Select a
                                                    {user.role === "SuperAdmin"
                                                        ? (selectedPortfolio === null ? " Portfolio!" : " Facility!")
                                                        : " Facility!"
                                                    }
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        </>
    );
}