import * as yup from 'yup';
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import axios from "axios";

const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required')
});

export default function ForgotPassword(){
    const navigate = useNavigate();
    const initialValues = {
        email: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleSubmit = (values) => {
        axios.post('/api/users/forgotpassword', values).then((res) =>{
            if(res.data.success){
                Toast.fire({
                    icon: 'success',
                    title: res.data.message || 'Login Successful!'
                });
                navigate("/login")           
            }
            else{
                Toast.fire({
                    icon: 'error',
                    title: res.data.message || 'Login Failed!'
                });
                // Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            // Swal.fire(error.message, '', 'info')
            Toast.fire({
                icon: 'error',
                title: error.response?.data?.message || error.message
            });        
        });
    }
    return(
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner py-4">
                    <div className="card">
                        <div className="card-body card-padding">
                            <div className="app-brand justify-content-center">
                                <Link to="#" className="app-brand-link gap-2">
                                    <img src={process.env.REACT_APP_BASE_URL+"assets/img/logo.png"} alt="" height="100px"/>
                                </Link>
                            </div>
                            <h2 className="text-center fw-bold">Forgot Password? 🔒</h2>
                            <h4 className="text-center" style={{fontSize: '14px'}}>Don't worry, it happens! Enter your email to reset your password and get back on track.</h4>

                            <form id="formAuthentication" className="mb-3" onSubmit={formik.handleSubmit} method="POST">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" name="email" placeholder="Enter your email" autoFocus value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    {formik.touched.email && formik.errors.email && (
                                    <div className="text-danger">{formik.errors.email}</div>
                                )}
                                </div>
                                <button className="btn btn-lg  btn-primary d-grid w-100">Reset Password </button>
                            </form>

                            <div className="text-center">
                                <Link to="/login" className="d-flex align-items-center justify-content-center">
                                    <i className="bx bx-chevron-left scaleX-n1-rtl bx-sm"></i>
                                    Back to login
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}