import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Layout } from "../Layout/Layout";
import { useEffect, useState } from "react";
import { DataTableComp } from "../Common/Datatable";
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";
import UploadExcel from './UploadExcel';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import NoEncryptionOutlinedIcon from '@mui/icons-material/NoEncryptionOutlined';
import RequestAssistance from "../PortfolioDashboard/RequestAsssistancePopup";
import Header from "../Header/Header";

export default function FacilityListing() {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const [facilities, setFacilities] = useState([]);
    const [unverifiedFacilities, setUnverifiedFacilities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [showExcelForm, setShowExcelForm] = useState(false);
    const handleCloseExcel = () => setShowExcelForm(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamPortfolioId = searchParams.get("portfolioId")
    const [isToggle, setIsToggle] = useState(false);

    const [activeTab, setActiveTab] = useState('all');
    const [currentFacility, setCurrentFacility] = useState({});
    const [show, setShow] = useState(false);
    const [unverifiedFacilitiesCount, setUnverifiedFacilitiesCount] = useState('');
    const [facilitiesCount, setFacilitiesCount] = useState('');

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const seeNonVerified = queryParams.get('seeNonVerified');
    const currency = queryParams.get('currency');

    const [portfolioId, setPortfolioId] = useState(
        queryParamPortfolioId
            ? queryParamPortfolioId
            : (user.portfolio
                ? user.portfolio.id
                : localStorage.getItem('selectedPortfolio')
                    ? parseInt(localStorage.getItem('selectedPortfolio'))
                    : null
            )
    );
    
    const getFacilitiesList = async (portfolioId, isVerified = '', currencyFilter = '') => {
        let condition = `?portfolioId=${portfolioId}&orderBy=id`;
        if (isVerified !== '') {
            condition += `&isVerified=${isVerified}`;
        }
        if(user.role === 'SubClient'){
            condition += `&userId=${user.id}&role=${user.role}`
        }
        if (currencyFilter !== ''){
            condition += `&currency=${currencyFilter}`;
        }
        try {
            setLoading(true);
            const response = await axios.get(`/api/facilities${condition}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                if (isVerified === '')  {
                setFacilities(result.data);
                } else{
                    setUnverifiedFacilities(result.data);
                }
                let recommendValuationVerified = 0;
                for (const facility of result.data) {
                    if (facility.RecommendValuationIsVerified) {
                        recommendValuationVerified += facility.RecommendValuationIsVerified === 'Yes' ? 1 : 0;
                    }
                };
                setUnverifiedFacilitiesCount(recommendValuationVerified);
                if (isVerified === '')  {
                    setFacilitiesCount(result.data.length);
                }
                
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting Facilities List: " + err);
        }
    };

    const handleFacilityListTab = async (isVerifiedFacility, currencyFilter = '') => {
        setActiveTab(isVerifiedFacility === 'Yes' ? 'non-verified' : 'all');
        await getFacilitiesList(portfolioId, isVerifiedFacility, currencyFilter);
    }

    const formatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    useEffect(() => {
        if (queryParamPortfolioId && queryParamPortfolioId !== '') {
            setPortfolioId(queryParamPortfolioId);
        }
        getClientsList();
    }, []);

    useEffect(() => {
        getFacilitiesList(portfolioId);
        if (portfolioId && seeNonVerified && currency) {
            setActiveTab('non-verified')
            getFacilitiesList(portfolioId, 'Yes', currency);
        } 
    }, [seeNonVerified]);

    // useEffect(() => {
    //     if(seeNonVerified) {
    //         setActiveTab('non-verified')
    //         getFacilitiesList(portfolioId, 'Yes');
    //     }
    // }, [seeNonVerified]);

    // const handlePortfolioChange = (newValue) => {
    //     localStorage.removeItem('selectedFacility');
    //     if (newValue) {
    //         setPortfolioId(newValue.portfolio.id);
    //         getFacilitiesList(newValue.portfolio.id);
    //         localStorage.setItem('selectedPortfolio', newValue.portfolio.id);
    //     } else {
    //         setPortfolioId(null);
    //         setFacilities([]);
    //         localStorage.removeItem('selectedPortfolio');
    //     }
    // }

    const getClientsList = async () => {
        try {
            await axios.get(`/api/users?role=Client`)
                .then(function (response) {
                    let result = response.data;
                    if (result.success) {
                        setClients(result.data);
                    }
                })
        } catch (err) {
            console.log(err)
            Swal.fire("Error in getting Clients List: " + err);
        }
    }

    const calculateFacilitiesValuation = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/calculateFacilitiesValuation?portfolioId=${portfolioId}`);
            setLoading(false)
            let result = response.data;
            if (result.success) {
                getFacilitiesList(portfolioId)
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false)
            Swal.fire("Error", err.message, "error");
        }
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#45a7c4',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/api/facility/delete?id=${id}`).then((res) => {
                    if (res.data.success) {
                        Toast.fire({
                            icon: 'success',
                            title: 'Deleted successfully!'
                        });
                        getFacilitiesList(portfolioId);
                        // Swal.fire('Deleted!', '', 'success').then(() => {
                        //     getFacilitiesList(portfolioId);
                        // });
                    }
                }).catch((error) => {
                    console.log(error);
                    Toast.fire({
                        icon: 'error',
                        title: error.message || 'Error occurred!'
                    }); 
                    // Swal.fire(error.message, '', 'info')
                });
            } else if (result.isDenied) {
                Swal.fire('Cancelled!', '', 'info')
            }
        })
    }

    const handleFacilityLockedStatus = async (facilityId, status) => {
        const newStatus = status === '1' ? '0' : '1';
        // console.log("newStatus--------------------", newStatus);
        try {
            const response = await axios.put(`api/facility/updateLockedStatus?id=${facilityId}`, { status: newStatus });
            if (response.data.success) {
                // Swal.fire(response.data.message, '', 'success').then(() => {
                    getFacilitiesList(portfolioId);
                // });
            }
        } catch (error) {
            console.error("Error updating facility status:", error);
            Swal.fire(error.message, '', 'error');
        }
    };

    const handleSelectedFacilityInSession = (facility) => {
        localStorage.setItem('selectedFacility', facility.id);
        localStorage.setItem('selectedFacilityName', facility.name);
        localStorage.setItem('selectedFacilityVerificationStatus', facility.RecommendValuationIsVerified);
    }

    const columns = [
        // {
        //     name: 'S No.',
        //     selector: (row, index) => index + 1,
        //     sortable: true,
        //     // grow: 0,
        //     width: '6%'
        // },
        {
            name: 'Name',
            // selector: row => row.name,
            cell: row => (
                row.name && (
                    <Link className="" to={'/facility-dashboard/' + row.id} 
                    onClick={() => handleSelectedFacilityInSession(row)}
                    >
                        {row.name}
                    </Link>
                )
            ),
            sortable: true,
            width: '13%'
        },
        {
            name: <div className="fs-12">Total<br />Revenue</div>,
            selector: row => row && row.facility_valuation_data[0] && row.facility_valuation_data[0].totalGrossIncome ? formatter.format(row.facility_valuation_data[0].totalGrossIncome) : "N/A",
            sortable: true,
            width: '10%'
        },
        {
            name: <div className="fs-12">Total<br />Expenses</div>,
            sortable: true,
            width: '10%',
            cell: row => (
                <div className={row && row.facility_valuation_data[0] && row.facility_valuation_data[0].totalOperatingExpenses < 0 ? 'text-danger' : ''}>
                    {row.facility_valuation_data[0] && row.facility_valuation_data[0].totalOperatingExpenses ? formatter.format(row.facility_valuation_data[0].totalOperatingExpenses) : "N/A"}
                </div>
            )
        },
        {
            name: 'EBITDA',
            selector: row => row && row.facility_valuation_data[0] && row.facility_valuation_data[0].netOperatingProfit ? formatter.format(row.facility_valuation_data[0].netOperatingProfit) : "N/A",
            sortable: true,
            width: '10%'
        },
        {
            name: <div className="fs-12">Capitalisation<br />Rate</div>,
            selector: row => row && row.facility_valuation_data[0] && row.facility_valuation_data[0].capitalisationRate ? row.facility_valuation_data[0].capitalisationRate + "%" : "N/A",
            sortable: true,
            width: '10%'
        },
        {
            name: <div className="fs-12">Stand-alone<br />Valuation</div>,
            selector: row => row && row.facility_valuation_data[0] && row.facility_valuation_data[0].adoptRoundedEstimatedValue ? formatter.format(row.facility_valuation_data[0].adoptRoundedEstimatedValue) : "N/A",
            sortable: true,
            width: '11%'
        },
        {
            name: <div className="fs-12">As Part of a <br /> Portfolio Value</div>,
            selector: row => row && row.facility_valuation_data[0] && row.facility_valuation_data[0].adoptedPortfolioValue ? formatter.format(row.facility_valuation_data[0].adoptedPortfolioValue) : "N/A",
            sortable: true,
            width: '12%'
        },
        {
            name: <div className="fs-12">Needs<br />Verification</div>,
            selector: row => row && row.RecommendValuationIsVerified,
            sortable: true,
            width: '9%',
            cell: row => (
                <div 
                className={`fs-12 ${row && row.RecommendValuationIsVerified === 'Yes' ? 'text-danger' : ''}`}>
                    {row.RecommendValuationIsVerified}
                </div>
            )
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    {user.role === "SuperAdmin" ? (
                        <>
                            <Tooltip
                                title={row.isLocked === '1' ? "Click here to unlock the facility" : "Click here to lock the facility"}
                                size="small"
                                className={
                                    row.isLocked === '1'
                                        ? "icon icon-sm icon-unlock"
                                        : "icon icon-sm icon-lock"
                                }
                            >
                                <IconButton onClick={(e) => handleFacilityLockedStatus(row.id, row.isLocked)} className="">
                                    {row.isLocked === '1' ? <NoEncryptionOutlinedIcon /> : <LockOpenOutlinedIcon />}
                                </IconButton>
                            </Tooltip>
                            <Link to={`/facility?id=${row.id}`}>
                                <Tooltip title="Edit" size='small' className={user.role === "SuperAdmin" ? 'icon icon-sm icon-secondary ms-2' : 'icon icon-sm icon-secondary'}>
                                    <IconButton>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                            <Tooltip onClick={() => handleDelete(row.id)} title="Delete" size='small' className='icon icon-sm icon-primary ms-2'>
                                <IconButton>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </>
                    )
                        :
                        <Link>
                            <button className="ss-btn" onClick={() => handleRequestAssistance(row.id)}>Seek Assistance</button>
                            {/* <Tooltip title="Edit" size='small' className={user.role === "SuperAdmin" ? 'icon icon-sm icon-secondary ms-2' : 'icon icon-sm icon-secondary'}>
                                <IconButton>
                                    <Edit />
                                </IconButton>
                            </Tooltip> */}
                        </Link>
                    }
                </>

            ),
            width: '9%'
        },
    ];

    // if (user.role === "SuperAdmin") {
    //     columns.push({
    //         name: 'Action',
    //         cell: row => (
    //             <>
    //                 <Tooltip
    //                     title={row.isLocked === '1' ? "Click here to unlock the facility" : "Click here to lock the facility"}
    //                     size="small"
    //                     className={row.isLocked === '1' ? "icon icon-sm icon-unlock" : "icon icon-sm icon-lock"}
    //                 >
    //                     <IconButton onClick={() => handleFacilityLockedStatus(row.id, row.isLocked)}>
    //                         {row.isLocked === '1' ? <NoEncryptionOutlinedIcon /> : <LockOpenOutlinedIcon />}
    //                     </IconButton>
    //                 </Tooltip>
    //                 <Link to={`/facility?id=${row.id}`}>
    //                     <Tooltip title="Edit" size='small' className='icon icon-sm icon-secondary ms-2'>
    //                         <IconButton>
    //                             <Edit />
    //                         </IconButton>
    //                     </Tooltip>
    //                 </Link>
    //                 <Tooltip onClick={() => handleDelete(row.id)} title="Delete" size='small' className='icon icon-sm icon-primary ms-2'>
    //                     <IconButton>
    //                         <Delete />
    //                     </IconButton>
    //                 </Tooltip>
    //             </>
    //         ),
    //         width: '9%',
    //     });
    // }

    const handleSidebarToggle = () => {
        const toggleArrow = document.getElementById('layout_menu_toggle');

        const icon = toggleArrow.querySelector("i");
        // icon.classList.toggle('bx-menu');
        icon.classList.toggle('bx-chevron-left');

        // const asside = document.querySelector('.menu-vertical, .menu-vertical .menu-block, .menu-vertical .menu-inner>.menu-item, .menu-vertical .menu-inner>.menu-header');
        const asside = document.querySelector('.bg-color-sidebar');
        console.log("assideasside===", asside)
        console.log("isToggleisToggle==", isToggle)
        if (isToggle === false) {
            asside.style.display = 'block';  // change sidebar width
            toggleArrow.setAttribute("style", "position: absolute; left:15rem; border-radius:50%;");

            // document.querySelectorAll('.menu-item').forEach((e, i) => {
            //     if(e.getElementsByClassName("label_name")[0]){
            //         e.getElementsByClassName("label_name")[0].classList.remove('d-none');
            //     }
            // })
            setIsToggle(true);
        }
        else {
            asside.style.display = 'none'; // change sidebar width

            toggleArrow.setAttribute("style", "position: absolute; left:15rem; border-radius:50%;");
            // document.querySelectorAll('.menu-item').forEach((e, i) => {
            //     if(e.getElementsByClassName("label_name")[0]){
            //         e.getElementsByClassName("label_name")[0].classList.remove('d-none');
            //     }
            // })
            setIsToggle(false);
        }
    }

    const handleUpload = () => {
        setShowExcelForm(true);
    }

    const getFacilityDetails = async (facilityId) => {
        await axios.get(`api/facility?id=${facilityId}`).then(res => {
            if (res.data.success) {
                const result = res.data.data;
                setCurrentFacility(result);
            }
            else {
                Swal.fire(res.data.message, '', "error").then(() => {
                    // console.log(error)
                });
            }
        })
    }

    const handleRequestAssistance = async (facilityId) => {
        await getFacilityDetails(facilityId);
        setShow(true);
    }

    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header pageTitle="Facilities" />
                <div className="container-xxl layout-navbar navbar-detached z-index-auto ss-navbar mt-0 border-0 pt-2">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-2" id="countries-tab">
                            <ul className="nav nav-tabs border-0 justify-content-start" id="ssTab" role="tablist">
                                <li className="nav-item active">
                                    <button className={`d-flex align-items-center ${activeTab === 'all' && 'active'}`} onClick={(e) => handleFacilityListTab()}>All Facilities
                                        <div className="ss-badge">{facilitiesCount || ''}</div>
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`d-flex align-items-center ${activeTab === 'non-verified' && 'active'}`} onClick={(e) => handleFacilityListTab('Yes')}>Non-verified
                                        <div className="ss-badge">{unverifiedFacilitiesCount || ''}</div>
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-lg-start text-sm-center">
                            <div className="d-flex justify-content-end">
                                {/* {user?.role === 'SuperAdmin' && facilities && facilities.length > 0
                                    ?
                                    <div className="mb-2">
                                        <button className="btn btn-primary me-2" onClick={calculateFacilitiesValuation}>Re Calculate
                                        </button>
                                    </div>
                                    : ""
                                } */}
                                {user?.role === 'SuperAdmin' && portfolioId ?
                                    <>
                                        <div className="mb-2 me-2">
                                            <Link className="" to={`/facility`}>
                                                <button type="button" className="btn btn-primary">
                                                    <span className="tf-icons bx bx-plus"></span>&nbsp; Add Facility
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="mb-2 d-flex">
                                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={handleUpload}>
                                                <span className="tf-icons bx bx-upload"></span>&nbsp; Upload Excel
                                            </button>
                                        </div>
                                    </>
                                    : ""}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container-xxl flex-grow-1 container-p-y pt-0">
                    <div className="ss-header">
                        {portfolioId && portfolioId !== null ? (facilities.length > 0 ?
                            activeTab === 'all' ?
                                (<div>
                                    <DataTableComp columns={columns} data={facilities} className="ss-data-table" />
                                </div>) :
                                (<div>
                                    <DataTableComp columns={columns} data={unverifiedFacilities} className="ss-data-table" />
                                </div>)
                            : <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                            <div className="mb-3 mt-3">
                                                <h4 style={{ color: "#697a8d" }}>No Facility Available!</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                            <div className="mb-3 mt-3">
                                                <h4 style={{ color: "#697a8d" }}>Please Select a Portfolio!</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <UploadExcel show={showExcelForm} setShow={setShowExcelForm} handleClose={handleCloseExcel} getFacilitiesList={getFacilitiesList} portfolioId={portfolioId} loading={loading} setLoading={setLoading} />
                <RequestAssistance
                    show={show}
                    setShow={setShow}
                    portfolioId={currentFacility?.portfolioId}
                    portfolio={currentFacility?.portfolio || ''}
                    facilityName={currentFacility?.name ? currentFacility.name : ""}
                />
            </Layout>
        </>
    );
}