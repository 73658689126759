import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#233446',
      },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#233446',
        color: 'White',
        boxShadow: theme.shadows[1],
        fontSize: '0.8rem',
        cursor: 'pointer',
        fontFamily: 'poppins',
        fontWeight: '400',
        whiteSpace: 'pre-wrap',
    },
}));

export default function ArrowTooltips({title, verificationStatus}) {
  return (
    <LightTooltip title={title && (<span dangerouslySetInnerHTML={{ __html: title }} />)} placement="top" arrow style={{cursor: 'pointer'}}>
      <i className={verificationStatus && verificationStatus === "Yes" ? "bi bi-circle-fill" : "bi bi-question-lg"}></i>
    </LightTooltip>
  );
}