import { Link, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import * as yup from 'yup';
import axios from "axios";
import { useState } from "react";

const validationSchema = yup.object({
    code: yup
      .string('Enter your code')
      .required('code is required')
});
export default function Verify(){
    const navigate = useNavigate();
    const initialValues = {
        code: '',
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    }); 

    const handleSubmit = (values) => {
        axios.post('/api/auth/verify', values).then((res) =>{
            if(res.data.success){
                localStorage.setItem('sv-authUser', JSON.stringify(res.data.data));
                if(res.data.data.role === 'SuperAdmin'){
                    navigate('/portfolios');
                }
                else {
                    localStorage.setItem('selectedPortfolio', res.data.data.portfolio.id);
                    localStorage.setItem('selectedPortfolioName', res.data.data.portfolio.name);
                    navigate(`/portfolio-dashboard/${res.data.data.portfolio.id}`);
                }
                //Ask a prompt to change their password on first login
                res.data.data.isFirstLogin === '1' && 
                Swal.fire({
                    title: "Security Reminder",
                    text: "For your security, please update your password.",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonText: "Change Password",
                    cancelButtonText: "Remind Me Later",
                    customClass: {
                        confirmButton: 'swal-custom-confirm-button'
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/change-password');
                    }
                });
            }
            else{
                Swal.fire(res.data.message, '', 'info')
            }
        }).catch((error) => {
            Swal.fire(error.message, '', 'info')        
        });
    }

    return(
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner py-4">
                    <div className="card">
                        <div className="card-body card-padding">
                            <div className="app-brand justify-content-center">
                                <Link to="#" className="app-brand-link gap-2">
                                    <img src={process.env.REACT_APP_BASE_URL+"assets/img/logo.png"} alt="" height="100px"/>
                                </Link>
                            </div>
                            <h2 className="text-center fw-bold">Verify It's You!</h2>
                            <h4 className="text-center" style={{fontSize: '14px'}}>Please enter the verification code we sent to your email.</h4>

                            <form id="formAuthentication" className="mb-3" onSubmit={formik.handleSubmit} method="POST">
                                <div className="mb-3">
                                    {/* <label htmlFor="code" className="form-label">Verification Code</label> */}
                                    <input type="text" className="form-control" id="code" name="code" placeholder="Enter verification code" autoFocus value={formik.values.code} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    {formik.touched.code && formik.errors.code && (
                                    <div className="text-danger">{formik.errors.code}</div>
                                )}
                                </div>
                                <button className="btn btn-lg  btn-primary d-grid w-100">Verify </button>
                            </form>

                            {/* <div className="text-center">
                                <Link to="/login" className="d-flex align-items-center justify-content-center">
                                    Resend Code
                                </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}