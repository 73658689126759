import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import ArrowTooltips from "./PopHover/ToolTip";
import DoughnutChart from "./charts/doughnut";
import PieChart from "./charts/pie";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import { Layout } from "../Layout/Layout";
import html2pdf from "html2pdf.js";
import HistoricValueChart from "./charts/historicValue";
import HistoricRevenueChart from "./charts/historicRevenue";
import PortfolioPDF from "./Report";

export default function PortfolioDashboard() {
    const { portfolioId } = useParams();
    const authUser = localStorage.getItem("sv-authUser");
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [allFacilities, setAllFacilities] = useState([]);
    const [portfolio, setPortfolio] = useState([]);
    const [currency, setCurrency] = useState("");

    const [totalEstimatedValue, setTotalEstimatedValue] = useState(0);
    const [totalSynergisticValue, setTotalSynergisticValue] = useState(0);
    const [totalNetOperatingProfit, setTotalNetOperatingProfit] = useState(0);
    const [isPortfolioValuationVerified, setIsPortfolioValuationVerified] =
        useState(0);
    const [totalAdoptedStorageUnitRevenue, setTotalAdoptedStorageUnitRevenue] =
        useState(0);
    const [facilitiesFetched, setFacilitiesFetched] = useState(false);

    const [loading, setLoading] = useState(false);
    const [portfolioAdoptedAndActualData, setPortfolioAdoptedAndActualData] =
        useState(null);

    const [doughnutTotalGrossRevenue, setDoughnutTotalGrossRevenue] =
        useState("");
    const [doughnutTotalOperatingExpenses, setDoughnutTotalOperatingExpenses] =
        useState("");
    const [doughnuNetOperatingProfit, setDoughnuNetOperatingProfit] =
        useState("");

    const [valuationPieData, setValuationPieData] = useState(null);
    const [expensesPieData, setExpensesPieData] = useState(null);
    const [currencyList, setCurrencyList] = useState(null);
    const [historicValueFilters, setHistoricValueFilters] = useState("monthly");
    const [historicRevenueFilters, setHistoricRevenueFilters] =
        useState("monthly");
    const [showGrossRevenueDetails, setShowGrossRevenueDetails] = useState(false);
    const [showStorageUnitPerformance, setShowStorageUnitPerformance] =
        useState(false);
    const [previousMonthValuationData, setPreviousMonthValuationData] = useState(
        {}
    );

    const formatCash = Intl.NumberFormat("en-US", {
        notation: "compact",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const getAllFacilities = async (portfolioId, currencyFilter = "") => {
        const condition = `?portfolioId=${portfolioId}&currency=${currencyFilter}`;
        try {
            setLoading(true);
            
            const response = await axios.get(`/api/facilities${condition}`);
            setLoading(false);
            
            const result = response.data;
            if (result.success) {
                let totEstmtdVal = 0;
                let totSynergisticVal = 0;
                let totNetOprtingProfit = 0;
                let recommendValuationVerified = 0;
                let totalAdoptedStorageUnitRevenue = 0;
    
                for (const facility of result.data) {
                    const valuationData = facility.facility_valuation_data[0] || {};
    
                    totEstmtdVal += parseFloat(valuationData.adoptRoundedEstimatedValue) || 0;
                    totSynergisticVal += parseFloat(valuationData.adoptedPortfolioValue) || 0;
                    totNetOprtingProfit += parseFloat(valuationData.netOperatingProfit) || 0;
                    totalAdoptedStorageUnitRevenue += parseFloat(valuationData.totalStorageRevenue) || 0;
    
                    if (facility.RecommendValuationIsVerified === "Yes") {
                        recommendValuationVerified++;
                    }
                }
    
                setAllFacilities(result.data);
                setTotalEstimatedValue(totEstmtdVal);
                setTotalSynergisticValue(totSynergisticVal);
                setTotalNetOperatingProfit(totNetOprtingProfit);
                setIsPortfolioValuationVerified(recommendValuationVerified);
                setTotalAdoptedStorageUnitRevenue(totalAdoptedStorageUnitRevenue);
            } else {
                Swal.fire("Error", "Failed to fetch facilities data.", "error");
            }
        } catch (err) {
            setLoading(false);
            Swal.fire("Error", `Error in getting Facilities List: ${err.message}`, "error");
        }
    };
    
    const getPreviousMonthValuationData = async (portfolioId) => {
        const repsonse = await axios.get(
            `/api/previousMonthValuationData?portfolioId=${portfolioId}`
        );
        setPreviousMonthValuationData(repsonse.data.data);
    };

    useEffect(() => {
        if (portfolioId && !facilitiesFetched) {
            localStorage.setItem("selectedPortfolio", portfolioId);

            getCurrencies(portfolioId);
        }
    }, [portfolioId, facilitiesFetched]);

    const getCurrencies = async (portfolioId) => {
        try {
            // setLoading(true)
            await axios
                .get(`/api/currencies?portfolioId=${portfolioId}`)
                .then(function (response) {
                    setLoading(false);
                    let result = response.data;
                    if (result.success) {
                        setCurrencyList(result.data);
                        if (result.data.length > 0) {
                            setCurrency(result.data[0].currency);
                        }
                    }
                });
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting Currencies List: " + err);
        }
    };

    const getPortfolio = async (portfolioId) => { 
        try {
            const response = await axios.get(`/api/portfolio?id=${portfolioId}`);            
            const result = response.data;
            if (result.success) {
                setPortfolio(result.data);
                localStorage.setItem('selectedPortfolioName',result.data.name)
            } else {
                Swal.fire("Error", "Failed to fetch portfolio data.", "error");
            }
        } catch (err) {
            console.log(err);
            Swal.fire("Error", `Error in getting Portfolios: ${err.message}`, "error");
        }
    };

    useEffect(() => {
        if (portfolioId) {
            getPortfolio(portfolioId);
        } else {
            if (user.portfolio) {
                getPortfolio(user.portfolio.id);
            }
        }
    }, [portfolioId]);

    useEffect(() => {
        if (portfolioId && currency) {
            getAllFacilities(portfolioId, currency);
            getCalculatedAdoptedActualValues(portfolioId, currency);
            getPieChartData(portfolioId, currency);
            getPreviousMonthValuationData(portfolioId);
        } else {
            if (user.portfolio && currency) {
                getCalculatedAdoptedActualValues(portfolioId, currency);
                getPieChartData(user.portfolio.id, currency);
            }
        }
    }, [currency]);

    const handlePortfolioCurrancyChange = (currency) => {
        getAllFacilities(portfolioId, currency);
        getCalculatedAdoptedActualValues(portfolioId, currency);
        setCurrency(currency);
        getPieChartData(portfolioId, currency);
    };

    const calculateFacilitiesValuation = async (currency) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `/api/calculateFacilitiesValuation?portfolioId=${portfolioId}`
            );
            setLoading(false);
            let result = response.data;
            if (result.success) {
                navigate(`/portfolio-dashboard/${portfolioId}`);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false);
            Swal.fire("Error", err.message, "error");
        }
    };

    const getCalculatedAdoptedActualValues = async (
        portfolioId,
        currencyFilter
    ) => {
        let condition = `?portfolioId=${portfolioId}&currency=${currencyFilter}`;
        try {
            // setLoading(true)
            const response = await axios.get(
                `/api/calculateAdoptedActualValues${condition}`
            );
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setPortfolioAdoptedAndActualData(result.data);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false);
            Swal.fire("Error", err.message, "error");
        }
    };

    useEffect(() => {
        if (portfolioAdoptedAndActualData) {
            setDoughnutTotalGrossRevenue(
                portfolioAdoptedAndActualData.TotalGrossRevenueAdopted
                    ? formatCash.format(
                        parseFloat(portfolioAdoptedAndActualData.TotalGrossRevenueAdopted)
                    )
                    : 0
            );
            setDoughnutTotalOperatingExpenses(
                portfolioAdoptedAndActualData.totalOperatingExpensesAdopted
                    ? formatCash.format(
                        parseFloat(
                            portfolioAdoptedAndActualData.totalOperatingExpensesAdopted
                        )
                    )
                    : 0
            );
            setDoughnuNetOperatingProfit(
                portfolioAdoptedAndActualData.NetOperatingProfitAdopted
                    ? formatCash.format(
                        parseFloat(
                            portfolioAdoptedAndActualData.NetOperatingProfitAdopted
                        )
                    )
                    : 0
            );
        }
    }, [portfolioAdoptedAndActualData]);

    const getPieChartData = async (portfolioId, currencyFilter) => {
        let condition = `?portfolioId=${portfolioId}&currency=${currencyFilter}`;
        // setLoading(true);
        try {
            const response = await axios.get(`/api/pieChartData${condition}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setValuationPieData(result.data);
                setExpensesPieData(result.data);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false);
            Swal.fire("Error", err.message, "error");
        }
    };

    const handleGrossRevenueDetails = () => {
        setShowGrossRevenueDetails((prevState) => !prevState);
        setShowStorageUnitPerformance(false);
    };

    const handleStorageUnitPerformance = () => {
        setShowStorageUnitPerformance((prevState) => !prevState);
    };

    const portfolioPDFRef = useRef(null);
    const [isPdfGenerating, setIsPdfGenerating] = useState(false);

    const handleGeneratePdf = async () => {
        setIsPdfGenerating(true);
    };

    useEffect(() => {
        if (isPdfGenerating) {
            setLoading(true);
            setTimeout(() => {
                const element = portfolioPDFRef.current;
                if (!element) {
                    console.error("Ref not set correctly");
                    setIsPdfGenerating(false);
                    setLoading(false);
                    return;
                }

                const opt = {
                    margin: 0,
                    filename: "PortfolioPDF.pdf",
                    image: { type: "jpeg", quality: 0.98 },
                    // html2canvas: { scale: 1 },
                    html2canvas: { scale: 2 }, 
                    jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
                };

                console.log("Generating PDF for element:", element);
                //  to directly download pdf on click
                // html2pdf().from(element).set(opt).save().then(() => {
                //      setIsPdfGenerating(false);
                // }).catch((err) => {
                //       console.error('PDF generation error:', err);
                //       setIsPdfGenerating(false);
                //  });
                html2pdf()
                    .from(element)
                    .set(opt)
                    .outputPdf("blob")
                    .then((pdfBlob) => {
                        const pdfUrl = URL.createObjectURL(pdfBlob);
                        window.open(pdfUrl, "_blank");
                        setIsPdfGenerating(false);
                        setLoading(false);
                    })
                    .catch((err) => {
                        console.error("PDF generation error:", err);
                        setIsPdfGenerating(false);
                        setLoading(false);
                    });
            }, 1000);
        }
    }, [isPdfGenerating]);
    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <div id="portfolio-dashboard">
                    <Header
                        pageTitle={portfolio?.name ? portfolio.name + " Dashboard" : ""}
                        handleGeneratePdf={handleGeneratePdf}
                        verificationStatus={isPortfolioValuationVerified}
                        refreshData={calculateFacilitiesValuation}
                        // imgSrc={imgSrc}
                        imgSrc={portfolio?.logo}
                        currency={currency || ''}
                    />
                    {/* Country Tabs */}
                    {currencyList && currencyList.length > 1 && 
                        <div className="container-xxl countries-header layout-navbar navbar-detached z-index-auto ss-navbar mt-0 ss-pb-0">
                            <div className="row d-flex align-items-center justify-content-center">
                                <div
                                    className="col-lg-12 col-md-12 col-sm-12 col-12"
                                    id="countries-tab"
                                >
                                    {currencyList && currencyList.length > 0 ? (
                                        <ul
                                            className="nav nav-tabs border-0"
                                            id="ssTab"
                                            role="tablist"
                                        >
                                            {currencyList.map((crncy, index) => (
                                                <li className="nav-item active" key={index}>
                                                    <button
                                                        className={currency === crncy.currency && "active "}
                                                        onClick={(e) =>
                                                            handlePortfolioCurrancyChange(crncy.currency)
                                                        }
                                                        style={{ fontSize: "14px" }}
                                                    >
                                                        {(crncy.currency).slice(0,-1)} Portfolio
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                    {/* Country Tabs */}

                    <div
                        className="container-xxl flex-grow-1 container-p-y"
                        style={{ backgroundColor: "#f6f6f6" }}
                    >
                        <div className="row mb-3">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-20">
                                <div className="card h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0 h-50px">
                                                Portfolio Estimated
                                                <br /> Total Value
                                            </h6>
                                            <span className="badge badge-center rounded-pill bg-label-success">
                                                <ArrowTooltips
                                                    title={`The sum of each individual facility EMV (estimated market value) in the portfolio, calculated on an ‘as is’, going concern (self storage use) basis which includes the real estate and business combined. No portfolio premiums or synergies are included (refer Portfolio Estimated Synergistic Value).<br/><br/>“From Last Month” demonstrates the change in the assessment from last month, with green representing an uplift and red representing a reduction.`}
                                                />
                                            </span>
                                        </div>
                                        <div className="card-amount">
                                            ${formatCash.format(totalEstimatedValue) + " " + currency}
                                        </div>
                                        <div className="d-flex align-items-center card-details">
                                            <p className="me-2">From last month:</p>
                                            {/* <p className="fw-700 fs-color-green">+2%</p> */}
                                            {/* <p className={`fw-700 fs-color-${previousMonthValuationData && previousMonthValuationData.totalAdoptRoundedEstimatedValue !== 0 && totalEstimatedValue !== 0 && ((totalEstimatedValue - previousMonthValuationData.totalAdoptRoundedEstimatedValue) * 100) > 0 ? 'green' : 'red'}`}>
                                                {previousMonthValuationData && previousMonthValuationData.totalAdoptRoundedEstimatedValue !== 0 && totalEstimatedValue !== 0 ? (((totalEstimatedValue - previousMonthValuationData.totalAdoptRoundedEstimatedValue ) / totalEstimatedValue) *100).toFixed() : 0}%
                                            </p> */}

                                            <p
                                                className={`fw-700 fs-color-${previousMonthValuationData && parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue) !== 0 &&
                                                        totalEstimatedValue !== 0 && ((totalEstimatedValue -
                                                            parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue)) /
                                                            (parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue) || 1)) * 100 > 0
                                                        ? "green"
                                                        : "red"
                                                    }`}
                                            >
                                                {previousMonthValuationData && parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue) !== 0 && totalEstimatedValue !== 0
                                                    ? ((totalEstimatedValue - parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue)) / (parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue) || 1)) * 100 > 0
                                                        ? `+${(((totalEstimatedValue - parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue)) / 
                                                            parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue)) * 100).toFixed(2)}`
                                                        : `${(((totalEstimatedValue - parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue)) / 
                                                            parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue)) * 100).toFixed(2)}`
                                                    : 0}% 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-20">
                                <div className="card h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0 h-50px">
                                                Weighted Average
                                                <br /> Capitalisation Rate
                                            </h6>
                                            <span className="badge badge-center rounded-pill bg-label-success">
                                                <ArrowTooltips
                                                    title={`Reflects the total return on investment according to the assessed market / achievable revenue relative to the portfolio’s estimated market value (EMV). It is calculated by dividing the total projected Net Operating Income (EBITDA), by the Total Estimated Portfolio Value.<br/><br/>It does not consider any portfolio premium synergistic value.`}
                                                />
                                            </span>
                                        </div>
                                        <div className="card-amount">
                                            {(totalNetOperatingProfit && totalEstimatedValue
                                                ? (
                                                    (totalNetOperatingProfit / totalEstimatedValue) *
                                                    100
                                                ).toFixed(2)
                                                : 0) + "%"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-20">
                                <div className="card h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0 h-50px">
                                                Total no. of Facilities
                                            </h6>
                                            <span className="badge badge-center rounded-pill bg-label-success">
                                                <ArrowTooltips
                                                    title={`The total number of facilities included in the portfolio.<br/><br/>When “not verified” is displayed, one or more of the facility assessments needs to be verified. This could be due to one or more of the facilities trading at below market levels, or an expansion being included. Contact Storval to review the non-verified facilities.`}
                                                />
                                            </span>
                                        </div>
                                        <div className="card-amount">
                                            {allFacilities?.length || 0}
                                        </div>
                                        <div className="d-flex align-items-center card-details">
                                            <p className="fs-color-red">
                                                {isPortfolioValuationVerified || 0} not verified
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-20">
                                <div className="card h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0 h-50px">
                                                Portfolio Estimated
                                                <br /> Synergistic Value
                                            </h6>
                                            <span className="badge badge-center rounded-pill bg-label-success">
                                                <ArrowTooltips
                                                    title={`The combined EMV (estimated market value) of the portfolio, taking into account portfolio premiums and synergies. Applies only to the entire and complete portfolio based on the number of facilities shown.<br/><br/>“From Last Month” demonstrates the change in the assessment from last month, with green representing an uplift and red representing a reduction.`}
                                                />
                                            </span>
                                        </div>
                                        <div className="card-amount">
                                            ${formatCash.format(totalSynergisticValue)}
                                        </div>
                                        <div className="d-flex align-items-center card-details">
                                            <p className="me-2">From last month:</p>
                                            {/* <p className="fw-700 fs-color-red">-2%</p> */}
                                            {/* <p className={`fw-700 fs-color-${previousMonthValuationData && previousMonthValuationData.totalAdoptedPortfolioValue !== 0 && totalSynergisticValue !== 0 && ((totalSynergisticValue - previousMonthValuationData.totalAdoptedPortfolioValue) * 100) > 0 ? 'green' : 'red'}`}>
                                                {previousMonthValuationData && previousMonthValuationData.totalAdoptedPortfolioValue !== 0 && totalSynergisticValue !== 0 ? (((totalSynergisticValue - previousMonthValuationData.totalAdoptedPortfolioValue) / totalSynergisticValue) * 100).toFixed() : 0}%
                                            </p> */}
                                            <p
                                                className={`fw-700 fs-color-${previousMonthValuationData && parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue) !== 0 &&
                                                    totalSynergisticValue !== 0 && ((totalSynergisticValue -
                                                        parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue)) /
                                                        (parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue) || 1))*100 > 0
                                                    ? "green"
                                                    : "red"
                                                }`}
                                            >
                                                {previousMonthValuationData && parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue) !== 0 && totalSynergisticValue !== 0
                                                    ? ((totalSynergisticValue - parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue)) / (parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue) || 1)) * 100 > 0
                                                        ? `+${(((totalSynergisticValue - parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue)) / 
                                                            parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue)) * 100).toFixed(2)}`
                                                        : `${(((totalSynergisticValue - parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue)) / 
                                                            parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue)) * 100).toFixed(2)}`
                                                    : 0}%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-20">
                                <div className="card">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">
                                                Last Month’s Portfolio Results
                                            </h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-12 mb-20">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="ms-auto text-center">
                                                        Total Gross Revenue <br />
                                                        Performance
                                                    </h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success ms-auto">
                                                        <ArrowTooltips
                                                            title={`Total Gross Revenue relates to all revenue capable of being generated from the portfolio including storage fees; ‘other income’ to include merchandise sales, storage income.<br/><br/>The performance gauge demonstrates actual total gross revenue relative to the projected achievable total gross revenue adopted in the assessment.`}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="text-center">
                                                    {/* <img src={process.env.REACT_APP_BASE_URL + "assets/img/graph-1.png"} className="img-fluid" style={{ width: "250px", marginBottom: '20px' }} alt="" /> */}

                                                    <div className="graph">
                                                        {doughnutTotalGrossRevenue ? (
                                                            <DoughnutChart
                                                                chartId="chart1"
                                                                // centerText={totalGrossRevenueCenterText}
                                                                data={[
                                                                    portfolioAdoptedAndActualData.TotalGrossRevenueActual,
                                                                    portfolioAdoptedAndActualData.TotalGrossRevenueAdopted,
                                                                ]}
                                                                chartTypeText1={"Revenue"}
                                                                chartTypeText2={"Total Gross"}
                                                                percentValue={
                                                                    portfolioAdoptedAndActualData &&
                                                                        portfolioAdoptedAndActualData.TotalGrossRevenueActualByAdopted
                                                                        ? parseFloat(
                                                                            portfolioAdoptedAndActualData.TotalGrossRevenueActualByAdopted
                                                                        ).toFixed(2) + "%"
                                                                        : 0
                                                                }
                                                            />
                                                        ) : (
                                                            <p className="mb-0 mt-4 text-center">
                                                                No data available!
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12 mb-20">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="ms-auto text-center">
                                                        Total Operating Expenses <br />
                                                        Performance
                                                    </h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success ms-auto">
                                                        <ArrowTooltips
                                                            title={`The combined total of all projected operating expenses for each facility in the portfolio, not including any head-office expenses, but inclusive of an industry-standard management fee.<br/><br/>The performance gauge demonstrates the operational efficiency of the portfolio. It uses a score system based upon the ratio of total operational expenses to total gross revenue.`}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="text-center">
                                                    {/* <img src={process.env.REACT_APP_BASE_URL + "assets/img/graph-1.png"} className="img-fluid" style={{ width: "250px", marginBottom: '20px' }} alt="" /> */}
                                                    <div className="graph">
                                                        {doughnutTotalOperatingExpenses ? (
                                                            <DoughnutChart
                                                                chartId="chart2"
                                                                // centerText={totalOperatingExpensesCenterText}
                                                                data={[
                                                                    portfolioAdoptedAndActualData.totalOperatingExpensesActual,
                                                                    // portfolioAdoptedAndActualData.totalOperatingExpensesAdopted,
                                                                    portfolioAdoptedAndActualData.OperatingExpensesIncluMgmtFee,
                                                                    portfolioAdoptedAndActualData?.OperatingExpensesPercentageOfGrossRevenue || 0
                                                                ]}
                                                                chartTypeText1={"Expenses"}
                                                                chartTypeText2={"Total Operating"}
                                                                percentValue={
                                                                    portfolioAdoptedAndActualData &&
                                                                        portfolioAdoptedAndActualData.totalOperatingExpensesActualByAdopted
                                                                        ? parseFloat(
                                                                            portfolioAdoptedAndActualData.totalOperatingExpensesActualByAdopted
                                                                        ).toFixed(2) + "%"
                                                                        : 0
                                                                }
                                                            />
                                                        ) : (
                                                            <p className="mb-0 mt-4 text-center">
                                                                No data available!
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center card-details mt-4">
                                                    <p className="me-4">
                                                        Expenses as % of Gross Revenue:
                                                    </p>
                                                    <p className="fw-700 fs-color-green">
                                                        {parseFloat(
                                                            portfolioAdoptedAndActualData?.OperatingExpensesPercentageOfGrossRevenue ||
                                                            0
                                                        ).toFixed(2)}
                                                        %
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12 mb-20">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="ms-auto text-center">
                                                        Total Net Operating Profit <br />
                                                        Performance
                                                    </h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success ms-auto">
                                                        <ArrowTooltips
                                                            title={`Total Net Operating Profit is calculated by deducting Total Operating Expenses from Total Gross Revenue.<br/><br/>Net Operating Profit is expressed as EBITDA (Earnings before Interest, Tax, Depreciation and Amortization).<br/><br/>The performance gauge demonstrates actual total Net Operating Profit relative to the projected total Net Operating Profit adopted in the assessment.`}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="text-center">
                                                    {/* <img src={process.env.REACT_APP_BASE_URL + "assets/img/graph-1.png"} className="img-fluid" style={{ width: "250px", marginBottom: '20px' }} alt="" /> */}
                                                    <div className="graph">
                                                        {doughnuNetOperatingProfit ? (
                                                            <DoughnutChart
                                                                chartId="chart3"
                                                                // centerText={netOperatingProfitCenterText}
                                                                data={[
                                                                    portfolioAdoptedAndActualData.NetOperatingProfitActual,
                                                                    portfolioAdoptedAndActualData.NetOperatingProfitAdopted,
                                                                ]}
                                                                chartTypeText1={"Profit"}
                                                                chartTypeText2={"Net Operating"}
                                                                percentValue={
                                                                    portfolioAdoptedAndActualData &&
                                                                        portfolioAdoptedAndActualData.NetOperatingProfitActualByAdopted
                                                                        ? parseFloat(
                                                                            portfolioAdoptedAndActualData.NetOperatingProfitActualByAdopted
                                                                        ).toFixed(2) + "%"
                                                                        : 0
                                                                }
                                                            />
                                                        ) : (
                                                            <p className="mb-0 mt-4 text-center">
                                                                No data available!
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-around card-details mt-4">
                                                    <p className="">Actual Profit Margin:</p>
                                                    <p className="fw-700 fs-color-green">
                                                        {parseFloat(
                                                            portfolioAdoptedAndActualData?.ProfitMarginActual ||
                                                            0
                                                        ).toFixed(2)}
                                                        %
                                                    </p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-around card-details">
                                                    <p className="">Adopted Profit Margin:</p>
                                                    <p className="">
                                                        {parseFloat(
                                                            portfolioAdoptedAndActualData?.ProfitMarginAdopted ||
                                                            0
                                                        ).toFixed(2)}
                                                        %
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* tree */}
                                        <ul className="tree">
                                            <li className="tree-node row">
                                                <div className="col-md-4">
                                                    <button
                                                        type="button"
                                                        // className={`btn tree-content btn-primary `}
                                                        className={`btn tree-content ${showGrossRevenueDetails
                                                            ? "btn-primary"
                                                            : "btn-primary-outline"
                                                        }`}
                                                        onClick={handleGrossRevenueDetails}
                                                    >
                                                        Gross Revenue Details
                                                    </button>
                                                </div>
                                                {showGrossRevenueDetails && (
                                                    <div className="col-md-12">
                                                        <ul className="tree-branch row">
                                                            <li className="tree-node col-md-4">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <div className="card-title d-flex align-items-baseline">
                                                                        <h6 className="text-center mb-0">
                                                                            Storage Unit Revenue
                                                                        </h6>
                                                                        <span className="badge badge-center rounded-pill bg-label-success ms-3">
                                                                            <ArrowTooltips
                                                                                title={`Storage fees generated from all storage areas including storage units, and if applicable, ‘open storage’/vehicle spaces, wine lockers, gun lockers, safety deposit boxes, etc.<br/><br/>Actual/Adopted demonstrates the actual Storage Unit Revenue relative to what is adopted in the assessment as achievable, expressed as a percentage.`}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <table className="">
                                                                        <tr>
                                                                            <td className="text-start text-color table-fs-16">
                                                                                Adopted
                                                                            </td>
                                                                            <td style={{ width: "20px" }}></td>
                                                                            <td className="table-textgreen table-fs-18">
                                                                                $
                                                                                {formatCash.format(
                                                                                    parseFloat(
                                                                                        portfolioAdoptedAndActualData?.StorageUnitRevenueAdopted ||
                                                                                        0
                                                                                    )
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-start text-color table-fs-16">
                                                                                Actual
                                                                            </td>
                                                                            <td style={{ width: "20px" }}></td>
                                                                            <td className="table-textDark table-fs-18">
                                                                                $
                                                                                {formatCash.format(
                                                                                    parseFloat(
                                                                                        portfolioAdoptedAndActualData?.StorageUnitRevenueActual ||
                                                                                        0
                                                                                    )
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-start text-color table-fs-16">
                                                                                Actual / Adopted
                                                                            </td>
                                                                            <td style={{ width: "20px" }}></td>
                                                                            <td className="table-fs18">
                                                                                {parseFloat(
                                                                                    portfolioAdoptedAndActualData?.StorageUnitRevenueActualByAdopted ||
                                                                                    0
                                                                                ).toFixed(2)}
                                                                                %
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </li>

                                                            <li className="tree-node col-md-4">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <div className="card-title d-flex align-items-baseline">
                                                                        <h6 className="text-center mb-0">
                                                                            Other Income
                                                                        </h6>
                                                                        <span className="badge badge-center rounded-pill bg-label-success ms-3">
                                                                            <ArrowTooltips
                                                                                title={`Revenue generated from merchandise sales, late fees, cleaning fees, admin fees, vehicle hire and ancillary charges.<br/><br/>Actual/Adopted demonstrates the actual Other Income relative to what is adopted in the assessment as achievable, expressed as a percentage.`}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <table className="">
                                                                        <tr>
                                                                            <td className="text-start text-color table-fs-16">
                                                                                Adopted
                                                                            </td>
                                                                            <td style={{ width: "20px" }}></td>
                                                                            <td className="table-textgreen table-fs-18">
                                                                                $
                                                                                {formatCash.format(
                                                                                    parseFloat(
                                                                                        portfolioAdoptedAndActualData?.OtherIncomeAdopted ||
                                                                                        0
                                                                                    )
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-start text-color table-fs-16">
                                                                                Actual
                                                                            </td>
                                                                            <td style={{ width: "20px" }}></td>
                                                                            <td className="table-textDark table-fs-18">
                                                                                $
                                                                                {formatCash.format(
                                                                                    parseFloat(
                                                                                        portfolioAdoptedAndActualData?.OtherIncomeActual ||
                                                                                        0
                                                                                    )
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-start text-color table-fs-16">
                                                                                Actual / Adopted
                                                                            </td>
                                                                            <td style={{ width: "20px" }}></td>
                                                                            <td className="table-fs18">
                                                                                {parseFloat(
                                                                                    portfolioAdoptedAndActualData?.OtherIncomeActualByAdopted ||
                                                                                    0
                                                                                ).toFixed(2)}
                                                                                %
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </li>

                                                            <li className="tree-node col-md-4">
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <div className="card-title d-flex align-items-baseline">
                                                                        <h6 className="text-center mb-0">
                                                                            Non-Storage Income
                                                                        </h6>
                                                                        <span className="badge badge-center rounded-pill bg-label-success ms-3">
                                                                            <ArrowTooltips
                                                                                title={`Additional income generated or available from any non-storage areas and external tenancies.<br/><br/>Actual/Adopted demonstrates the actual (passing) Non-Storage Income relative to what is adopted in the assessment as market, expressed as a percentage.`}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    <table className="">
                                                                        <tr>
                                                                            <td className="text-start text-color table-fs-16">
                                                                                Adopted
                                                                            </td>
                                                                            <td style={{ width: "20px" }}></td>
                                                                            <td className="table-textgreen table-fs-18">
                                                                                $
                                                                                {formatCash.format(
                                                                                    parseFloat(
                                                                                        portfolioAdoptedAndActualData?.NonStorageIncomeAdopted ||
                                                                                        0
                                                                                    )
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-start text-color table-fs-16">
                                                                                Actual
                                                                            </td>
                                                                            <td style={{ width: "20px" }}></td>
                                                                            <td className="table-textDark table-fs-18">
                                                                                $
                                                                                {formatCash.format(
                                                                                    parseFloat(
                                                                                        portfolioAdoptedAndActualData?.NonStorageIncomeActual ||
                                                                                        0
                                                                                    )
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="text-start text-color table-fs-16">
                                                                                Actual / Adopted
                                                                            </td>
                                                                            <td style={{ width: "20px" }}></td>
                                                                            <td className="table-fs18">
                                                                                {parseFloat(
                                                                                    portfolioAdoptedAndActualData?.NonStorageIncomeActualByAdopted ||
                                                                                    0
                                                                                ).toFixed(2)}
                                                                                %
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </li>
                                        </ul>
                                        <ul className="tree mt-4  ss-tree-node">
                                            <li className="tree-node row">
                                                {showGrossRevenueDetails && (
                                                    <div className="col-md-4">
                                                        <button
                                                            type="button"
                                                            className={`btn tree-content ${showStorageUnitPerformance
                                                                    ? "btn-primary"
                                                                    : "btn-primary-outline"
                                                                }`}
                                                            onClick={handleStorageUnitPerformance}
                                                        >
                                                            Storage Unit Performance
                                                        </button>
                                                    </div>
                                                )}
                                                {showStorageUnitPerformance &&
                                                    showGrossRevenueDetails && (
                                                        <div className="col-md-12">
                                                            <ul className="tree-branch row">
                                                                <li className="tree-node col-md-4">
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                        <div className="card-title d-flex align-items-baseline">
                                                                            <h6 className="text-center mb-0">
                                                                                Average Storage Fee Rate
                                                                            </h6>
                                                                            <span className="badge badge-center rounded-pill bg-label-success ms-3">
                                                                                <ArrowTooltips
                                                                                    title={`Storage unit revenue divided by the Net Storage Area (NSA) occupied. Expressed as “effective” – that is, net of all deductions, and blended across all types of storage areas.<br/><br/>Actual/Adopted demonstrates the actual Average Storage Fee Rate relative to what is adopted in the assessment as achievable in the current market, expressed as a percentage.`}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                        <table className="">
                                                                            <tr>
                                                                                <td className="text-start text-color table-fs-16">
                                                                                    Adopted
                                                                                </td>
                                                                                <td style={{ width: "20px" }}></td>
                                                                                <td className="table-textgreen table-fs-18">
                                                                                    $
                                                                                    {formatCash.format(
                                                                                        parseFloat(
                                                                                            portfolioAdoptedAndActualData?.AverageStorageFeeRateAdpoted ||
                                                                                            0
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-start text-color table-fs-16">
                                                                                    Actual
                                                                                </td>
                                                                                <td style={{ width: "20px" }}></td>
                                                                                <td className="table-textDark table-fs-18">
                                                                                    $
                                                                                    {formatCash.format(
                                                                                        parseFloat(
                                                                                            portfolioAdoptedAndActualData?.AverageStorageFeeRateActual ||
                                                                                            0
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-start text-color table-fs-16">
                                                                                    Actual / Adopted
                                                                                </td>
                                                                                <td style={{ width: "20px" }}></td>
                                                                                <td className="table-fs18">
                                                                                    {parseFloat(
                                                                                        portfolioAdoptedAndActualData?.AverageStorageFeeRateActualByAdopted ||
                                                                                        0
                                                                                    ).toFixed(2)}
                                                                                    %
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </li>

                                                                <li className="tree-node col-md-4">
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                        <div className="card-title d-flex align-items-baseline">
                                                                            <h6 className="text-center mb-0">
                                                                                Average Occupancy (by area)
                                                                            </h6>
                                                                            <span className="badge badge-center rounded-pill bg-label-success ms-3">
                                                                                <ArrowTooltips
                                                                                    title={`Net Storage Area Occupied over Total Net Storage Area (all storage areas).<br/><br/>Actual/Adopted demonstrates the actual Average Occupancy (by area) relative to what is adopted in the assessment as achievable, expressed as a percentage.`}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                        <table className="">
                                                                            <tr>
                                                                                <td className="text-start text-color table-fs-16">
                                                                                    Adopted
                                                                                </td>
                                                                                <td style={{ width: "20px" }}></td>
                                                                                <td className="table-textgreen table-fs-18">
                                                                                    {formatCash.format(
                                                                                        parseFloat(
                                                                                            portfolioAdoptedAndActualData?.AverageOccupancyByAreaAdopted ||
                                                                                            0
                                                                                        )
                                                                                    )}%
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-start text-color table-fs-16">
                                                                                    Actual
                                                                                </td>
                                                                                <td style={{ width: "20px" }}></td>
                                                                                <td className="table-textDark table-fs-18">
                                                                                    {formatCash.format(
                                                                                        parseFloat(
                                                                                            portfolioAdoptedAndActualData?.AverageOccupancyByAreaActual ||
                                                                                            0
                                                                                        )
                                                                                    )}%
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-start text-color table-fs-16">
                                                                                    Actual / Adopted
                                                                                </td>
                                                                                <td style={{ width: "20px" }}></td>
                                                                                <td className="table-fs18">
                                                                                    {parseFloat(
                                                                                        portfolioAdoptedAndActualData?.AverageOccupancyByAreaActualByAdopted ||
                                                                                        0
                                                                                    ).toFixed(2)}
                                                                                    %
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </li>

                                                                <li className="tree-node col-md-4">
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                        <div className="card-title d-flex align-items-baseline">
                                                                            <h6 className="text-center mb-0">
                                                                                RevPAM
                                                                            </h6>
                                                                            <span className="badge badge-center rounded-pill bg-label-success ms-3">
                                                                                <ArrowTooltips
                                                                                    title={`Storage unit revenue divided by the Total Net Storage Area (NSA). Expressed as “effective” – that is, net of all deductions, and blended across all types of storage areas.<br/><br/>RevPAM is a measure of performance that seeks to blend average storage fee rate and occupancy performance.<br/><br/>Actual/Adopted demonstrates the actual RevPAM relative to what is adopted in the assessment as achievable, expressed as a percentage.`}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                        <table className="">
                                                                            <tr>
                                                                                <td className="text-start text-color table-fs-16">
                                                                                    Adopted
                                                                                </td>
                                                                                <td style={{ width: "20px" }}></td>
                                                                                <td className="table-textgreen table-fs-18">
                                                                                    ${formatCash.format(
                                                                                        parseFloat(
                                                                                            portfolioAdoptedAndActualData?.AdoptedRevenuePerAvailableMetre ||
                                                                                            0
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-start text-color table-fs-16">
                                                                                    Actual
                                                                                </td>
                                                                                <td style={{ width: "20px" }}></td>
                                                                                <td className="table-textDark table-fs-18">
                                                                                    ${formatCash.format(
                                                                                        parseFloat(
                                                                                            portfolioAdoptedAndActualData?.ActualRevenuePerAvailableMetre ||
                                                                                            0
                                                                                        )
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="text-start text-color table-fs-16">
                                                                                    Actual / Adopted
                                                                                </td>
                                                                                <td style={{ width: "20px" }}></td>
                                                                                <td className="table-fs18">
                                                                                    {parseFloat(
                                                                                        portfolioAdoptedAndActualData?.RevenuePerAvailableMetreActualByAdopted ||
                                                                                        0
                                                                                    ).toFixed(2)}
                                                                                    %
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    )}
                                            </li>
                                        </ul>
                                        {/* tree */}
                                    </div>
                                </div>
                            </div>
                            {/* Bar Charts */}
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-20">
                                <div className="card h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h3 className="d-block fw-12 mb-0">
                                                Portfolio Historic Value Trend
                                            </h3>
                                            <span className="badge badge-center rounded-pill bg-label-success">
                                                <ArrowTooltips
                                                    title={`Demonstrates changes in estimated market value (EMV) on a monthly basis. Revenue is automatically adjusted each month in line with revenue management software results. Note that the frequency of change to adopted operational expenses within the assessments is dependent on how often the user adjusts operating expenses. It is recommended that operating expenses are reviewed by the user every six months. Capitalisation rates can be adjusted any time – however it is recommended that this is discussed with the team at Storval before being adjusted.`}
                                                />
                                            </span>
                                        </div>
                                        <div className="text-center">
                                            <div className="graph">
                                                {currency && portfolio ? (
                                                    <HistoricValueChart
                                                        portfolioId={portfolioId}
                                                        currencyFilter={currency}
                                                        filterType={historicValueFilters}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="">
                                            <ul
                                                className="nav ss-nav nav-tabs border-bottom"
                                                id="myTab"
                                                role="tablist"
                                            >
                                                <li
                                                    className={`nav-item ${historicValueFilters === "monthly" && "active"
                                                        }`}
                                                    onClick={() => setHistoricValueFilters("monthly")}
                                                >
                                                    <button>Monthly</button>
                                                </li>
                                                <li
                                                    className={`nav-item ${historicValueFilters === "6-monthly" && "active"
                                                        }`}
                                                    onClick={() => setHistoricValueFilters("6-monthly")}
                                                >
                                                    <button>6 Monthly</button>
                                                </li>
                                                <li
                                                    className={`nav-item ${historicValueFilters === "yearly" && "active"
                                                        }`}
                                                    onClick={() => setHistoricValueFilters("yearly")}
                                                >
                                                    <button>Annually</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-20">
                                <div className="card h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h3 className="d-block fw-12 mb-0">
                                                Portfolio Historic Revenue Trend
                                            </h3>
                                            <span className="badge badge-center rounded-pill bg-label-success">
                                                <ArrowTooltips
                                                    title={`Demonstrates changes in actual revenue on a monthly basis. Revenue is automatically adjusted each month in line with revenue management software results. This will, in turn, update the Net Operating Profit result. Note that the frequency of change to adopted operational expenses for the Net Operating Profit calculation is dependent on how often the user adjusts operating expenses. It is recommended that operating expenses are reviewed by the user every six months.`}
                                                />
                                            </span>
                                        </div>
                                        <div className="text-center">
                                            <div className="graph">
                                                {currency && portfolio ? (
                                                    <HistoricRevenueChart
                                                        portfolioId={portfolioId}
                                                        currencyFilter={currency}
                                                        filterType={historicRevenueFilters}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                        <div className="">
                                            <ul
                                                className="nav ss-nav nav-tabs border-bottom"
                                                id="myTab"
                                                role="tablist"
                                            >
                                                <li
                                                    className={`nav-item ${historicRevenueFilters === "monthly" && "active"
                                                        }`}
                                                    onClick={() => setHistoricRevenueFilters("monthly")}
                                                >
                                                    <button>Monthly</button>
                                                </li>
                                                <li
                                                    className={`nav-item ${historicRevenueFilters === "6-monthly" && "active"
                                                        }`}
                                                    onClick={() => setHistoricRevenueFilters("6-monthly")}
                                                >
                                                    <button>6 Monthly</button>
                                                </li>
                                                <li
                                                    className={`nav-item ${historicRevenueFilters === "yearly" && "active"
                                                        }`}
                                                    onClick={() => setHistoricRevenueFilters("yearly")}
                                                >
                                                    <button>Annually</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Bar Charts */}

                            {/* Pie Charts */}
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-20">
                                <div className="card h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h3 className="d-block fw-12 mb-0">
                                                Portfolio Makeup by Facility Value
                                            </h3>
                                            <span className="badge badge-center rounded-pill bg-label-success">
                                                <ArrowTooltips
                                                    title={`Shows the top 10 facilities with the highest estimated market value (EMV) within the portfolio`}
                                                />
                                            </span>
                                        </div>
                                        <div className="text-center">
                                            <div className="graph">
                                                {valuationPieData &&
                                                    valuationPieData.adoptedValues.length > 0 ? (
                                                    <PieChart pieData={valuationPieData} pieId="pie1" />
                                                ) : (
                                                    <p className="mb-0 mt-4 text-center">
                                                        No data available!
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-20">
                                <div className="card h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h3 className="d-block fw-12 mb-0">
                                                Net Operating Profit Makeup by Facility Value
                                            </h3>
                                            <span className="badge badge-center rounded-pill bg-label-success">
                                                <ArrowTooltips
                                                    title={`Shows the top 10 facilities with the highest Net Operating Profit within the portfolio.`}
                                                />
                                            </span>
                                        </div>
                                        <div className="text-center">
                                            <div className="graph">
                                                {valuationPieData &&
                                                    valuationPieData.totalOperatingExpensesValues.length >
                                                    0 ? (
                                                    <PieChart pieData={expensesPieData} pieId="pie2" />
                                                ) : (
                                                    <p className="mb-0 mt-4 text-center">
                                                        No data available!
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Pie Charts */}

                            {/* Disclaimer */}
                            <div className="col-md-12 mb-20">
                                <div className="card">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">
                                                Disclaimer
                                            </h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-12 card-body">
                                                <p className="fs-13">
                                                    This indicative assessment of value is a user-extracted estimate only and provides the “estimated market value”
                                                    (EMV) of the portfolio, with each asset calculated on a going concern (self storage use) basis, which includes the real
                                                    estate and business combined. The EMV is based upon financial data provided by a third party and user inputs. <span className="border-bottom-disclaimer">It does not constitute a formal
                                                        valuation.</span> Specific research into the property/ies and the trading catchment/s has not
                                                    been undertaken. The EMV does not consider any property specific attributes or external factors which may impact
                                                    value. Details have not been confirmed and an inspection of the facility/ies has not occurred.
                                                </p>
                                                <p className="fs-13">
                                                    Storval is a user-operated estimation tool that can only be used by the licensee (“user”) for informative purposes.
                                                    The EMV should not be regarded as advice. It should not and cannot be relied upon for decision-making purposes.
                                                    Specifically, the EMV must not be used, and is not suitable for mortgage security purposes, transaction purposes or
                                                    investment decisions. Professional valuation advice is recommended before making any investment decisions. A full
                                                    valuation that adheres to the International Valuation Standards is recommended to confirm the EMV displayed.
                                                </p>
                                                <p className="fs-13">
                                                    Storval Pty Ltd (Storval) does not make any representation or give any warranty as to the accuracy, completeness or
                                                    reliability of the EMV, or the information provided or produced; nor does Storval accept any liability arising in any
                                                    way from any reliance, omissions or errors.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Disclaimer */}
                        </div>
                    </div>

                    <div className="hidden-offscreen">
                        <PortfolioPDF
                            ref={portfolioPDFRef}
                            portfolioId={portfolioId}
                            portfolioName={portfolio.name}
                            currency={currency}
                            allFacilities={allFacilities}
                            totalEstimatedValue={totalEstimatedValue}
                            previousMonthEstimatedValuePercentage={
                                previousMonthValuationData && parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue) !== 0 &&
                                    totalEstimatedValue !== 0 ? (((totalEstimatedValue -
                                        parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue)) /
                                        (parseFloat(previousMonthValuationData.totalAdoptRoundedEstimatedValue) || 1)) * 100).toFixed(2)
                                    : 0
                            }
                            totalNetOperatingProfit={totalNetOperatingProfit}
                            isPortfolioValuationVerified={isPortfolioValuationVerified}
                            totalSynergisticValue={totalSynergisticValue}
                            previousMonthSynergisticValuePercentage={
                                previousMonthValuationData && parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue) !== 0 && totalSynergisticValue !== 0 ? (((totalSynergisticValue -
                                    parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue)) /
                                    (parseFloat(previousMonthValuationData.totalAdoptedPortfolioValue) || 1)) *100).toFixed(2)
                                : 0
                            }
                            portfolioAdoptedAndActualData={portfolioAdoptedAndActualData}
                            doughnutTotalGrossRevenue={doughnutTotalGrossRevenue}
                            doughnutTotalOperatingExpenses={doughnutTotalOperatingExpenses}
                            doughnuNetOperatingProfit={doughnuNetOperatingProfit}
                            valuationPieData={valuationPieData}
                            expensesPieData={expensesPieData}
                            historicValueFilters={historicValueFilters}
                            historicRevenueFilters={historicRevenueFilters}
                            imgSrc={portfolio?.logo}
                        />
                    </div>
                </div>
            </Layout>
        </>
    );
}