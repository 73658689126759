
import { Layout } from "../Layout/Layout";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Loader from "../Loader/Loader";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";

const highlightBackground = [
    "Total Net Storage Area (m²)",
    "Mature Stabilised Maintainable Occupancy",
    "Mature Average Storage Fee Rate (Effective)",
    "Total Gross Storage Revenue",
    "Total Gross Revenue",
    "Total Expenses",
    "Net Operating Profit (EBITDA)",
    "Core Market Value",
    "Indicative Market Value",
];

const highlightText = [
    "Operating Expenses",
    "Management Fee on Storage Revenue",
    "Management Fee on Additional Tenancies",
    "Total Expenses",
    "Below the Line Adjustment (Storage Revenue)",
    "Non-Storage Income Rental Shortfall / Vacancy Allowance",
    "Cost of Expansion",
    "Essential Capital Expenditure",
];

const showValuesInPercent = [
    "Mature Stabilised Maintainable Occupancy",
    "Other Income % of Gross Revenue",
    "Non-Storage Income % of Gross Revenue",
    "Operating Expenses % of Gross Revenue",
    "Total Expenses % of Gross Revenue",
    "Capitalisation Rate",
    "% Passing Revenue of Mature Revenue",
    "Profit Margin",
    ""
];

const noFormatHeadings = [
    "Total Net Storage Area (m²)",
];

const emptyRowAfter = [
    'Total Gross Storage Revenue',
    'Non-Storage Income % of Gross Revenue',
    'Total Gross Revenue',
    'Total Expenses % of Gross Revenue',
    'Net Operating Profit (EBITDA)',
    'Capitalisation Rate',
    'Core Market Value',
    'Below the Line Adjustment (Storage Revenue)',
    'Additional Below the Line adjustment',
];

export default function BuildupValuation() {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [buildupValuation, setBuildupValuation] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);

    useEffect(() => {
        if(user.role !== 'SuperAdmin'){
            navigate(-1)
        }
        const portfolioId = localStorage.getItem('selectedPortfolio');
        const facilityId = localStorage.getItem('selectedFacility');

        if (portfolioId) {
            setSelectedPortfolio(JSON.parse(portfolioId));
        }

        if (facilityId) {
            setSelectedFacility(JSON.parse(facilityId));
            getBuildupValuation(facilityId);
        }
    }, []);

    const initialValues = {
        portfolioId: '',
        id: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
    });

    const getBuildupValuation = (facilityId) => {
        setLoading(true)
        if (!facilityId) {
            console.error("Invalid facilityId", facilityId);
            setLoading(false)
            return;
        }
        axios.get(`api/buildupValuation?facilityId=${facilityId}`).then(res => {
            setLoading(false)
            if (res.data.success) {
                const result = res.data.data;
                setBuildupValuation(result)
            }
        })
    }

    const formatterWithoutDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const formatterWithDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatValue = (value) => {
        console.log("value", value);
        const numericValue = parseFloat(value);
        const hasSixDigitsBeforeDecimal = Math.floor(Math.abs(numericValue)).toString().length >= 3;

        return hasSixDigitsBeforeDecimal
            ? formatterWithoutDecimals.format(value)
            : formatterWithDecimals.format(value);
    };

    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header
                    pageTitle={localStorage.getItem('selectedFacilityName')}
                    verificationStatus={localStorage.getItem('selectedFacilityVerificationStatus')}
                />
                <div className="container-xxl flex-grow-1 container-p-y">
                    {selectedFacility !== null ?
                        <React.Fragment>
                            {/* <NavHeader /> */}
                            <div className="bg-white p-3 mb-3">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 view">
                                        <div className="facilityTable table-responsive wrapper">
                                            <table className="table table-sm" id="Rj-scroll-fix">
                                                <thead>
                                                    <tr>
                                                        <th className="table-blank-space Rj-w-30 sticky-col first-col" scope="col"></th>
                                                        <th scope="col">Indicative Market Valuation <br /> (Higher Occupancy)</th>
                                                        <th scope="col">Indicative Market Valuation <br /> (Projected Med)</th>
                                                        <th scope="col">Indicative Market Valuation <br /> (Higher Fee Rate)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {buildupValuation.map((values, keyIndex) => (
                                                        <React.Fragment key={keyIndex}>
                                                            <tr key={keyIndex}>
                                                                <td className={values.heading === '' ? "sticky-col first-col bg-white" : "theme-color-bg sticky-col first-col"}>{values.heading}</td>
                                                                <td style={{ fontWeight: values.heading === 'Total Expenses' ? 800 : 'normal', color: values.heading === 'Total Expenses' ? 'red!important' : 'inherit' }}
                                                                    className={
                                                                        `${highlightBackground.includes(values.heading) ? "bg-table" : ""}
                                                        ${highlightText.includes(values.heading) ? "color-red-table" : ""}
                                                        ${values.heading === 'Total Expenses' ? "color-red-table fw-600" : ""}
                                                        bg-white`
                                                                    }>
                                                                    {
                                                                        showValuesInPercent.includes(values.heading)
                                                                            ? parseFloat(values.HigherOccupancy).toFixed(2) + "%"
                                                                            : noFormatHeadings.includes(values.heading)
                                                                                ? parseFloat(values.HigherOccupancy).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                                : values.heading === 'No of Months to Reach Maturity'
                                                                                    ? values.HigherOccupancy
                                                                                    : (values.heading === 'Mature Stabilised Maintainable Occupancy' || values.heading === 'Capitalisation Rate' || values.heading === 'Other Income % of Gross Revenue')
                                                                                        ? parseFloat(values.HigherOccupancy).toFixed(2)
                                                                                        : (values.heading === "Mature Average Storage Fee Rate (Effective)"
                                                                                            ? formatterWithDecimals.format(values.HigherOccupancy)
                                                                                            : formatterWithoutDecimals.format(values.HigherOccupancy))
                                                                    }
                                                                </td>
                                                                <td style={{ fontWeight: values.heading === 'Total Expenses' ? 800 : 'normal', color: values.heading === 'Total Expenses' ? 'red!important' : 'inherit' }}
                                                                    className={
                                                                        `${highlightBackground.includes(values.heading) ? "bg-table" : ""}
                                                                        ${highlightText.includes(values.heading) ? "color-red-table" : ""}
                                                                        ${values.heading === 'Total Expenses' ? "color-red-table fw-600" : ""}
                                                                        bg-white`
                                                                    }>
                                                                    {
                                                                        showValuesInPercent.includes(values.heading)
                                                                        ? (values.heading === ""
                                                                            ? values.ProjectedMed
                                                                            : !isNaN(parseFloat(values.ProjectedMed))
                                                                              ? parseFloat(values.ProjectedMed).toFixed(2) + "%"
                                                                              : "0.00%")
                                                                            : noFormatHeadings.includes(values.heading)
                                                                                ? parseFloat(values.ProjectedMed).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                                : values.heading === 'No of Months to Reach Maturity'
                                                                                    ? values.ProjectedMed
                                                                                    : (values.heading === 'Mature Stabilised Maintainable Occupancy' || values.heading === 'Capitalisation Rate' || values.heading === 'Other Income % of Gross Revenue')
                                                                                        ? parseFloat(values.ProjectedMed).toFixed(2)
                                                                                        : (values.heading === "Mature Average Storage Fee Rate (Effective)"
                                                                                            ? formatterWithDecimals.format(values.ProjectedMed)
                                                                                            : formatterWithoutDecimals.format(values.ProjectedMed))
                                                                    }
                                                                </td>
                                                                <td style={{ fontWeight: values.heading === 'Total Expenses' ? 800 : 'normal', color: values.heading === 'Total Expenses' ? 'red!important' : 'inherit' }}
                                                                    className={
                                                                        `${highlightBackground.includes(values.heading) ? "bg-table" : ""}
                                                                        ${highlightText.includes(values.heading) ? "color-red-table" : ""}
                                                                        ${values.heading === 'Total Expenses' ? "color-red-table fw-600" : ""}
                                                                        bg-white`
                                                                    }>
                                                                    {
                                                                             showValuesInPercent.includes(values.heading)
                                                                             ? !isNaN(parseFloat(values.HigherFeeRate)) // Check for valid number
                                                                               ? parseFloat(values.HigherFeeRate).toFixed(2) + "%"
                                                                               : "0.00%"
                                                                            : noFormatHeadings.includes(values.heading)
                                                                                ? parseFloat(values.HigherFeeRate).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                                : values.heading === 'No of Months to Reach Maturity'
                                                                                    ? values.HigherFeeRate
                                                                                    : (values.heading === 'Mature Stabilised Maintainable Occupancy' || values.heading === 'Capitalisation Rate' || values.heading === 'Other Income % of Gross Revenue')
                                                                                        ? parseFloat(values.HigherFeeRate).toFixed(2)
                                                                                        : (values.heading === "Mature Average Storage Fee Rate (Effective)"
                                                                                            ? formatterWithDecimals.format(values.HigherFeeRate)
                                                                                            : formatterWithoutDecimals.format(values.HigherFeeRate))
                                                                    }
                                                                </td>
                                                            </tr>
                                                            {
                                                                emptyRowAfter.includes(values.heading)
                                                                    ? <tr key={'EmptyRow'} height="20px;">
                                                                        <td className="bg-blank-space"></td>
                                                                        <td className="bg-blank-space"></td>
                                                                        <td className="bg-blank-space"></td>
                                                                        <td className="bg-blank-space"></td>
                                                                    </tr>
                                                                    : ''
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                        <div className="mb-3 mt-3">
                                            <h4 style={{ color: "#697a8d" }}>Please Select a {selectedPortfolio === null ? "Portfolio" : "Facility"}!</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Layout>
        </>
    );
}