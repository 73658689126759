import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import ArrowTooltips from "../PortfolioDashboard/PopHover/ToolTip";

export default function Header({ pageTitle, handleGeneratePdf, verificationStatus, refreshData, imgSrc, currency,...rest }) {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const location = useLocation();
    const [isToggle, setIsToggle] = useState(false)
    const facilityId = localStorage.getItem('selectedFacility') ? parseInt(localStorage.getItem('selectedFacility')) : null;
    const isFacilityDashboard = /^\/facility-dashboard\/[^/]+$/.test(location.pathname);
    const isPortfolioDashboard = /^\/portfolio-dashboard\/[^/]+$/.test(location.pathname);

    const facilityRelatedPages = [
        "/client_input",
        "/admin_input",
        "/operating_expenses",
        "/non_storage_income",
        "/api_data",
        "/static_valuation",
        "/buildUp_valuation",
        "/sensitivity_output",
        "/cash_flow",
        "/cashflow_output"
    ];

    const isFacilityRelatedPage = isFacilityDashboard || facilityRelatedPages.includes(location.pathname);

    const handleSidebarToggle = () => {
        const toggleArrow = document.getElementById('layout_menu_toggle');

        const icon = toggleArrow.querySelector("i");
        icon.classList.toggle('bx-chevron-left');
        icon.classList.toggle('bx-chevron-right');

        const asside = document.querySelector('.menu-vertical, .menu-vertical .menu-block, .menu-vertical .menu-inner>.menu-item, .menu-vertical .menu-inner>.menu-header');

        // const contentBar = document.querySelector('.layout-menu-fixed:not(.layout-menu-collapsed) .layout-page, .layout-menu-fixed-offcanvas:not(.layout-menu-collapsed) .layout-page')

        // const logo = document.querySelector(".logo_img");

        if (isToggle === false) {
            // change logo
            // logo.src  = 'assets/img/logo_s.png';
            // logo.setAttribute("style", "width: 51px; height:51px;");
            // change logo end

            asside.style.width = '65px';  // change sidebar width
            // contentBar.style.paddingLeft = '65px'; // change content bar width

            toggleArrow.setAttribute("style", "position: absolute; left:4rem; border-radius:50%;");


            // if(x.classList.contains('active')){
            //     x.classList.style.width = '212px';
            // }
            // remove tab labels
            document.querySelectorAll('.menu-item').forEach((e, i) => {
                if (e.getElementsByClassName("label_name")[0]) {
                    e.getElementsByClassName("label_name")[0].classList.add('d-none');
                }
            })
            // remove tab labels end

            setIsToggle(true);
        }
        else {
            // change logo            
            // logo.src  = 'assets/img/storval-logo.png';
            // logo.setAttribute("style", "width: 125px; height:67px;");
            // change logo end

            asside.style.width = '227.483px'; // change sidebar width
            // contentBar.style.paddingLeft = '227.483px'; // change content bar width

            toggleArrow.setAttribute("style", "position: absolute; left:15rem; border-radius:50%;");

            // if(x.classList.contains('active')){
            //     x.classList.style.width = '65px';
            // }
            // remove tab labels
            document.querySelectorAll('.menu-item').forEach((e, i) => {
                if (e.getElementsByClassName("label_name")[0]) {
                    e.getElementsByClassName("label_name")[0].classList.remove('d-none');
                }
            })
            // remove tab labels end

            setIsToggle(false);
        }
    }

    return (
        <>
            {user && (
                ((user.role === 'SuperAdmin' && location.pathname !== '/contact-us') ||
                    ((user.isSuperAdmin > 0 && user.role === 'Client') ||
                        (user.isAdmin > 0 && user.role === 'SubClient'))) && (
                            <div className="ss-sticky1">
                                <div style={{ backgroundColor: '#6bb947c7' }}>
                                    <p>Admin View</p>
                                </div>
                            </div>
                        ))
            }
            <nav className={`layout-navbar ss-navbar container-xxl 
                ${
                    user && (
                        (user.role === 'SuperAdmin' && location.pathname !== '/contact-us') || ( 
                            (user.isSuperAdmin > 0 && user.role === 'Client') ||
                                (user.isAdmin > 0 && user.role === 'SubClient')
                    ))
                    ? 'top-35'
                    : ''
                }
                height120 navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme Rj-scroll-sticky AdminBanner`} id="layout-navbar">

                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-none">
                    <Link className="nav-item nav-link px-0 me-xl-4" onClick={handleSidebarToggle}>
                        <i className="bx bx-menu bx-sm"></i>
                    </Link>
                </div>

                <div className="navbar-nav-right" id="navbar-collapse">
                    <div className="row align-items-md-center ss-title">
                        <div className={location.pathname === "/facility" ? "col-md-12 col-12" : "col-md-8 col-12"}>
                            <div className="d-flex align-items-center mt-md-3 mt-0">
                                {/* <Link className="layout-menu-toggle menu-link text-large d-block cursor-pointer d-xl-none" id="layout_menu_toggle">
                                    <i className="bx bx-menu bx-sm align-middle toggle-icon"></i>
                                </Link> */}
                                {isPortfolioDashboard && (
                                    <img src={imgSrc} alt="logo" height="40px" style={{ marginRight: '10px' }} />
                                )}
                                <h4 className="fw-semi-bold m-0">{pageTitle}</h4>
                            </div>
                            <div className="d-flex align-items-center px-0 d-md-block title-span">
                                {(isFacilityDashboard || isFacilityRelatedPage) ? (
                                    <span className={`${verificationStatus === "Yes" ?
                                        "text-red" :
                                        (verificationStatus === "No" ? "theme-color" : "grey-color")} me-2`}>
                                        {/* <i className="bi bi-circle-fill"></i> */}
                                        <ArrowTooltips
                                            title={verificationStatus === "Yes" && `Red indicates that the assessment needs to be verified. This could be due to the facility’s revenue performing at below market levels, or an expansion being included. Contact Storval to review the assessment.`}
                                            verificationStatus="Yes"
                                        />
                                    </span>
                                ) : (
                                    isPortfolioDashboard && (
                                        <span className={`${verificationStatus > 0 ? "text-red" : "theme-color"} me-2`}>
                                            {/* <i className="bi bi-circle-fill"></i> */}
                                            <ArrowTooltips
                                                title={verificationStatus > 0 && `Red indicates that one or more of the assessments within the portfolio needs to be verified. This could be due to one or more of the facilities trading at below market levels, or an expansion being included. Contact Storval to review the non-verified facilities.`}
                                                verificationStatus="Yes"
                                            />
                                        </span>
                                    )
                                )
                                }
                                <span>{(isPortfolioDashboard || (isFacilityDashboard || isFacilityRelatedPage)) && "Verification Status: "}
                                    {(isFacilityDashboard || isFacilityRelatedPage) ? (
                                        <span className={verificationStatus === "Yes" ? "text-red spanBorderBottom" :
                                            (verificationStatus === "No" ? "theme-color spanBorderBottomSuccess" : "grey-color spanBorderBottomGrey")}
                                        >{"Estimated "}
                                            <span className={verificationStatus === "Yes" ?
                                                "spanBorderBottom" :
                                                (verificationStatus === "No"
                                                    ? "spanBorderBottomSuccess" : "spanBorderBottomGrey"
                                                )}
                                            >
                                                {verificationStatus === "Yes"
                                                    ? "Market Value is not verified."
                                                    : (verificationStatus === "No"
                                                        ? "Market Value is verified."
                                                        : "Market Value is not calculated"
                                                    )
                                                }
                                            </span>
                                            {verificationStatus === "Yes" && " Contact Storval"}
                                        </span>
                                    ) : (
                                        isPortfolioDashboard && (
                                            <Link to={verificationStatus > 0 && `/facility_listing?seeNonVerified=1&currency=${currency}`}>
                                                <span className={verificationStatus > 0 ? "text-red spanBorderBottom" : "theme-color spanBorderBottomSuccess"}>
                                                    {verificationStatus > 0 ? ` There ${verificationStatus !== 1 ? 'are ' : 'is only '}` : " All"}
                                                    <span className={verificationStatus > 0 ? "spanBorderBottom" : "spanBorderBottomSuccess"}>
                                                        {verificationStatus > 0 ?
                                                            `${verificationStatus} facilit${verificationStatus !== 1 ? 'ies' : 'y'} that ${verificationStatus !== 1 ? 'are' : 'is'} not verified.`
                                                            : " facilities are verified."}
                                                    </span>
                                                    {verificationStatus > 0 && " View non-verified facilities"}
                                                </span>
                                            </Link>
                                        )
                                    )
                                    }
                                </span>
                            </div>

                        </div>
                        <div className="col-md-4 col-12">
                            <div className="d-flex align-items-center justify-content-md-end">
                                {/* {isPortfolioDashboard && (
                                    <div className="">
                                        <button type="button" className="btn btn-primary ms-2" onClick={refreshData}>Refresh Data</button>
                                    </div>
                                )} */}
                                {isFacilityDashboard && (
                                    <div className="">
                                        <button type="button" className="btn btn-primary ms-2" onClick={refreshData}>Record Value</button>
                                    </div>
                                )}
                                {(isPortfolioDashboard || (isFacilityDashboard)) &&
                                    <div className="">
                                        <button type="button" className="btn btn-primary ms-2"
                                            onClick={handleGeneratePdf}
                                        >Generate PDF</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {isFacilityRelatedPage && (
                <div className="container-xxl layout-navbar navbar-detached z-index-auto ss-navbar mt-0 border-0">
                    <div className="row d-flex align-items-center justify-content-start">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-2" id="countries-tab">
                            <div className="navContainer">
                                <ul className="nav ss-nav nav-tabs border-bottom border-primary ss-scroll text-nowrap flex-nowrap overflow-x-auto" id="myTab" role="tablist">
                                    <Link className={`nav-item ${isFacilityDashboard && "active"}`}
                                        to={`/facility-dashboard/${facilityId}`}
                                    >
                                        <button>Dashboard</button>
                                    </Link>
                                    <Link className={`nav-item ${location.pathname === "/client_input" && "active"}`}
                                        to="/client_input"
                                    >
                                        <button>Client Inputs</button>
                                    </Link>
                                    {user.role === 'SuperAdmin' && (
                                        <Link className={`nav-item ${location.pathname === "/admin_input" && "active"}`}
                                            to="/admin_input"
                                        >
                                            <button>Admin Inputs</button>
                                        </Link>
                                    )}
                                    <Link className={`nav-item ${location.pathname === "/operating_expenses" && "active"}`}
                                        to="/operating_expenses">
                                        <button>Operating Expenses</button>
                                    </Link>
                                    <Link className={`nav-item ${location.pathname === "/non_storage_income" && "active"}`}
                                        to="/non_storage_income">
                                        <button>Non-Storage Income</button>
                                    </Link>
                                    <Link className={`nav-item ${location.pathname === "/api_data" && "active"}`}
                                        to="/api_data">
                                        <button>Trading Data</button>
                                    </Link>
                                    {user.role === 'SuperAdmin' && (
                                        <>
                                            <Link className={`nav-item ${location.pathname === "/static_valuation" && "active"}`}
                                                to="/static_valuation">
                                                <button>Static Valuation</button>
                                            </Link>
                                            <Link className={`nav-item ${location.pathname === "/buildUp_valuation" && "active"}`}
                                                to="/buildUp_valuation">
                                                <button>Build Up Valuation</button>
                                            </Link>
                                        </>
                                    )}
                                    <Link className={`nav-item ${location.pathname === "/sensitivity_output" && "active"}`}
                                        to="/sensitivity_output">
                                        <button>Sensitivity Output</button>
                                    </Link>
                                    {user.role === 'SuperAdmin' && (
                                        <Link className={`nav-item ${location.pathname === "/cash_flow" && "active"}`}
                                            to="/cash_flow">
                                            <button>Cash Flow</button>
                                        </Link>
                                    )}
                                    <Link className={`nav-item ${location.pathname === "/cashflow_output" && "active"}`}
                                        to="/cashflow_output">
                                        <button>Cashflow Output</button>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}