import React from 'react';
import PropTypes from "prop-types";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const Add = ({ show, setShow, handleClose, getFacilityDetails, facilityId, currentFacility, loading, setLoading,  ...rest }) => {

    // const [loading, setLoading] = useState(false);

    var validationSchema = yup.object({});
    if (currentFacility?.storeType === 'Sitelink') {
        validationSchema = validationSchema.shape({
            facility_data: yup.mixed().required('Facility data file is required'),
        });
    }    
    if (currentFacility?.storeType === 'Storman') {
        validationSchema = validationSchema.shape({
            income_charges_data: yup.mixed().required('Income charges data file is required'),
            key_stats_data: yup.mixed().required('Key stats file is required'),
        });
    }

    const initialValues = {
        facilityId: facilityId || '',
        storeType: currentFacility?.storeType || '',
        facility_data: null,
        income_charges_data: null,
        key_stats_data: null,
    };

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const calculateFacilityValuation = async (facilityId) => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/calculateFacilityValuation?facilityId=${facilityId}`);
            setLoading(false)
            let result = response.data;
            if (result.success) {
                console.log("response.data.data", response.data);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false)
            Swal.fire("Error", err.message, "error");
        }
    };

    const handleSubmit = async (values) => {
        setLoading(true);

        const formData = new FormData();
        formData.append('facilityId', values.facilityId);
        formData.append('storeType', values.storeType);

        let url = `/api/uploadStormanReportData`;
        if(values.storeType === 'Sitelink' ){
            formData.append('facility_data', values.facility_data);
            url = `/api/uploadSiteLinkReportData`;
        }
        else{
            formData.append('income_charges_data', values.income_charges_data);
            formData.append('key_stats_data', values.key_stats_data);
        }

        try{
            let res = await axios.post(url, formData);
            setLoading(false);
            if(res.data.success){
                setShow(false);
                Toast.fire({
                    icon: 'success',
                    title: res.data.message || 'File Uploaded!'
                });
                getFacilityDetails(facilityId);
                calculateFacilityValuation(facilityId);
                // Swal.fire(res.data.message, '', 'success').then(result => {
                //     getFacilityDetails(facilityId);
                // })
                formik.resetForm();
            }
            else {
                setShow(false);
                Toast.fire({
                    icon: 'error',
                    title: res.data.message || 'Upload Failed!'
                });
                // Swal.fire(res.data.message, '', 'error')
            }
        }catch(error) {
            setLoading(false);
            Toast.fire({
                icon: 'error',
                title: error.response?.data?.message || error.message
            });
            // Swal.fire(error.response.data.message, '', 'error')
        };
    }

    const handleCloseModal = () => {
        formik.resetForm();
        handleClose(); // Close the modal
    };

    return (
        <>
            {/* <Loader loading={loading} /> */}
            <Modal show={show} onHide={handleCloseModal} dialogClassName="">
                <form autoComplete="off" onSubmit={formik.handleSubmit} encType="multipart/form-data" >
                    <Modal.Header>
                        <h5 className="modal-title fs-14 mb-0">Upload Excel</h5>
                        {/*<a href="./documents/facilityList.xlsx" className="fs-14" download>Download Sample File</a>*/}
                        <CancelIcon style={{ cursor: 'pointer' }} onClick={handleCloseModal} />
                    </Modal.Header>
                    <Modal.Body>
                    <div className="row">
                    <h5 className='ml-5'>{ !currentFacility?.storeType ? "Please select Store Type at Client Input sheet" : "" }</h5>
                        { currentFacility?.storeType === 'Sitelink' ?
                            <div className="mb-4">
                                <label htmlFor="upload" className="btn btn-outline-primary w-100 mb-2">
                                    <span className="d-none d-sm-block"><span className="tf-icons bi bi-cloud-upload"></span>&nbsp; Upload Report File</span>
                                    <i className="bx bx-upload d-block d-sm-none"></i>
                                    <input 
                                    type="file" 
                                    id="upload"
                                    hidden=''
                                    accept={".xlsx, .xls"}
                                    name='facility_data'
                                    onChange={(e) => {
                                        formik.setFieldValue('facility_data', e.currentTarget.files[0]);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className={formik.touched.facility_data && formik.errors.facility_data ? 'account-file-input is-invalid form-control' : 'form-control account-file-input'}
                                    />
                                </label>
                                {formik.touched.facility_data && formik.errors.facility_data ? (
                                    <div className="text-danger">{formik.errors.facility_data}</div>
                                ) : null }
                                <div className="text-end">
                                    <a href="./documents/sample_siteLink_facility_details.xlsx" download>Download Sample File</a>
                                </div>
                            </div>
                        :""}

                        { currentFacility?.storeType === 'Storman' ?
                            <>
                                <div className="">
                                    <label htmlFor="income_chargs" className="btn btn-outline-primary w-100 mb-2">
                                        <span className="d-none d-sm-block"><span className="tf-icons bi bi-cloud-upload"></span>&nbsp; Upload Income Charged Report File</span>
                                        <i className="bx bx-upload d-block d-sm-none"></i>
                                        <input 
                                        type="file" 
                                        id="income_chargs"
                                        hidden=''
                                        accept={".xlsx, .xls"}
                                        name='income_charges_data'
                                        onChange={(e) => {
                                            formik.setFieldValue('income_charges_data', e.currentTarget.files[0]);
                                        }}
                                        onBlur={formik.handleBlur}
                                        className={formik.touched.income_charges_data && formik.errors.income_charges_data ? 'account-file-input is-invalid form-control' : 'form-control account-file-input'}
                                        />
                                    </label>
                                    {formik.touched.income_charges_data && formik.errors.income_charges_data ? (
                                        <div className="text-danger">{formik.errors.income_charges_data}</div>
                                    ) : null }
                                    <div className="text-end">
                                        <a href="./documents/sample_income_charges_storman.xlsx" download>Download Sample File</a>
                                    </div>
                                </div>
                                
                                <div className="mt-4">
                                    <label htmlFor="stats_upload" className="btn btn-outline-primary w-100 mb-2">
                                        <span className="d-none d-sm-block"><span className="tf-icons bi bi-cloud-upload"></span>&nbsp; Upload Statistics Report File</span>
                                        <i className="bx bx-upload d-block d-sm-none"></i>
                                        <input 
                                        type="file" 
                                        id="stats_upload"
                                        hidden=''
                                        accept={".xlsx, .xls"}
                                        name='key_stats_data'
                                        onChange={(e) => {
                                            formik.setFieldValue('key_stats_data', e.currentTarget.files[0]);
                                        }}
                                        onBlur={formik.handleBlur}
                                        className={formik.touched.key_stats_data && formik.errors.key_stats_data ? 'account-file-input is-invalid form-control' : 'form-control account-file-input'}
                                        />
                                    </label>
                                    {formik.touched.key_stats_data && formik.errors.key_stats_data ? (
                                        <div className="text-danger">{formik.errors.key_stats_data}</div>
                                    ) : null }
                                    <div className="text-end">
                                        <a href="./documents/sample_key_stats_storman.xlsx" download>Download Sample File</a>
                                    </div>
                                </div>
                        
                            </>
                        :""}
                </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="reset" className="btn btn-outline-primary" onClick={handleCloseModal}>Close</button>
                        <button type="submit" className="btn btn-primary">Upload</button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default Add;

Add.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    getFacilityDetails: PropTypes.func.isRequired,
    facilityId: PropTypes.number, // Adjust type according to your needs
};
