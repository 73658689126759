import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function Sidebar() {
    const location = useLocation();
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [isToggle, setIsToggle] = useState(false);

    const params = useParams();
    const [portfolioId, setPortfolioId] = useState(() => {
        const storedPortfolioId = localStorage.getItem('selectedPortfolio');
        return params && params.portfolioId ? parseInt(params.portfolioId) : (storedPortfolioId ? parseInt(storedPortfolioId) : '');
    });    
    const [facilityId, setFacilityId] = useState(() => {
        const storedFacilityId = localStorage.getItem('selectedFacility');
        return  params && params.facilityId ? parseInt(params.facilityId) : (storedFacilityId ? parseInt(storedFacilityId) : '');
    });

    const [loading, setLoading] = useState(false);
    const [facilities, setFacilities] = useState([]);

    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    };

    const handleSidebarToggle = () => {
        const toggleArrow = document.getElementById('layout_menu_toggle');

        const icon = toggleArrow.querySelector("i");
        icon.classList.toggle('bx-chevron-left');
        icon.classList.toggle('bx-chevron-right');

        // const asside = document.querySelector('.menu-vertical, .menu-vertical .menu-block, .menu-vertical .menu-inner>.menu-item, .menu-vertical .menu-inner>.menu-header');
        const asside = document.querySelector('.bg-color-sidebar');
        if (isToggle === false) {
            asside.style.display = 'none';  // change sidebar width
            toggleArrow.setAttribute("style", "position: absolute; left:4rem; border-radius:50%;");

            // document.querySelectorAll('.menu-item').forEach((e, i) => {
            //     if(e.getElementsByClassName("label_name")[0]){
            //         e.getElementsByClassName("label_name")[0].classList.add('d-none');
            //     }
            // })

            setIsToggle(true);
        }
        else {
            // asside.style.width = '227.483px'; // change sidebar width
            asside.style.display = 'block';

            toggleArrow.setAttribute("style", "position: absolute; left:15rem; border-radius:50%;");
            // document.querySelectorAll('.menu-item').forEach((e, i) => {
            //     if(e.getElementsByClassName("label_name")[0]){
            //         e.getElementsByClassName("label_name")[0].classList.remove('d-none');
            //     }
            // })
            setIsToggle(false);
        }
    }

    useEffect(() => {
        if (portfolioId) {
            getFacilitiesList(portfolioId);
        }
    }, [portfolioId]);

    const getFacilitiesList = async (portfolioId) => {
        let condition = `?portfolioId=${portfolioId}&orderBy=id&lastestAccessed=true`;
        if (user.role === 'SubClient') {
            condition += `&userId=${user.id}&role=${user.role}`
        }
        try {
            // setLoading(true);
            const response = await axios.get(`/api/facilities${condition}`);
            setLoading(false);
            let result = response.data;
            if (result.success) {
                setFacilities(result.data);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            Swal.fire("Error in getting Facilities List: " + err);
        }
    };

    const handleFacilityDashboard = (facility) => {
        localStorage.setItem('selectedFacility', facility.id)
        localStorage.setItem('selectedFacilityName', facility?.name ? facility.name : "")
        localStorage.setItem('selectedFacilityVerificationStatus', facility?.RecommendValuationIsVerified || '')
        setFacilityId(facility.id)
        navigate(`/facility-dashboard/${facility.id}`);
    };

    const otherPages = [
        user.portfolio?.id ? `/portfolio-dashboard/${user.portfolio.id}` : `/portfolio-dashboard/${portfolioId}`,
        '/facility',
        '/portfolios',
        '/users',
        '/email_assistance',
        '/profile',
        '/change-password',
        '/facility_listing',
    ];

    useEffect(() => {
        if (['/portfolios'].includes(location.pathname)) {
            localStorage.setItem('selectedPortfolio', '');
            localStorage.setItem('selectedPortfolioName', '');
            localStorage.setItem('selectedFacility', '');
            localStorage.setItem('selectedFacilityName', '');
            setFacilityId('');
            setPortfolioId('');
        }
    }, [location.pathname, portfolioId]);

    const goBack = () => {
        axios.post('/api/auth/goBack/login', { isSuperAdmin: user.isSuperAdmin, isAdmin: user.isAdmin })
            .then((res) => {
                if (res.data.success) {
                    localStorage.clear();
                    localStorage.setItem('sv-authUser', JSON.stringify(res.data.data));
                    navigate(`/portfolios`);
                } else {
                    Swal.fire(res.data.message, '', 'info');
                }
            })
            .catch((error) => {
                Swal.fire(error.message, '', 'info');
            });

    }
    const handlePortfolioToNull = async () => {
        localStorage.setItem('selectedPortfolio', '');
        localStorage.setItem('selectedPortfolioName', '');
        localStorage.setItem('selectedFacility', '');
        localStorage.setItem('selectedFacilityName', '');
        localStorage.setItem('selectedFacilityVerificationStatus', '');
        setFacilityId('');
        navigate('/portfolios'); 
    }

    return (
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme bg-color-sidebar">
            <div className="app-brand demo">
                <Link className="app-brand-link">
                    <span className="app-brand-logo demo">
                        <img src={process.env.REACT_APP_BASE_URL + "assets/img/storval-logo-1.png"} alt="" height="50px" style={{ width: '180px' }} />
                    </span>
                </Link>
                <Link className="layout-menu-toggle menu-link text-large ms-auto d-block cursor-pointer d-xl-none" id="layout_menu_toggle" onClick={handleSidebarToggle}>
                    <i className="bx bx-chevron-left bx-sm align-middle"></i>
                </Link>
            </div>

            <div className="menu-inner-shadow"></div>

            <ul className="menu-inner py-1">
                {((user.isSuperAdmin > 0 && user.role === 'Client') || (user.isAdmin > 0 && user.role === 'SubClient')) ?
                    <li className="menu-item" onClick={goBack}>
                        <Link className="menu-link">
                            {/* <i className="bx bx-chevron-left"></i> */}
                            <i className="menu-icon me-0 bx bi-arrow-left-circle"></i>
                            Admin Panel
                        </Link>
                    </li>
                : ""}

                {(user.role === 'SuperAdmin' && portfolioId) ? (
                    <li className="menu-item" onClick={handlePortfolioToNull}>
                        <Link to={`/portfolios`} className="menu-link">
                            <i className="menu-icon me-0 bx bi-arrow-left-circle"></i>
                            Admin Panel
                        </Link>
                    </li>
                ) : null}

                {/* {(user.role === 'SuperAdmin' && (location.pathname !== "/portfolios")) ? (
                    <li className="menu-item">
                        <Link to="/portfolios" className="menu-link">
                            <i className="menu-icon me-0 bx bi-arrow-left-circle"></i>
                            Admin Panel
                        </Link>
                    </li>
                ): ""
                } */}
                {user.role === 'SuperAdmin' && (
                    <>
                        {/* <li className={location.pathname === "/portfolios" ? "menu-item active" : "menu-item"}>
                            <Link to="/portfolios" className="menu-link">
                                <i className="menu-icon me-0 tf-icons bi bi-peopletf-icons bi bi-people"></i>
                                <div>Portfolios</div>
                            </Link>
                        </li> */}

                        {(!localStorage.getItem('selectedPortfolio') && !localStorage.getItem('selectedFacility')) ? (
                            <li className={location.pathname === "/portfolios" ? "menu-item active" : "menu-item"}>
                                <Link to="/portfolios" className="menu-link">
                                    <i className="menu-icon me-0 tf-icons bi bi-people"></i>
                                    <div>Portfolios</div>
                                </Link>
                            </li>
                        ) : (
                            <li className={location.pathname.includes("/portfolio-dashboard") ? "menu-item active" : "menu-item"}>
                                <Link to={`/portfolio-dashboard/${portfolioId}`} className="menu-link">
                                    <i className="menu-icon me-0 tf-icons bi bi-house-door"></i>
                                    {/* <div>{localStorage.getItem('selectedPortfolioName')} Dashboard</div> */}
                                    <div>Portfolio Dashboard</div>
                                </Link>
                            </li>
                        )}
                        {location.pathname === "/users" && 
                            <li className={location.pathname === "/users" ? "menu-item active" : "menu-item"}>
                                <Link to={`/users?portfolioId=${portfolioId}`} className="menu-link">
                                    <i className="menu-icon me-0 tf-icons bi bi-person-lines-fill"></i>
                                    <div>Users</div>
                                </Link>
                            </li>
                        }
                    </>
                )}
                {(user.role === 'Client' || user.role === 'SubClient') && (
                    <li className={location.pathname.includes(`/portfolio-dashboard`) ? "menu-item active" : "menu-item"}>
                        <Link to={`/portfolio-dashboard/${user.portfolio.id}`} className="menu-link">
                            <i className="menu-icon me-0 tf-icons bi bi-house-door"></i>
                            <div>Portfolio Dashboard</div>
                        </Link>
                    </li>
                )}
                {portfolioId && (
                    <li className={location.pathname === "/facility_listing" ? "menu-item active" : "menu-item"}>
                        <Link to="/facility_listing" className="menu-link">
                            <i className="menu-icon tf-icons bi bi-search me-0"></i>
                            <div>View all facilities</div>
                        </Link>
                    </li>
                )}
                {(portfolioId && facilities.length > 0) && (
                    <>
                        <li className="menu-header small text-uppercase">
                            <span className="menu-header-text ms-8">Facilities</span>
                        </li>
                        {
                            facilities.map((facility, index) => (
                                <li
                                    key={facility.id}
                                    className={`ms-15
                                        ${facilityId && facilityId === parseInt(facility.id) && !otherPages.includes(location.pathname)
                                            ? `menu-item active`
                                            : `menu-item`}
                                    `}

                                >
                                    <div className="menu-link cursor-pointer" onClick={() => handleFacilityDashboard(facility)}>
                                        <i className={`menu-icon me-0 tf-icons bi ${facilityId && facilityId === parseInt(facility.id) && !otherPages.includes(location.pathname) ? 'bi-circle-fill' : 'bi bi-circle'}`}></i>
                                        <div>{facility?.name || ''}</div>
                                    </div>
                                </li>
                            ))
                        }
                    </>
                )}
                { user && ((user?.role === 'SuperAdmin' && (location.pathname === "/portfolios" || location.pathname === "/profile" || location.pathname === "/change-password")) || 
                    ((user.isSuperAdmin > 0 && user.role === 'Client') ||
                    (user.isAdmin > 0 && user.role === 'SubClient'))) && 
                        <li className={(location.pathname === "/profile" || location.pathname === "/change-password") ? "menu-item active" : "menu-item"}>
                            <Link className="menu-link" to="/profile">
                                <i className="bx bx-cog me-2"></i>
                                <div className="align-middle me-2">{user?.role === 'SuperAdmin' ? "Admin User" : "Settings"}</div>
                            </Link>
                        </li>
                }
                {/* {user.role === 'SuperAdmin' &&
                    <li className={location.pathname === "/email_assistance" ? "menu-item active" : "menu-item"}>
                        <Link className="menu-link" to="/email_assistance">
                            <i className="bx bx-envelope me-2"></i>
                            <div className="align-middle me-2">Email Assistance</div>
                        </Link>
                    </li>
                } */}

                <li className="menu-item cursor-pointer">
                    <span className="menu-link" onClick={handleLogout}>
                        <i className="bx bx-power-off me-2"></i>
                        <div className="align-middle">Log Out</div>
                    </span>
                </li>
            </ul>
        </aside>
    )
}