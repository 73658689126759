import React, { useEffect, useState } from "react";
import { Layout } from "../Layout/Layout";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";

export default function CashFlow() {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [cashFlowData, setCashFlowData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [years, setYears] = useState(10);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);

    useEffect(() => {
        if(user.role !== 'SuperAdmin'){
            navigate(-1)
        }
        const portfolioId = localStorage.getItem('selectedPortfolio');
        const facilityId = localStorage.getItem('selectedFacility');

        if (portfolioId) {
            setSelectedPortfolio(JSON.parse(portfolioId));
        }

        if (facilityId) {
            setSelectedFacility(JSON.parse(facilityId));
            getCashFlowData(facilityId);
        }
    }, []);

    const initialValues = {
        portfolioId: '',
        id: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
    });

    const formatterWithDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const handleInputChange = (e, index, field) => {
        let { value } = e.target;
        value = value.replace('%', '').trim();
        const updatedValues = [...(cashFlowData?.[field] || Array.from({ length: years }, () => ""))];
        updatedValues[index] = value;
        setCashFlowData({
            ...cashFlowData,
            [field]: updatedValues
        });
    };

    const handleBlur = (e, index, field) => {
        let rawValue = parseFloat(e.target.value.replace('%', '').trim());
        const formattedValue = !isNaN(rawValue) ? rawValue.toFixed(2) + '%' : '';
        const updatedData = { ...cashFlowData };
        updatedData[field][index] = rawValue;

        setCashFlowData(updatedData);

        // Update the input field to display formatted value
        e.target.value = formattedValue;
        // console.log("formattedValue-----------",formattedValue);
    };

    const getCashFlowData = async (facilityId) => {
        setLoading(true)
        if (!facilityId) {
            console.error("Invalid facilityId", facilityId);
            setLoading(false);
            return;
        }
        try {
            const response = await axios.get(`api/cashFlow?facilityId=${facilityId}`);
            setLoading(false)
            if (response.data.success) {
                setCashFlowData(response.data.data.CashFlow);

            } else {
                Swal.fire('Error', 'There is no data for this facility', 'error');
            }
        } catch (error) {
            setLoading(false)
            Swal.fire('Error', error.message, 'error');
        }
    }

    const calculateFacilityValuation = async (facilityId) => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/calculateFacilityValuation?facilityId=${facilityId}`);
            setLoading(false)
            let result = response.data;
            if (result.success) {
                console.log("response.data.data", response.data);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false)
            Swal.fire("Error", err.message, "error");
        }
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedFacility) {
            Swal.fire('Warning!', 'Please Select a Facility!', 'warning');
            return;
        }
        setLoading(true);
        try {
            const marketGrowths = cashFlowData.marketGrowth.map((value, index) => ({
                year: index + 1,
                facilityId: selectedFacility,
                marketGrowth: parseFloat(value)
            }));

            const actualGrowths = cashFlowData.actualGrowth.map((value, index) => ({
                year: index + 1,
                facilityId: selectedFacility,
                actualGrowth: parseFloat(value)
            }));

            const otherIncomes = cashFlowData.otherIncome.map((value, index) => ({
                year: index + 1,
                facilityId: selectedFacility,
                otherIncome: parseFloat(value)
            }));

            const modifiedOtherIncomes = cashFlowData.modifiedOtherIncome.map((value, index) => ({
                year: index + 1,
                facilityId: selectedFacility,
                modifiedOtherIncome: parseFloat(value)
            }));
            const response = await axios.put('/api/saveCashFlowData', {
                marketGrowths,
                actualGrowths,
                otherIncomes,
                modifiedOtherIncomes
            });

            if (response.data.success) {
                setLoading(false);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message || 'Update Successful!'
                });
                calculateFacilityValuation(selectedFacility)
                getCashFlowData(selectedFacility);
            } else {
                // Swal.fire(response.data.message, '', 'error');
                Toast.fire({
                    icon: 'error',
                    title: response.data.message || 'Update Failed!'
                });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            // Swal.fire('Error', error.message, 'error');
            Toast.fire({
                icon: 'error',
                title: error.response?.data?.message || error.message
            });
        }
    };

    const handleDefaultValuesSaving = async (facilityId) => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/facility/saveFacilityGrowths?facilityId=${facilityId}`);
            setLoading(false)
            if (response.data.success) {
                setLoading(false);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message || 'Values Saved!'
                });
                calculateFacilityValuation(facilityId)
                getCashFlowData(facilityId);
            } else {
                // Swal.fire(response.data.message, '', 'error');
                Toast.fire({
                    icon: 'error',
                    title: response.data.message || 'Failed!'
                });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false)
            // Swal.fire('Error', error.message, 'error');
            Toast.fire({
                icon: 'error',
                title: error.response?.data?.message || error.message
            });
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header
                    pageTitle={localStorage.getItem('selectedFacilityName')}
                    verificationStatus={localStorage.getItem('selectedFacilityVerificationStatus')}
                />
                <div className="container-xxl flex-grow-1 container-p-y">
                    {selectedFacility !== null ?
                        <React.Fragment>
                            {/* <NavHeader /> */}
                            <div className="bg-white p-3 mt-1 mb-3">
                                <div className="row">
                                    <form id="formCashFlowMarketGrowth" method="POST" onSubmit={handleSubmit}>
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 view ">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="alert alert-success col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <strong>Defer with Expansion : </strong> {cashFlowData && cashFlowData.expectedMonthsToCompleteExpansion ? cashFlowData.expectedMonthsToCompleteExpansion : 0} Months
                                                </div>
                                                <div className="">
                                                    *Please use this button if default data is not already set in inputs &nbsp;&nbsp;
                                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => handleDefaultValuesSaving(selectedFacility)}>Save Default Values</button>
                                                </div>
                                            </div>
                                            <div className="facilityTable table-responsive">
                                                <table className="table table-bordered table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th className="table-blank-space Rj-w-30" scope="col"></th>
                                                            {cashFlowData && cashFlowData.marketGrowth && cashFlowData.marketGrowth.length > 0
                                                                ? cashFlowData.marketGrowth.map((_, index) => (
                                                                    <th key={index}>Year {index + 1}</th>
                                                                ))
                                                                : Array.from({ length: 10 }, (_, index) => (
                                                                    <th key={index}>Year {index + 1}</th>
                                                                ))
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col">Market Growth</td>
                                                            {cashFlowData && cashFlowData.marketGrowth && cashFlowData.marketGrowth.length > 0
                                                                ? cashFlowData.marketGrowth.map((value, index) => (
                                                                    <td key={index}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            name="marketGrowth"
                                                                            value={
                                                                                cashFlowData?.marketGrowth?.[index]
                                                                                    ? cashFlowData.marketGrowth[index] + '%'
                                                                                    : cashFlowData.marketGrowth[index] || ''
                                                                            }
                                                                            onChange={(e) => handleInputChange(e, index, 'marketGrowth')}
                                                                            onBlur={(e) => handleBlur(e, index, 'marketGrowth')}
                                                                            onFocus={(e) => {
                                                                                let rawValue = e.target.value.replace('%', '').trim() || '';
                                                                                handleInputChange({ target: { value: rawValue } }, index, 'marketGrowth');
                                                                            }}
                                                                        />
                                                                    </td>
                                                                ))
                                                                : Array.from({ length: years }, (_, index) => (
                                                                    <td key={index}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            name="marketGrowth"
                                                                            value=""
                                                                            // onChange={(e) => handleInputChange(e, index)}
                                                                            onChange={(e) => handleInputChange(e, index, 'marketGrowth')}
                                                                        />
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col" >Actual Growth</td>
                                                            {cashFlowData && cashFlowData.actualGrowth && cashFlowData.actualGrowth.length > 0
                                                                ? cashFlowData.actualGrowth.map((value, index) => (
                                                                    <td key={index}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            name="actualGrowth"
                                                                            value={
                                                                                cashFlowData?.actualGrowth?.[index]
                                                                                    ? cashFlowData.actualGrowth[index] + '%'
                                                                                    : cashFlowData.actualGrowth[index] || ''
                                                                            }
                                                                            onChange={(e) => handleInputChange(e, index, 'actualGrowth')}
                                                                            onBlur={(e) => handleBlur(e, index, 'actualGrowth')}
                                                                            onFocus={(e) => {
                                                                                let rawValue = e.target.value.replace('%', '').trim() || '';
                                                                                handleInputChange({ target: { value: rawValue } }, index, 'actualGrowth');
                                                                            }}
                                                                        />
                                                                    </td>
                                                                ))
                                                                : Array.from({ length: years }, (_, index) => (
                                                                    <td key={index}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            name="actualGrowth"
                                                                            value=""
                                                                            onChange={(e) => handleInputChange(e, index, 'actualGrowth')}
                                                                        />
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col" >Other Income</td>
                                                            {cashFlowData && cashFlowData.otherIncome && cashFlowData.otherIncome.length > 0
                                                                ? cashFlowData.otherIncome.map((value, index) => (
                                                                    <td key={index}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            name="otherIncome"
                                                                            value={
                                                                                cashFlowData?.otherIncome?.[index]
                                                                                    ? cashFlowData.otherIncome[index] + '%'
                                                                                    : cashFlowData.otherIncome[index] || ''
                                                                            }
                                                                            // onChange={(e) => handleInputChange(e, index, 'otherIncome')}
                                                                            // onBlur={(e) => handleBlur(e, index, 'otherIncome')}
                                                                            onFocus={(e) => {
                                                                                let rawValue = e.target.value.replace('%', '').trim() || '';
                                                                                handleInputChange({ target: { value: rawValue } }, index, 'otherIncome');
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    </td>
                                                                ))
                                                                : Array.from({ length: years }, (_, index) => (
                                                                    // <td key={index}>0.00%</td>
                                                                    <td key={index}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            name="otherIncome"
                                                                            value=""
                                                                            onChange={(e) => handleInputChange(e, index, 'otherIncome')}
                                                                        />
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                        {cashFlowData && cashFlowData.modifiedOtherIncome && cashFlowData.modifiedOtherIncome.length > 0 && (
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col" >Modified Other Income</td>
                                                            {cashFlowData && cashFlowData.modifiedOtherIncome && cashFlowData.modifiedOtherIncome.length > 0
                                                                ? cashFlowData.modifiedOtherIncome.map((value, index) => (
                                                                    <td key={index}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            name="modifiedOtherIncome"
                                                                            value={
                                                                                cashFlowData?.modifiedOtherIncome?.[index]
                                                                                    ? cashFlowData.modifiedOtherIncome[index] + '%'
                                                                                    : cashFlowData.modifiedOtherIncome[index] || ''
                                                                            }
                                                                            onChange={(e) => handleInputChange(e, index, 'modifiedOtherIncome')}
                                                                            onBlur={(e) => handleBlur(e, index, 'modifiedOtherIncome')}
                                                                            onFocus={(e) => {
                                                                                let rawValue = e.target.value.replace('%', '').trim() || '';
                                                                                handleInputChange({ target: { value: rawValue } }, index, 'modifiedOtherIncome');
                                                                            }}
                                                                        />
                                                                    </td>
                                                                ))
                                                                : Array.from({ length: years }, (_, index) => (
                                                                    <td key={index}>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control form-control-sm"
                                                                            name="modifiedOtherIncome"
                                                                            value=""
                                                                            onChange={(e) => handleInputChange(e, index, 'modifiedOtherIncome')}
                                                                        />
                                                                    </td>
                                                                ))
                                                            }
                                                        </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {selectedFacility !== null ?
                                                <div className="mt-4 mb-4 d-flex justify-content-end">
                                                    <button type="submit" className="btn btn-primary me-2">Save Changes</button>
                                                </div>
                                                : ""}

                                            <div className="facilityTable table-responsive">
                                                <div className="NewTable-Rj">
                                                    <table className="table table-bordered table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" >Fee Rate Matures</th>
                                                                <th scope="col" style={{ backgroundColor: '#fff' }}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="colspan=2" className="theme-color-bg">Month</th>
                                                                <td className="bg-white">{cashFlowData && cashFlowData.MonthFeeRateReachesMaturity ? cashFlowData.MonthFeeRateReachesMaturity.MonthFeeRateReachesMaturity : 0}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="colspan=2" className="theme-color-bg ">Actual</th>
                                                                <td className="bg-white">{cashFlowData && cashFlowData.MonthFeeRateReachesMaturity && cashFlowData.MonthFeeRateReachesMaturity.AnnualFeeRate ? formatterWithDecimals.format(cashFlowData.MonthFeeRateReachesMaturity.AnnualFeeRate) : '$0'}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="colspan=2" className="theme-color-bg ">Market</th>
                                                                <td className="bg-white">{cashFlowData && cashFlowData.MonthFeeRateReachesMaturity && cashFlowData.MonthFeeRateReachesMaturity.ActualAnnualFeeRate ? formatterWithDecimals.format(cashFlowData.MonthFeeRateReachesMaturity.ActualAnnualFeeRate) : '$0'}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                        <div className="mb-3 mt-3">
                                            <h4 style={{ color: "#697a8d" }}>Please Select a {selectedPortfolio === null ? "Portfolio" : "Facility"}!</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Layout>
        </>
    );
}