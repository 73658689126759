import { Link, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import * as yup from 'yup';
import axios from "axios";
import { useState } from "react";

const validationSchema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string('Enter your password')
      .required('Password is required'),
    acceptingTerms: yup
    .boolean()
    .oneOf([true], 'You must accept the terms and conditions')
    .required('You must accept the terms and conditions'),
});
export default function Login(){
    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => { showPassword ? setShowPassword(false) : setShowPassword(true); }

    const navigate = useNavigate();
    const initialValues = {
        email: '',
        password: '',
        acceptingTerms: false
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);            
        }
    });
    const handleSubmit = async (values) => {
        try {
            const response = await axios.post('/api/auth/login', values);
            if (response.data.success) {
                // if (response.data.data.role === 'SuperAdmin') {
                //     localStorage.setItem('sv-authUser', JSON.stringify(response.data.data));
                //     navigate('/portfolios');
                // } else {
                    navigate('/verify/');
                // }
            } else {
                Swal.fire(response.data.message, '', 'info');
            }
        } catch (error) {
            Swal.fire(error.message, '', 'info');
        }
    }
    

    return(
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    <div className="card">
                        <div className="card-body card-padding">
                            <div className="app-brand justify-content-center">
                                <span className="cursor-pointer app-brand-link gap-2">
                                    <img src={process.env.REACT_APP_BASE_URL+"assets/img/logo.png"} alt="" height="100px" />
                                </span>
                            </div>
                            <h2 className="text-center fw-bold">Welcome Back!</h2>
                            <h4 className="text-center">Please Sign in</h4>

                            <form id="formAuthentication" className="mb-3"  onSubmit={formik.handleSubmit} method="POST">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" name="email" placeholder="Enter your email" autoFocus value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    {formik.touched.email && formik.errors.email && (
                                    <div className="text-danger">{formik.errors.email}</div>
                                )}
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <label className="form-label" htmlFor="password">Password</label>
                                    <div className="input-group input-group-merge">
                                        <input type={showPassword ? "text" : "password"} id="password" className="form-control" name="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                        <span className="input-group-text cursor-pointer" onClick={handleShowPassword}><i className={showPassword ? "bx bx-show" : "bx bx-hide"}></i></span>
                                    </div>
                                {formik.touched.password && formik.errors.password && (
                                    <div className="text-danger">{formik.errors.password}</div>
                                )}
                                </div>
                                <div className="mb-3 text-end">
                                    <Link to="/forgot-password">
                                        <span>Forgot Password?</span>
                                    </Link>
                                </div>
                                <div className="mb-3">
                                    <div className="form-check">
                                        <div>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="acceptingTerms"
                                                onChange={formik.handleChange}
                                                name="acceptingTerms"
                                            />
                                            <label className="form-check-label fs-13" htmlFor="acceptingTerms"> 
                                                By accessing this system, you acknowledge and agree to our <Link to="https://storval.com.au/terms-of-service/" target="_blank">Terms of Service</Link>
                                            </label>
                                        </div>                                        
                                    </div>
                                    {formik.touched.acceptingTerms && formik.errors.acceptingTerms && (
                                        <div className="text-danger">{formik.errors.acceptingTerms}</div>
                                    )}
                                </div>
                                
                                <div className="mb-3">
                                    <button type="Submit" className="btn btn-lg btn-primary w-100">Log in</button>
                                </div>

                                {/* <div className="divider my-4">
                                    <div className="divider-text">Or</div>
                                </div>
                                <div className="mb-3 btn-google">
                                    <button type="button" className="btn btn-lg btn-outline-success w-100">Login with Google</button>
                                </div> */}
                            </form>

                            <p className="text-center">
                                <span>Don't have an account?</span>
                                <Link to="https://storval.com.au/contact/" target="_blank">
                                    <span> Contact Us</span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}