
import * as yup from 'yup';
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from 'formik';
import Loader from "../Loader/Loader";
import Header from "../Header/Header";
import { Layout } from "../Layout/Layout";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

const validationSchema = yup.object({
    portfolioId: yup.string().required('Portfolio is required'),
    facility_code: yup.string().required('Facility Code is required'),
    name: yup.string().required('Facility Name is required'),
    // address: yup.string().required('Facility Address is required'),
    country: yup.string().required('Country is required'),
    state: yup.string().required('State is required'),
    // postCode: yup.string().required('Post Code is required'),
    // titleReference: yup.string().required('Title Reference is required'),
});

export default function AddFacility() {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const portfolioId = user.portfolio ? user.portfolio.id : null;
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const queryParamFacilityId = searchParams.get("id");
    const queryParamPortfolioId = localStorage.getItem('selectedPortfolio')//searchParams.get("portfolioId");
    const [type, setType] = useState("Add");
    const [taxRates, setTaxRates] = useState([]);

    useEffect(() => {
        if(user.role !== 'SuperAdmin'){
            navigate(-1)
        }
        if (queryParamFacilityId && queryParamFacilityId !== '') {
            setType("Update");
        }
    }, [queryParamFacilityId]);

    const initialValues = {
        id: queryParamFacilityId || '',
        portfolioId: queryParamPortfolioId || '',
        facility_code: '',
        name: '',
        address: '',
        titleReference: '',
        country: '', 
        state: '',
        postCode: '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
            // formik.resetForm();
        }
    });
    
    useEffect(() => {
        if (queryParamPortfolioId && queryParamPortfolioId !== '') {
            formik.setFieldValue('portfolioId', queryParamPortfolioId);
        }
        getClientsList();
        getTaxRates();
    }, [queryParamPortfolioId]);

    useEffect(() => {
        // getClientsList()
        if(queryParamFacilityId > 0){
            axios.get(`api/facility?id=${queryParamFacilityId}`).then(res => {
                if(res.data.success){
                    formik.setValues({
                        id: res.data.data.id,
                        portfolioId: res.data.data.portfolioId,
                        name: res.data.data.name,
                        facility_code: res.data.data.facility_code,
                        address: res.data.data.address,
                        titleReference: res.data.data.titleReference,
                        country: res.data.data.country,
                        state: res.data.data.state_code,
                        postCode: res.data.data.postCode,
                    });
                }
            })
        }
        else{
            formik.resetForm();
        }
    },[queryParamFacilityId]);

    const getClientsList = async() => {
        let condition = `?role=Client`;
        if(user.role !== 'SuperAdmin'){
            condition += `&id=${user.id}`;
        }
        setLoading(true);
        try{
            await axios.get(`/api/users${condition}`)
            .then(function (response) {
                let result = response.data;
                if(result.success){
                    setClients(result.data);
                    setLoading(false)
                }
            })
        }catch(err) {
            console.log(err)
            Swal.fire("Error in getting Clients List: "+err);
        }
    }

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleSubmit = (values) => {
        const apiCall = queryParamFacilityId > 0 ? axios.put : axios.post;
        const apiUrl = queryParamFacilityId > 0 ? `api/facility/update` : `api/facility/create`;
    
        apiCall(apiUrl, values)
            .then((res) => {
                if (res.data.success) {
                    Toast.fire({
                        icon: 'success',
                        title: res.data.message || `${queryParamFacilityId > 0 ? 'Update' : 'Created'} Successful!`
                    });
                    navigate(`/facility_listing?portfolioId=${values.portfolioId}`);
                } else {
                    Toast.fire({
                        icon: 'warning',
                        title: res.data.message || `${queryParamFacilityId > 0 ? 'Update' : 'Created'} Failed!`
                    });
                    // Swal.fire('Warning!', res.data.message, 'warning');
                }
            })
            .catch((error) => {
                const errorMessage = error.response?.data?.message || "An unexpected error occurred.";
                Toast.fire({
                    icon: 'error',
                    title: errorMessage
                });
                // Swal.fire('Warning!', errorMessage, 'warning');
            });
    };

    const getTaxRates = async() => {
        try{
            await axios.get(`/api/taxRates`)
            .then(function (response) {
                let result = response.data;
                if(result.success){
                    setTaxRates(result.data);
                    console.log("result.data", result.data);
                }
            })
        }catch(err) {
            console.log(err)
            Swal.fire(err);
        }
    }
    return(
        <>
            <Loader loading={loading} />
            <Layout>
                <Header 
                    pageTitle={
                        type === 'Update'
                            ? `Edit ${formik.values.name}`
                            : `${localStorage.getItem('selectedPortfolioName') ? localStorage.getItem('selectedPortfolioName') + ' - Add New Facility' : ''}`
                    }
                        // `${clients.find(client => client.portfolio.id === parseInt(queryParamPortfolioId, 10))?.portfolio?.name || ''} - Add New Facility `} 
                />
                <div className="container-xxl flex-grow-1 container-p-y">
                    {localStorage.getItem('selectedPortfolio') ?
                        <div className="">
                            <div>
                                <div className="card mb-3">
                                    <form id="formAddFacility" method="POST" onSubmit={formik.handleSubmit}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='row mb-3'>
                                                        <div className='col-md-6'>
                                                            <label className="form-label" htmlFor="portfolio">Select Portfolio</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <select
                                                                id="portfolioId"
                                                                className="select2 form-select form-control form-select-sm"
                                                                name="portfolioId"
                                                                value={formik.values.portfolioId}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            >
                                                                <option value="">Select Portfolio</option>
                                                                {clients.map(client => (
                                                                    <option
                                                                        key={client.portfolio?.id || ''}
                                                                        value={client.portfolio?.id || ''}
                                                                        selected={client.portfolio && client.portfolio.id === portfolioId ? "selected" : ""}
                                                                    >
                                                                        {client.portfolio?.name || ''}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {formik.touched.portfolioId && formik.errors.portfolioId && (
                                                                <div className="text-danger">{formik.errors.portfolioId}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='row mb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="country" className="form-label">Country</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <select
                                                                id="country"
                                                                className="select2 form-select form-control form-select-sm"
                                                                name="country"
                                                                value={formik.values.country}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            >
                                                                <option value="">Select Country</option>
                                                                <option value="Australia">Australia</option>
                                                                <option value="New Zealand">New Zealand</option>
                                                            </select>
                                                            {formik.touched.country && formik.errors.country && (
                                                                <div className="text-danger">{formik.errors.country}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='row mb-3'>
                                                        <div className='col-md-6'>
                                                            <label className="form-label" htmlFor="name">Facility Name</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input 
                                                                type="text" 
                                                                className="form-control form-control-sm" 
                                                                id="name" 
                                                                placeholder="Enter Facility Name" 
                                                                aria-label="Facility Name" 
                                                                value={formik.values.name}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.name && formik.errors.name && (
                                                                <div className="text-danger">{formik.errors.name}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='row mb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="state" className="form-label">State</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <select
                                                                id="state"
                                                                className="select2 form-select form-control form-select-sm"
                                                                name="state"
                                                                value={formik.values.state}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            >
                                                                <option value="">Select State</option>
                                                                {taxRates
                                                                    .filter(rate => rate.country === formik.values.country)
                                                                    .map(rate => (
                                                                        <option key={rate.id} value={rate.state}>
                                                                            {rate.fullName}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select> 
                                                            {formik.touched.state && formik.errors.state && (
                                                                <div className="text-danger">{formik.errors.state}</div>
                                                            )}      
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='row mb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="facility_code" className="form-label">Facility Code</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input 
                                                                type="text" 
                                                                className="form-control form-control-sm" 
                                                                id="facility_code" 
                                                                placeholder="Enter Facility Code" 
                                                                aria-label="Facility Code" 
                                                                value={formik.values.facility_code}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.facility_code && formik.errors.facility_code && (
                                                                <div className="text-danger">{formik.errors.facility_code}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='row mb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="postCode" className="form-label">Post Code</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input 
                                                                type="text" 
                                                                className="form-control form-control-sm" 
                                                                id="postCode" 
                                                                placeholder="Enter Post Code" 
                                                                aria-label="Post Code" 
                                                                value={formik.values.postCode}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.postCode && formik.errors.postCode && (
                                                                <div className="text-danger">{formik.errors.postCode}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='row mb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="titleReference" className="form-label">Title Reference</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input 
                                                                type="text" 
                                                                className="form-control form-control-sm" 
                                                                id="titleReference" 
                                                                placeholder="Enter Title Reference" 
                                                                aria-label="Title Reference" 
                                                                value={formik.values.titleReference}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.titleReference && formik.errors.titleReference && (
                                                                <div className="text-danger">{formik.errors.titleReference}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='row mb-3'>
                                                        <div className='col-md-6'>
                                                            <label className="form-label" htmlFor="address">Facility Address</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input 
                                                                type="text" 
                                                                className="form-control form-control-sm" 
                                                                id="address" 
                                                                placeholder="Enter Facility Address" 
                                                                aria-label="Facility Address" 
                                                                value={formik.values.address}
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                            />
                                                            {formik.touched.address && formik.errors.address && (
                                                                <div className="text-danger">{formik.errors.address}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                



                                            </div>
                                            <div className="mt-2 mb-4 d-flex justify-content-end">
                                                <button type="button" className="btn btn-outline-secondary me-3" onClick={() => navigate(-1)}>Back</button>
                                                <button type="submit" className="btn btn-primary">{type}</button>   
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    :
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                        <div className="mb-3 mt-3">
                                            <h4 style={{ color: "#697a8d" }}>Please Select a {localStorage.getItem('selectedPortfolio') === null ? "Portfolio" : "Facility"}!</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }   
                </div>
            </Layout>
        </>
    );
}