
import React, { useEffect, useState } from "react";
import { Layout } from "../Layout/Layout";
import { useFormik } from "formik";
import axios from "axios";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";

export default function SensitivityOutput() {
    const [valuationResult, setValuationResult] = useState([]);
    const [sensitivityOutput, setSensitivityOutput] = useState([]);
    const [buildupData, setBuildupData] = useState([]);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [loading, setLoading] = useState(false);
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);

    useEffect(() => {
        const portfolioId = localStorage.getItem('selectedPortfolio');
        const facilityId = localStorage.getItem('selectedFacility');

        if (portfolioId) {
            setSelectedPortfolio(JSON.parse(portfolioId));
        }

        if (facilityId) {
            setSelectedFacility(JSON.parse(facilityId));
            getSensitivityCalculation(facilityId);
        }
    }, []);

    const initialValues = {
        portfolioId: '',
        id: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
    });

    const getSensitivityCalculation = (facilityId) => {
        setLoading(true)
        if (!facilityId) {
            console.error("Invalid facilityId", facilityId);
            setLoading(false);
            return;
        }
        axios.get(`api/sensitivity?facilityId=${facilityId}`).then(res => {
            setLoading(false)
            if (res.data.success) {
                const result = res.data.data;
                // console.log("setValuationResult === ",res.data.data)
                // console.log("setSensitivityOutput === ",res.data.otherInputs)
                // console.log("setBuildupData === ",res.data.buildUpData)
                setValuationResult(result);
                setSensitivityOutput(res.data.otherInputs);
                setBuildupData(res.data.buildUpData);
            }
        })
    }

    const formatterWithoutDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const formatterWithDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatValue = (value) => {
        const numericValue = parseFloat(value);
        const hasSixDigitsBeforeDecimal = Math.floor(Math.abs(numericValue)).toString().length >= 3;

        return hasSixDigitsBeforeDecimal
            ? formatterWithoutDecimals.format(value)
            : formatterWithDecimals.format(value);
    };

    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header
                    pageTitle={localStorage.getItem('selectedFacilityName')}
                    verificationStatus={localStorage.getItem('selectedFacilityVerificationStatus')}
                />
                <div className="container-xxl flex-grow-1 container-p-y">
                    {/*{ selectedFacility !== null ? */}
                    {selectedFacility && (user.role === "SuperAdmin" ? selectedPortfolio !== null : true) ?
                        <React.Fragment>
                            {/* <NavHeader /> */}
                            <div className="bg-white p-3 mt-1 mb-3">
                                <div className="row">
                                    {user.role === 'SuperAdmin' ?
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 view">
                                            <div className="facilityTable table-responsive wrapper">
                                                <table className="table table-bordered table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th className="bg-New  sticky-col first-col" scope="col" style={{ color: "black!important" }}>{valuationResult.adoptedValuation}</th>
                                                            <th className="table-blank-space " scope="col"></th>
                                                            <th scope="col" colSpan={valuationResult.adoptedValuation === 'Static' ? 1 : 2} className="text-center">Fee Rate</th>
                                                            <th scope="col" colSpan={valuationResult.adoptedValuation === 'Static' ? 1 : 2} className="text-center">Occupancy</th>
                                                            <th scope="col" className="text-center">Other Income</th>
                                                            <th scope="col" colSpan={2} className="text-center">Expenses</th>
                                                            <th scope="col" colSpan={2} className={`text-center ${valuationResult.adoptedValuation === 'Static' ? '' : 'd-none'}`}>Capitalisation Rate</th>
                                                            <th scope="col" className="text-center">Fully </th>

                                                        </tr>
                                                        <tr>
                                                            <th className="table-blank-space " scope="col"></th>
                                                            <th className="table-blank-space " scope="col"></th>
                                                            <th scope="col">
                                                                <input type="text" className="form-control form-control-sm"
                                                                    name="feeRatePositive"
                                                                    value={sensitivityOutput.feeRatePositive + "%"}
                                                                    disabled
                                                                />
                                                            </th>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" :
                                                                <th scope="col">
                                                                    <input type="text" className="form-control form-control-sm" name="feeRateNegative"
                                                                        value={sensitivityOutput.feeRateNegative + "%"}
                                                                        disabled
                                                                    />
                                                                </th>
                                                            }
                                                            {valuationResult.adoptedValuation === 'Static' ? "" :
                                                                <th scope="col">
                                                                    <input type="text" className="form-control form-control-sm" name="occupancyPositive"
                                                                        value={sensitivityOutput.occupancyPositive + "%"}
                                                                        disabled
                                                                    />
                                                                </th>
                                                            }
                                                            <th scope="col">
                                                                <input type="text" className="form-control form-control-sm" name="occupancyNegative"
                                                                    value={sensitivityOutput.occupancyNegative + "%"}
                                                                    disabled
                                                                />
                                                            </th>
                                                            <th scope="col">
                                                                <input type="text" className="form-control form-control-sm" name="otherIncome"
                                                                    value={sensitivityOutput.otherIncome + "%"}
                                                                    disabled
                                                                />
                                                            </th>
                                                            <th scope="col">
                                                                <input type="text" className="form-control form-control-sm" name="expensesNegative"
                                                                    value={sensitivityOutput.expensesNegative + "%"}
                                                                    disabled
                                                                />
                                                            </th>
                                                            <th scope="col">
                                                                <input type="text" className="form-control form-control-sm" name="expensesPositive"
                                                                    value={sensitivityOutput.expensesPositive + "%"}
                                                                    disabled
                                                                />
                                                            </th>
                                                            {valuationResult.adoptedValuation === 'Static' ?
                                                                <>
                                                                    <th scope="col">
                                                                        <input type="text" className="form-control form-control-sm" name="capitalisationRatePositive"
                                                                            value={sensitivityOutput.capitalisationRatePositive + "%"}
                                                                            disabled
                                                                        />
                                                                    </th>
                                                                    <th scope="col">
                                                                        <input type="text" className="form-control form-control-sm" name="capitalisationRateNegative"
                                                                            value={sensitivityOutput.capitalisationRateNegative + "%"}
                                                                            disabled
                                                                        />
                                                                    </th>
                                                                </>
                                                                : ""}
                                                            <th scope="col">optimised</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> NSA </td>
                                                            <td className="highlight-column bg-table">{
                                                                !isNaN(valuationResult.NSA)
                                                                    ? parseFloat(valuationResult.NSA).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                    : !isNaN(valuationResult.totalNetStorageArea)
                                                                        ? parseFloat(valuationResult.totalNetStorageArea).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                        : 0
                                                            }</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <td></td>}
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <td></td>}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Assessment Adopted  </td>
                                                            <td className="highlight-column bg-table">{valuationResult.adoptedAssessmentType}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <td></td>}
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <td></td>}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Occupancy  </td>
                                                            <td className="highlight-column bg-table">{valuationResult.occupancy ? (valuationResult.occupancy).toFixed(2) : 0}%</td>
                                                            <td>{valuationResult.occupancy ? (valuationResult.occupancy).toFixed(2) : 0}%</td>

                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <td>{valuationResult.occupancy ? (valuationResult.occupancy).toFixed(2) : 0}%</td>}
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <td className="table-red-bg">{parseFloat(sensitivityOutput.OcupancyWithoccupancyPositive).toFixed(2)}%</td>}

                                                            <td className="table-red-bg">{parseFloat(sensitivityOutput.OcupancyWithoccupancyNegative).toFixed(2)}%</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}
                                                            <td className="bg-table">{sensitivityOutput.OcupancyWithFullyOptimised ? (sensitivityOutput.OcupancyWithFullyOptimised).toFixed(2) : 0}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Average Storage Fee Rate  </td>
                                                            <td className="highlight-column bg-table">{valuationResult.feeRate ? formatterWithDecimals.format(valuationResult.feeRate) : ''}</td>
                                                            <td className="table-red-bg">{sensitivityOutput.AvgStorageFeeRateWithFeeRatePositive ? formatterWithDecimals.format(sensitivityOutput.AvgStorageFeeRateWithFeeRatePositive) : ''}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td className="table-red-bg">{sensitivityOutput.AvgStorageFeeRateWithFeeRateNegative ? formatterWithDecimals.format(sensitivityOutput.AvgStorageFeeRateWithFeeRateNegative) : ''}</td>
                                                                <td>{sensitivityOutput.AvgStorageFeeRateWithOccupancyPositive ? formatterWithDecimals.format(sensitivityOutput.AvgStorageFeeRateWithOccupancyPositive) : ''}</td>
                                                            </>}
                                                            <td>{sensitivityOutput.AvgStorageFeeRateWithOccupancyNegative ? formatterWithDecimals.format(sensitivityOutput.AvgStorageFeeRateWithOccupancyNegative) : ''}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}
                                                            <td className="bg-table table-red-bg">{sensitivityOutput.AvgStorageFeeRateWithFullyOptimised ? formatterWithDecimals.format(sensitivityOutput.AvgStorageFeeRateWithFullyOptimised) : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Total Storage Revenue  </td>
                                                            <td className="highlight-column bg-table">{valuationResult.totalStorageRevenue ? formatterWithoutDecimals.format(valuationResult.totalStorageRevenue) : ''}</td>
                                                            <td>{sensitivityOutput.TotalStorageRevenueWithFeeRatePositive ? formatterWithoutDecimals.format(sensitivityOutput.TotalStorageRevenueWithFeeRatePositive) : ''}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{sensitivityOutput.TotalStorageRevenueWithFeeRateNegative ? formatterWithoutDecimals.format(sensitivityOutput.TotalStorageRevenueWithFeeRateNegative) : ''}</td>
                                                                <td>{sensitivityOutput.TotalStorageRevenueWithoccupancyPositive ? formatterWithoutDecimals.format(sensitivityOutput.TotalStorageRevenueWithoccupancyPositive) : ''}</td>
                                                            </>
                                                            }
                                                            <td>{sensitivityOutput.TotalStorageRevenueWithoccupancyNegative ? formatterWithoutDecimals.format(sensitivityOutput.TotalStorageRevenueWithoccupancyNegative) : ''}</td>
                                                            <td>{valuationResult.totalStorageRevenue ? formatterWithoutDecimals.format(valuationResult.totalStorageRevenue) : ''}</td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}
                                                            <td className="bg-table">{sensitivityOutput.TotalStorageRevenue ? formatterWithoutDecimals.format(sensitivityOutput.TotalStorageRevenue) : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> % 'Other Income'  </td>
                                                            <td className="highlight-column bg-table">{valuationResult.otherIncomePercentage ? (valuationResult.otherIncomePercentage).toFixed(2) : 0}% </td>
                                                            <td>{valuationResult.otherIncomePercentage ? (valuationResult.otherIncomePercentage).toFixed(2) : 0}% </td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{valuationResult.otherIncomePercentage ? (valuationResult.otherIncomePercentage).toFixed(2) : 0}% </td>
                                                                <td>{valuationResult.otherIncomePercentage ? (valuationResult.otherIncomePercentage).toFixed(2) : 0}% </td>
                                                            </>
                                                            }
                                                            <td>{valuationResult.otherIncomePercentage ? (valuationResult.otherIncomePercentage).toFixed(2) : 0}% </td>
                                                            <td className="table-red-bg">{sensitivityOutput.percentageOtherIncomeWithOtherIncome ? parseFloat(sensitivityOutput.percentageOtherIncomeWithOtherIncome).toFixed(2) : 0}%</td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table table-red-bg">{sensitivityOutput.percentageOtherIncomeWithFullyOptimised ? parseFloat(sensitivityOutput.percentageOtherIncomeWithFullyOptimised).toFixed(2) : 0}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> 'Other Income'  </td>
                                                            <td className="highlight-column bg-table">{valuationResult.otherIncome ? formatterWithoutDecimals.format(valuationResult.otherIncome) : ''}</td>
                                                            <td>{sensitivityOutput.OtherIncomeWithFeeRatePositive ? formatterWithoutDecimals.format(sensitivityOutput.OtherIncomeWithFeeRatePositive) : ''}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{sensitivityOutput.OtherIncomeWithFeeRateNegative ? formatterWithoutDecimals.format(sensitivityOutput.OtherIncomeWithFeeRateNegative) : ''}</td>
                                                                <td>{sensitivityOutput.OtherIncomeWithOccupancyPositive ? formatterWithoutDecimals.format(sensitivityOutput.OtherIncomeWithOccupancyPositive) : ''}</td>
                                                            </>
                                                            }
                                                            <td>{sensitivityOutput.OtherIncomeWithOccupancyNegative ? formatterWithoutDecimals.format(sensitivityOutput.OtherIncomeWithOccupancyNegative) : ''}</td>
                                                            <td>{sensitivityOutput.OtherIncomeWithOtherIncome ? formatterWithoutDecimals.format(sensitivityOutput.OtherIncomeWithOtherIncome) : ''}</td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}
                                                            <td className="bg-table">{sensitivityOutput.OtherIncomeWithFullyOptimised ? formatterWithoutDecimals.format(sensitivityOutput.OtherIncomeWithFullyOptimised) : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Gross Storage Revenue </td>
                                                            <td className="bg-table">{valuationResult.grossStorageRevenue ? formatterWithoutDecimals.format(valuationResult.grossStorageRevenue) : ''}</td>
                                                            <td>{sensitivityOutput.GrossStorageRevenueWithFeeRatePositive ? formatterWithoutDecimals.format(sensitivityOutput.GrossStorageRevenueWithFeeRatePositive) : ''}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{sensitivityOutput.GrossStorageRevenueWithFeeRateNegative ? formatterWithoutDecimals.format(sensitivityOutput.GrossStorageRevenueWithFeeRateNegative) : ''}</td>
                                                                <td>{sensitivityOutput.GrossStorageRevenueWithOccupancyPositive ? formatterWithoutDecimals.format(sensitivityOutput.GrossStorageRevenueWithOccupancyPositive) : ''}</td>
                                                            </>}
                                                            <td>{sensitivityOutput.GrossStorageRevenueWithOccupancyNegative ? formatterWithoutDecimals.format(sensitivityOutput.GrossStorageRevenueWithOccupancyNegative) : ''}</td>
                                                            <td>{sensitivityOutput.GrossStorageRevenueWithOtherIncome ? formatterWithoutDecimals.format(sensitivityOutput.GrossStorageRevenueWithOtherIncome) : ''}</td>
                                                            <td>{valuationResult.grossStorageRevenue ? formatterWithoutDecimals.format(valuationResult.grossStorageRevenue) : ''}</td>
                                                            <td>{valuationResult.grossStorageRevenue ? formatterWithoutDecimals.format(valuationResult.grossStorageRevenue) : ''}</td>

                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table">{sensitivityOutput.GrossStorageRevenue ? formatterWithoutDecimals.format(sensitivityOutput.GrossStorageRevenueWithFullyOptimised) : ''}</td>
                                                            {/* className="bg-table" <td>{ sensitivityOutput.GrossStorageRevenueWithOtherIncome ? formatterWithoutDecimals.format(sensitivityOutput.GrossStorageRevenueWithOtherIncome) :''}</td> */}
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Non-Storage Income  </td>
                                                            <td className="bg-table">{valuationResult.nonStorageIncome ? formatterWithoutDecimals.format(valuationResult.nonStorageIncome) : '$0'}</td>
                                                            <td>{valuationResult.nonStorageIncome ? formatterWithoutDecimals.format(valuationResult.nonStorageIncome) : '$0'}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{valuationResult.nonStorageIncome ? formatterWithoutDecimals.format(valuationResult.nonStorageIncome) : '$0'}</td>
                                                                <td>{valuationResult.nonStorageIncome ? formatterWithoutDecimals.format(valuationResult.nonStorageIncome) : '$0'}</td>
                                                            </>}
                                                            <td>{valuationResult.nonStorageIncome ? formatterWithoutDecimals.format(valuationResult.nonStorageIncome) : '$0'}</td>
                                                            <td>{valuationResult.nonStorageIncome ? formatterWithoutDecimals.format(valuationResult.nonStorageIncome) : '$0'}</td>
                                                            <td>{valuationResult.nonStorageIncome ? formatterWithoutDecimals.format(valuationResult.nonStorageIncome) : '$0'}</td>
                                                            <td>{valuationResult.nonStorageIncome ? formatterWithoutDecimals.format(valuationResult.nonStorageIncome) : '$0'}</td>

                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table">{valuationResult.nonStorageIncome ? formatterWithoutDecimals.format(parseFloat(valuationResult.nonStorageIncome)) : '$0'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Total Gross Income  </td>
                                                            <td className="bg-table">{valuationResult.totalGrossIncome ? formatterWithoutDecimals.format(valuationResult.totalGrossIncome) : ''}</td>
                                                            <td>{sensitivityOutput.TotalGrossIncomeWithFeeRatePositive ? formatterWithoutDecimals.format(sensitivityOutput.TotalGrossIncomeWithFeeRatePositive) : ''}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{sensitivityOutput.TotalGrossIncomeWithFeeRateNegative ? formatterWithoutDecimals.format(sensitivityOutput.TotalGrossIncomeWithFeeRateNegative) : ''}</td>
                                                                <td>{sensitivityOutput.TotalGrossIncomeWithOccupancyPositive ? formatterWithoutDecimals.format(sensitivityOutput.TotalGrossIncomeWithOccupancyPositive) : ''}</td>
                                                            </>}
                                                            <td>{sensitivityOutput.TotalGrossIncomeWithOccupancyNegative ? formatterWithoutDecimals.format(sensitivityOutput.TotalGrossIncomeWithOccupancyNegative) : ''}</td>
                                                            <td>{sensitivityOutput.TotalGrossIncomeWithOtherIncome ? formatterWithoutDecimals.format(sensitivityOutput.TotalGrossIncomeWithOtherIncome) : ''}</td>
                                                            <td>{sensitivityOutput.TotalGrossIncomeWithExpense ? formatterWithoutDecimals.format(sensitivityOutput.TotalGrossIncomeWithExpense) : ''}</td>
                                                            <td>{sensitivityOutput.TotalGrossIncomeWithExpense ? formatterWithoutDecimals.format(sensitivityOutput.TotalGrossIncomeWithExpense) : ''}</td>

                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}
                                                            {/*<td>{ formatterWithoutDecimals.format( GrossStorageRevenue + valuationResult.nonStorageIncome) }</td>*/}
                                                            <td className="bg-table">{sensitivityOutput.TotalGrossIncomeWithFullyOptimised ? formatterWithoutDecimals.format(sensitivityOutput.TotalGrossIncomeWithFullyOptimised) : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Operating Expenses </td>
                                                            <td className="color-red-table bg-table">{valuationResult.operatingExpenses ? formatterWithoutDecimals.format(valuationResult.operatingExpenses) : ''}</td>
                                                            <td className="color-red-table">{valuationResult.operatingExpenses ? formatterWithoutDecimals.format(valuationResult.operatingExpenses) : ''}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td className="color-red-table">{valuationResult.operatingExpenses ? formatterWithoutDecimals.format(valuationResult.operatingExpenses) : ''}</td>
                                                                <td className="color-red-table">{valuationResult.operatingExpenses ? formatterWithoutDecimals.format(valuationResult.operatingExpenses) : ''}</td>
                                                            </>}
                                                            <td className="color-red-table">{valuationResult.operatingExpenses ? formatterWithoutDecimals.format(valuationResult.operatingExpenses) : ''}</td>
                                                            <td className="color-red-table">{valuationResult.operatingExpenses ? formatterWithoutDecimals.format(valuationResult.operatingExpenses) : ''}</td>
                                                            <td className="color-red-table table-red-bg">{sensitivityOutput.operatingExpensesWithExpenseNegative ? formatterWithoutDecimals.format(sensitivityOutput.operatingExpensesWithExpenseNegative) : ''}</td>
                                                            <td className="color-red-table table-red-bg">{sensitivityOutput.operatingExpensesWithExpensePositive ? formatterWithoutDecimals.format(sensitivityOutput.operatingExpensesWithExpensePositive) : ''}</td>

                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table color-red-table table-red-bg">{sensitivityOutput.OperatingExpensesWithFullyOptimised ? formatterWithoutDecimals.format(sensitivityOutput.OperatingExpensesWithFullyOptimised) : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Storage Management Fee </td>
                                                            <td className="color-red-table bg-table">{valuationResult.managementFeeOnStorageRevenue ? formatterWithoutDecimals.format(valuationResult.managementFeeOnStorageRevenue) : ''}</td>
                                                            <td className="color-red-table">{sensitivityOutput.storageManagementFeeWithFeeRatePositive ? formatterWithoutDecimals.format(sensitivityOutput.storageManagementFeeWithFeeRatePositive) : ''}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td className="color-red-table">{sensitivityOutput.storageManagementFeeWithFeeRateNegative ? formatterWithoutDecimals.format(sensitivityOutput.storageManagementFeeWithFeeRateNegative) : ''}</td>
                                                                <td className="color-red-table">{sensitivityOutput.storageManagementFeeWithOccupancyPositive ? formatterWithoutDecimals.format(sensitivityOutput.storageManagementFeeWithOccupancyPositive) : ''}</td>
                                                            </>}
                                                            <td className="color-red-table">{sensitivityOutput.storageManagementFeeWithOccupancyPositive ? formatterWithoutDecimals.format(sensitivityOutput.storageManagementFeeWithOccupancyNegative) : ''}</td>
                                                            <td className="color-red-table">{sensitivityOutput.storageManagementFeeWithOtherIncome ? formatterWithoutDecimals.format(sensitivityOutput.storageManagementFeeWithOtherIncome) : ''}</td>
                                                            <td className="color-red-table">{sensitivityOutput.storageManagementFeeWithExpenseNegative ? formatterWithoutDecimals.format(sensitivityOutput.storageManagementFeeWithExpenseNegative) : ''}</td>
                                                            <td className="color-red-table">{sensitivityOutput.storageManagementFeeWithExpensePositive ? formatterWithoutDecimals.format(sensitivityOutput.storageManagementFeeWithExpensePositive) : ''}</td>

                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table color-red-table">{sensitivityOutput.storageManagementFeeWithFullyOptimised ? formatterWithoutDecimals.format(sensitivityOutput.storageManagementFeeWithFullyOptimised) : ''}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Non-Storage Management Fee </td>
                                                            <td className="color-red-table  bg-table">{formatterWithoutDecimals.format(sensitivityOutput.nonStorageManagementFee)}</td>
                                                            <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.nonStorageManagementFee)}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.nonStorageManagementFee)}</td>
                                                                <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.nonStorageManagementFee)}</td>
                                                            </>}
                                                            <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.nonStorageManagementFee)}</td>
                                                            <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.nonStorageManagementFee)}</td>
                                                            <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.nonStorageManagementFee)}</td>
                                                            <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.nonStorageManagementFee)}</td>

                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.nonStorageManagementFee)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Total Expenses  </td>
                                                            <td className="color-red-table bg-table">{valuationResult && !isNaN(valuationResult.totalOperatingExpenses) ? formatterWithoutDecimals.format(valuationResult.totalOperatingExpenses) : "$" + 0}</td>
                                                            <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.TotalExpensesWithFeeRatePositive)}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.TotalExpensesWithFeeRateNegative)}</td>
                                                                <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.TotalExpensesWithOccupancyPositive)}</td>
                                                            </>}
                                                            <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.TotalExpensesWithOccupancyNegative)}</td>
                                                            <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.TotalExpensesWithOtherIncome)}</td>
                                                            <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.TotalExpensesWithExpenseNegative)}</td>
                                                            <td className="color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.TotalExpensesWithExpensePositive)}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table color-red-table">{formatterWithoutDecimals.format(sensitivityOutput.TotalExpensesWithFullyOptimised)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Net Operating Profit  </td>
                                                            <td className="bg-table">{valuationResult && !isNaN(valuationResult.netOperatingProfit) ? formatterWithoutDecimals.format(valuationResult.netOperatingProfit) : "$" + 0}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.NetOpertaingProfitWithFeeRatePositive)}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{formatterWithoutDecimals.format(sensitivityOutput.NetOpertaingProfitWithFeeRateNegative)}</td>
                                                                <td>{formatterWithoutDecimals.format(sensitivityOutput.NetOpertaingProfitWithOccupancyPositive)}</td>
                                                            </>}
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.NetOpertaingProfitWithOccupancyNegative)}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.NetOpertaingProfitWithOtherIncome)}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.NetOpertaingProfitWithExpenseNegative)}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.NetOpertaingProfitWithExpensePositive)}</td>

                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table">{formatterWithoutDecimals.format(sensitivityOutput.NetOperatingProfitWithFullyOptimised)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Capitalisation Rate </td>
                                                            <td className="bg-table">{valuationResult && !isNaN(valuationResult.capitalisationRate) ? parseFloat(valuationResult.capitalisationRate).toFixed(2) : 0}%</td>
                                                            <td>{sensitivityOutput && !isNaN(sensitivityOutput.CapitalisationRateWithFeeRate) ? parseFloat(sensitivityOutput.CapitalisationRateWithFeeRate).toFixed(2) : 0}%</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{sensitivityOutput && !isNaN(sensitivityOutput.CapitalisationRateWithFeeRate) ? parseFloat(sensitivityOutput.CapitalisationRateWithFeeRate).toFixed(2) : 0}%</td>
                                                                <td>{sensitivityOutput && !isNaN(sensitivityOutput.CapitalisationRateWithOccupancyPositive) ? parseFloat(sensitivityOutput.CapitalisationRateWithOccupancyPositive).toFixed(2) : 0}%</td>
                                                            </>}
                                                            <td>{sensitivityOutput && !isNaN(sensitivityOutput.CapitalisationRateWithOccupancyNegative) ? parseFloat(sensitivityOutput.CapitalisationRateWithOccupancyNegative).toFixed(2) : 0}%</td>
                                                            <td>{sensitivityOutput && !isNaN(sensitivityOutput.CapitalisationRateWithOtherIncome) ? parseFloat(sensitivityOutput.CapitalisationRateWithOtherIncome).toFixed(2) : 0}%</td>
                                                            <td>{sensitivityOutput && !isNaN(sensitivityOutput.CapitalisationRateWithExpenseNegative) ? parseFloat(sensitivityOutput.CapitalisationRateWithExpenseNegative).toFixed(2) : 0}%</td>
                                                            <td>{sensitivityOutput && !isNaN(sensitivityOutput.CapitalisationRateWithExpensePositive) ? parseFloat(sensitivityOutput.CapitalisationRateWithExpensePositive).toFixed(2) : 0}%</td>
                                                            {valuationResult.adoptedValuation === 'Static'
                                                                ? <>
                                                                    <td>{sensitivityOutput && !isNaN(sensitivityOutput.CapitalisationRateWithCapitalisationRatePositive) ? parseFloat(sensitivityOutput.CapitalisationRateWithCapitalisationRatePositive).toFixed(2) : 0}%</td>
                                                                    <td>{sensitivityOutput && !isNaN(sensitivityOutput.CapitalisationRateWithCapitalisationRateNegative) ? parseFloat(sensitivityOutput.CapitalisationRateWithCapitalisationRateNegative).toFixed(2) : 0}%</td>
                                                                </>
                                                                : ""}

                                                            <td className="bg-table">{parseFloat(sensitivityOutput.CapitalisationRateWithFullyOptimised).toFixed(2)}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Core Market Value  </td>
                                                            <td className="bg-table">{valuationResult && !isNaN(valuationResult.coreMarketValue) ? formatterWithoutDecimals.format(valuationResult.coreMarketValue) : "$" + 0}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.CoreMarketValueWithFreeRatePositive)}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{formatterWithoutDecimals.format(sensitivityOutput.CoreMarketValueWithFreeRateNegative)}</td>
                                                                <td>{formatterWithoutDecimals.format(sensitivityOutput.CoreMarketValueWithOccupancyPositive)}</td>
                                                            </>}
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.CoreMarketValueWithOccupancyNegative)}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.CoreMarketValueWithOtherIncome)}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.CoreMarketValueWithExpenseNegative)}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.CoreMarketValueWithExpensePositive)}</td>
                                                            {valuationResult.adoptedValuation === 'Static'
                                                                ? <>
                                                                    <td>{formatterWithoutDecimals.format(sensitivityOutput.CoreMarketValueWithCapitalisationRatePositive)}</td>
                                                                    <td>{formatterWithoutDecimals.format(sensitivityOutput.CoreMarketValueWithCapitalisationRateNegative)}</td>
                                                                </>
                                                                : ""}
                                                            <td className="bg-table">{formatterWithoutDecimals.format(parseFloat(sensitivityOutput.CoreMarketValue))}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Storage Rental Shortfall  </td>
                                                            <td className="color-red-table bg-table">{valuationResult && valuationResult.adoptedValuation && valuationResult.adoptedValuation !== 'Static'
                                                                ? formatterWithoutDecimals.format(valuationResult.storageRentalShortfall)
                                                                : "$0"}</td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td></td>
                                                                <td></td>
                                                            </>}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table color-red-table">{valuationResult && valuationResult.adoptedValuation && valuationResult.adoptedValuation !== 'Static'
                                                                ? formatterWithoutDecimals.format(valuationResult.storageRentalShortfall)
                                                                : "$0"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Non-Storage Rental Shortfall </td>
                                                            <td className="color-red-table bg-table">{valuationResult && !isNaN(valuationResult.nonStorageRentalShortfall) ? formatterWithoutDecimals.format(valuationResult.nonStorageRentalShortfall) : "$" + 0}</td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td></td>
                                                                <td></td>
                                                            </>}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table color-red-table">{valuationResult && !isNaN(valuationResult.nonStorageRentalShortfall) ? formatterWithoutDecimals.format(valuationResult.nonStorageRentalShortfall) : "$" + 0}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Capital Expenditure </td>
                                                            <td className="color-red-table bg-table">
                                                                {valuationResult &&
                                                                    valuationResult.adoptedValuation === 'Static'
                                                                    ? (!isNaN(valuationResult.capitalExpenditure) ? formatterWithoutDecimals.format(valuationResult.capitalExpenditure) : "$" + 0)
                                                                    : (!isNaN(valuationResult.costOfExpansion) ? formatterWithoutDecimals.format(valuationResult.costOfExpansion) : "$" + 0)
                                                                }
                                                            </td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td></td>
                                                                <td></td>
                                                            </>}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="color-red-table bg-table">
                                                                {valuationResult &&
                                                                    valuationResult.adoptedValuation === 'Static'
                                                                    ? (!isNaN(valuationResult.capitalExpenditure) ? formatterWithoutDecimals.format(valuationResult.capitalExpenditure) : "$" + 0)
                                                                    : (!isNaN(valuationResult.costOfExpansion) ? formatterWithoutDecimals.format(valuationResult.costOfExpansion) : "$" + 0)
                                                                }
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Value of Additional Areas </td>
                                                            <td className="bg-table">{valuationResult && !isNaN(valuationResult.valueOfAdditionalAreas) ? formatterWithoutDecimals.format(valuationResult.valueOfAdditionalAreas) : "$" + 0}</td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td></td>
                                                                <td></td>
                                                            </>}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table">{valuationResult && !isNaN(valuationResult.valueOfAdditionalAreas) ? formatterWithoutDecimals.format(valuationResult.valueOfAdditionalAreas) : "$" + 0} </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Other 'below the line' adjustment </td>
                                                            <td className="bg-table">{valuationResult && !isNaN(valuationResult.otherBelowTheLineAdjustment) ? formatterWithoutDecimals.format(valuationResult.otherBelowTheLineAdjustment) : "$" + 0}</td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td></td>
                                                                <td></td>
                                                            </>}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {valuationResult.adoptedValuation === 'Static' ? <><td></td><td></td></> : ""}

                                                            <td className="bg-table">{valuationResult && !isNaN(valuationResult.otherBelowTheLineAdjustment) ? formatterWithoutDecimals.format(valuationResult.otherBelowTheLineAdjustment) : "$" + 0}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> {valuationResult.adoptedValuation} Value  </td>
                                                            <td className="bg-table">{valuationResult && !isNaN(valuationResult.adoptedValue) ? formatterWithoutDecimals.format(valuationResult.adoptedValue) : "$" + 0}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.StaticValueWithFreeRatePositive)}</td>

                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{formatterWithoutDecimals.format(sensitivityOutput.StaticValueWithFreeRateNegative)}</td>
                                                                <td>{formatterWithoutDecimals.format(sensitivityOutput.StaticValueWithOccupancyPositive)}</td>
                                                            </>}

                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.StaticValueWithOccupancyNegative)}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.StaticValueWithOtherIncome)}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.StaticValueWithExpenseNegative)}</td>
                                                            <td>{formatterWithoutDecimals.format(sensitivityOutput.StaticValueWithExpensePositive)}</td>

                                                            {valuationResult.adoptedValuation === 'Static'
                                                                ? <>
                                                                    <td>{formatterWithoutDecimals.format(sensitivityOutput.StaticValueWithCapitalisationRatePositive)}</td>
                                                                    <td>{formatterWithoutDecimals.format(sensitivityOutput.StaticValueWithCapitalisationRateNegative)}</td>
                                                                </>
                                                                : ""
                                                            }
                                                            <td className="bg-table">{formatterWithoutDecimals.format(sensitivityOutput.StaticValueWithFullyOptimised)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="theme-color-bg sticky-col first-col"> Uplift  </td>
                                                            <td className="bg-table"></td>
                                                            <td>{sensitivityOutput.UpliftWithFeeRatePositive ? (sensitivityOutput.UpliftWithFeeRatePositive).toFixed(2) : 0}%</td>

                                                            {valuationResult.adoptedValuation === 'Static' ? "" : <>
                                                                <td>{sensitivityOutput.UpliftWithFeeRateNegative ? (sensitivityOutput.UpliftWithFeeRateNegative).toFixed(2) : 0}%</td>
                                                                <td>{sensitivityOutput.UpliftWithOccupancyPositive ? (sensitivityOutput.UpliftWithOccupancyPositive).toFixed(2) : 0}%</td>
                                                            </>}

                                                            <td>{sensitivityOutput.UpliftWithOccupancyNegative ? (sensitivityOutput.UpliftWithOccupancyNegative).toFixed(2) : 0}%</td>
                                                            <td>{sensitivityOutput.UpliftWithOtherIncome ? (sensitivityOutput.UpliftWithOtherIncome).toFixed(2) : 0}%</td>
                                                            <td>{sensitivityOutput.UpliftWithExpenseNegative ? (sensitivityOutput.UpliftWithExpenseNegative).toFixed(2) : 0}%</td>
                                                            <td>{sensitivityOutput.UpliftWithExpensePositive ? (sensitivityOutput.UpliftWithExpensePositive).toFixed(2) : 0}%</td>

                                                            {valuationResult.adoptedValuation === 'Static'
                                                                ? <>
                                                                    <td>{sensitivityOutput.UpliftWithCapitalisationRatePositive ? (sensitivityOutput.UpliftWithCapitalisationRatePositive).toFixed(2) : 0}%</td>
                                                                    <td>{sensitivityOutput.UpliftWithCapitalisationRateNegative ? (sensitivityOutput.UpliftWithCapitalisationRateNegative).toFixed(2) : 0}%</td>
                                                                </>
                                                                : ""
                                                            }
                                                            <td className="bg-table">{sensitivityOutput.UpliftWithFullyOptimised ? (sensitivityOutput.UpliftWithFullyOptimised).toFixed(2) : 0}%</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        user.role !== 'SuperAdmin' && valuationResult.adoptedValuation === 'Static' && (
                                            <div className="col-xxl-8 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 view">
                                                <div className="facilityTable table-responsive">
                                                    <table className="table table-bordered table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sensitivity Output</th>
                                                                <th scope="col">Current Assessment</th>
                                                                <th scope="col" colSpan={4}>{valuationResult && !isNaN(parseFloat(valuationResult.adoptedValue)) ? formatterWithoutDecimals.format(parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) : '$0'}</th>
                                                            </tr>
                                                            <tr>
                                                                <th scope="col" className="table-blank-space"></th>
                                                            </tr>
                                                            <tr>
                                                                <th scope="col">Condition</th>
                                                                <th scope="col">Result</th>
                                                                <th scope="col">Movement</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>If Fee Rate increases by 5% and occupancy holds</td>
                                                                <td>{sensitivityOutput && !isNaN(sensitivityOutput.StaticValueWithFreeRatePositive) ? formatterWithoutDecimals.format(parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithFreeRatePositive / 10000))) : 0}</td>
                                                                <td>
                                                                    {isNaN(sensitivityOutput.StaticValueWithFreeRatePositive) ||
                                                                    isNaN(valuationResult.adoptedValue) ||
                                                                    parseFloat(sensitivityOutput.StaticValueWithFreeRatePositive) === 0 ||
                                                                    parseFloat(valuationResult.adoptedValue) === 0
                                                                        ? 0
                                                                        : (
                                                                            (parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithFreeRatePositive / 10000)) -
                                                                            parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) /
                                                                            parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100
                                                                        ).toFixed(2)
                                                                    }%
                                                                </td>
                                                            </tr>
                                                            <tr className="bg-table">
                                                                <td>If Fee Rate increases by 5% and occupancy drops by 2.5%</td>
                                                                <td>{formatterWithoutDecimals.format(parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithOccupancyNegative / 10000)))}</td>
                                                                <td>{
                                                                    isNaN(sensitivityOutput.StaticValueWithOccupancyNegative) || 
                                                                    isNaN(valuationResult.adoptedValue) ||
                                                                    parseFloat(sensitivityOutput.StaticValueWithOccupancyNegative) === 0 ||
                                                                    parseFloat(valuationResult.adoptedValue) === 0 ? 0 :
                                                                        (
                                                                            (parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithOccupancyNegative / 10000)) -
                                                                                parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) /
                                                                            parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100
                                                                        ).toFixed(2)
                                                                }%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>If Other Income increases by 1%</td>
                                                                <td>{formatterWithoutDecimals.format(parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithOtherIncome / 10000)))}</td>
                                                                <td>{
                                                                    isNaN(sensitivityOutput.StaticValueWithOtherIncome) || isNaN(valuationResult.adoptedValue) ||
                                                                    parseFloat(sensitivityOutput.StaticValueWithOtherIncome) === 0 ||
                                                                    parseFloat(valuationResult.adoptedValue) === 0 ? 0 :
                                                                        (
                                                                            (parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithOtherIncome / 10000)) -
                                                                                parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) /
                                                                            parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100
                                                                        ).toFixed(2)
                                                                }%</td>
                                                            </tr>
                                                            <tr className="bg-table">
                                                                <td>If Operating Expenses decrease by 5%</td>
                                                                <td>{formatterWithoutDecimals.format(parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithExpenseNegative / 10000)))}</td>
                                                                <td>{
                                                                    isNaN(sensitivityOutput.StaticValueWithExpenseNegative) || isNaN(valuationResult.adoptedValue) ||
                                                                    parseFloat(sensitivityOutput.StaticValueWithExpenseNegative) === 0 ||
                                                                    parseFloat(valuationResult.adoptedValue) === 0 ? 0 :
                                                                        (
                                                                            (parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithExpenseNegative / 10000)) -
                                                                                parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) /
                                                                            parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100
                                                                        ).toFixed(2)
                                                                }%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>If Operating Expenses increase by 5%</td>
                                                                <td>{formatterWithoutDecimals.format(parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithExpensePositive / 10000)))}</td>
                                                                <td>{
                                                                    isNaN(sensitivityOutput.StaticValueWithExpensePositive) || isNaN(valuationResult.adoptedValue) ||
                                                                    parseFloat(sensitivityOutput.StaticValueWithExpensePositive) === 0 ||
                                                                    parseFloat(valuationResult.adoptedValue) === 0 ? 0 :
                                                                        (
                                                                            (parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithExpensePositive / 10000)) -
                                                                                parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) /
                                                                            parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100
                                                                        ).toFixed(2)
                                                                }%</td>
                                                            </tr>
                                                            <tr className="bg-table">
                                                                <td>If the Capitalisation Rate softens by 25 bps</td>
                                                                <td>{formatterWithoutDecimals.format(parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithCapitalisationRatePositive / 10000)))}</td>
                                                                <td>{
                                                                    isNaN(sensitivityOutput.StaticValueWithCapitalisationRatePositive) || isNaN(valuationResult.adoptedValue) ||
                                                                    parseFloat(sensitivityOutput.StaticValueWithCapitalisationRatePositive) === 0 ||
                                                                    parseFloat(valuationResult.adoptedValue) === 0 ? 0 :
                                                                        (
                                                                            (parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithCapitalisationRatePositive / 10000)) -
                                                                                parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) /
                                                                            parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100
                                                                        ).toFixed(2)
                                                                }%</td>
                                                            </tr>
                                                            <tr>
                                                                <td>If the Capitalisation Rate sharpens by 25 bps</td>
                                                                <td>{formatterWithoutDecimals.format(parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithCapitalisationRateNegative / 10000)))}</td>
                                                                <td>{
                                                                    isNaN(sensitivityOutput.StaticValueWithCapitalisationRateNegative) || isNaN(valuationResult.adoptedValue) ||
                                                                    parseFloat(sensitivityOutput.StaticValueWithCapitalisationRateNegative) === 0 ||
                                                                    parseFloat(valuationResult.adoptedValue) === 0 ? 0 :
                                                                        (
                                                                            (parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithCapitalisationRateNegative / 10000)) -
                                                                                parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) /
                                                                            parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100
                                                                        ).toFixed(2)
                                                                }%</td>
                                                            </tr>
                                                            <tr className="bg-table">
                                                                <td colSpan="3"><hr /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>If all positive revenue and market instances occur</td>
                                                                <td>{formatterWithoutDecimals.format(parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithFullyOptimised / 10000)))}</td>
                                                                <td>{
                                                                    isNaN(sensitivityOutput.StaticValueWithFullyOptimised) || isNaN(valuationResult.adoptedValue) ||
                                                                    parseFloat(sensitivityOutput.StaticValueWithFullyOptimised) === 0 ||
                                                                    parseFloat(valuationResult.adoptedValue) === 0 ? 0 :
                                                                        (
                                                                            (parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithFullyOptimised / 10000)) -
                                                                                parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) /
                                                                            parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100
                                                                        ).toFixed(2)
                                                                }%</td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>)}
                                    {user.role !== 'SuperAdmin' && valuationResult.adoptedValuation === 'BuildUp' && (
                                        <div className="col-xxl-8 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 view">
                                            <div className="facilityTable table-responsive">
                                                <table className="table table-bordered table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Sensitivity Output</th>
                                                            <th scope="col">Current Assessment</th>
                                                            <th scope="col" colSpan={4}>{valuationResult && !isNaN(parseFloat(valuationResult.adoptedValue)) ? formatterWithoutDecimals.format(parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) : '$0'}</th>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col" className="table-blank-space"></th>
                                                        </tr>
                                                        <tr>
                                                            <th scope="col">Condition</th>
                                                            <th scope="col">Result</th>
                                                            <th scope="col">Movement</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>If Mature Fee Rate is 5% higher than projected (same occupancy)</td>
                                                            <td>{buildupData && buildupData.higherFeeRateResponse && !isNaN(parseFloat(buildupData.higherFeeRateResponse.adoptedValue)) ? formatterWithoutDecimals.format(parseFloat(
                                                                10000 * Math.round(parseFloat(buildupData.higherFeeRateResponse.adoptedValue) / 10000))) : '$0'}</td>
                                                            <td>{buildupData && 
                                                                valuationResult && 
                                                                buildupData.higherFeeRateResponse && 
                                                                !isNaN(valuationResult.adoptedValue) && 
                                                                !isNaN(buildupData.higherFeeRateResponse.adoptedValue) && 
                                                                parseFloat(valuationResult.adoptedValue) !== 0 &&
                                                                parseFloat(buildupData.higherFeeRateResponse.adoptedValue) !== 0 
                                                                ? ((parseFloat(
                                                                    10000 * Math.round(parseFloat(buildupData.higherFeeRateResponse.adoptedValue) / 10000)) - parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) / parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100).toFixed(2) : 0}%
                                                            </td>

                                                        </tr>
                                                        <tr className="bg-table">
                                                            <td>If Mature Fee Rate is 5% lower than projected (same occupancy) </td>
                                                            <td>{sensitivityOutput && !isNaN(parseFloat(sensitivityOutput.StaticValueWithFreeRateNegative)) ? formatterWithoutDecimals.format(parseFloat(
                                                                10000 * Math.round(sensitivityOutput.StaticValueWithFreeRateNegative / 10000))) : '$0'}</td>
                                                            <td>{sensitivityOutput && 
                                                            valuationResult && 
                                                            !isNaN(valuationResult.adoptedValue) && 
                                                            !isNaN(sensitivityOutput.StaticValueWithFreeRateNegative) && 
                                                            parseFloat(valuationResult.adoptedValue) !== 0 &&
                                                            parseFloat(sensitivityOutput.StaticValueWithFreeRateNegative) !== 0  
                                                                ? ((parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithFreeRateNegative / 10000)) - parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) / parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100).toFixed(2) : 0}%
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>If Occupancy is 2.5% higher than projected (same mature fee rate)</td>
                                                            <td>{sensitivityOutput && !isNaN(parseFloat(sensitivityOutput.StaticValueWithOccupancyPositive)) ? formatterWithoutDecimals.format(parseFloat(
                                                                10000 * Math.round(sensitivityOutput.StaticValueWithOccupancyPositive / 10000))) : '$0'}</td>
                                                            <td>{sensitivityOutput && 
                                                            valuationResult && 
                                                            !isNaN(valuationResult.adoptedValue) && 
                                                            !isNaN(sensitivityOutput.StaticValueWithOccupancyPositive) && 
                                                            parseFloat(valuationResult.adoptedValue) !== 0 &&
                                                            parseFloat(sensitivityOutput.StaticValueWithOccupancyPositive) !== 0  
                                                                ? ((parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithOccupancyPositive / 10000)) - parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) / parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100).toFixed(2) : 0}%
                                                            </td>
                                                        </tr>
                                                        <tr className="bg-table">
                                                            <td>If Occupancy is 2.5% lower than projected (same mature fee rate)</td>
                                                            <td>{sensitivityOutput && !isNaN(parseFloat(sensitivityOutput.StaticValueWithOccupancyNegative)) ? formatterWithoutDecimals.format(parseFloat(
                                                                10000 * Math.round(sensitivityOutput.StaticValueWithOccupancyNegative / 10000))) : '$0'}</td>
                                                            <td>{sensitivityOutput && 
                                                                valuationResult && 
                                                                !isNaN(valuationResult.adoptedValue) && 
                                                                !isNaN(sensitivityOutput.StaticValueWithOccupancyNegative) && 
                                                                parseFloat(valuationResult.adoptedValue) !== 0 &&
                                                                parseFloat(sensitivityOutput.StaticValueWithOccupancyNegative) !== 0 
                                                                ? ((parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithOccupancyNegative / 10000)) - parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) / parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100).toFixed(2) : 0}%
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>If Other Income is 1% lower than projected</td>
                                                                <td>{sensitivityOutput && !isNaN(parseFloat(sensitivityOutput.StaticValueWithOtherIncome)) ? formatterWithoutDecimals.format(parseFloat(
                                                                    10000 * Math.round(sensitivityOutput.StaticValueWithOtherIncome / 10000))) : '$0'}</td>
                                                                <td>{sensitivityOutput && 
                                                                valuationResult && 
                                                                !isNaN(valuationResult.adoptedValue) && 
                                                                !isNaN(sensitivityOutput.StaticValueWithOtherIncome) && 
                                                                parseFloat(valuationResult.adoptedValue) !== 0 &&
                                                                parseFloat(sensitivityOutput.StaticValueWithOtherIncome) !== 0  
                                                                ? ((parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithOtherIncome / 10000)) - parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) / parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100).toFixed(2) : 0}%
                                                            </td>
                                                        </tr>
                                                        <tr className="bg-table">
                                                            <td>If Operating Expenses are 5% lower than projected</td>
                                                            <td>{sensitivityOutput && !isNaN(parseFloat(sensitivityOutput.StaticValueWithExpenseNegative)) ? formatterWithoutDecimals.format(parseFloat(
                                                                10000 * Math.round(sensitivityOutput.StaticValueWithExpenseNegative / 10000))) : '$0'}</td>
                                                            <td>{sensitivityOutput && 
                                                            valuationResult && 
                                                            !isNaN(valuationResult.adoptedValue) && 
                                                            !isNaN(sensitivityOutput.StaticValueWithExpenseNegative) && 
                                                            parseFloat(valuationResult.adoptedValue) !== 0 &&
                                                            parseFloat(sensitivityOutput.StaticValueWithExpenseNegative) !== 0  
                                                            ? ((parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithExpenseNegative / 10000)) - parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) / parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100).toFixed(2) : 0}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td>If Operating Expenses are 5% higher than projected</td>
                                                            <td>{sensitivityOutput && !isNaN(parseFloat(sensitivityOutput.StaticValueWithExpensePositive)) ? formatterWithoutDecimals.format(parseFloat(
                                                                10000 * Math.round(sensitivityOutput.StaticValueWithExpensePositive / 10000))) : '$0'}</td>
                                                            <td>{sensitivityOutput && 
                                                            valuationResult && 
                                                            !isNaN(valuationResult.adoptedValue) && 
                                                            !isNaN(sensitivityOutput.StaticValueWithExpensePositive) && 
                                                            parseFloat(valuationResult.adoptedValue) !== 0 &&
                                                            parseFloat(sensitivityOutput.StaticValueWithExpensePositive) !== 0
                                                            ? ((parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithExpensePositive / 10000)) - parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) / parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100).toFixed(2) : 0}%</td>
                                                        </tr>
                                                        <tr height="30px;">
                                                            <td colSpan="3"></td>
                                                        </tr>
                                                        <tr className="bg-table">
                                                            <td>If all results are weaker than the projections</td>
                                                            <td>{sensitivityOutput && !isNaN(parseFloat(sensitivityOutput.StaticValueWithFullyOptimised)) ? formatterWithoutDecimals.format(parseFloat(
                                                                10000 * Math.round(sensitivityOutput.StaticValueWithFullyOptimised / 10000))) : '$0'}</td>
                                                            <td>{sensitivityOutput && 
                                                            valuationResult && 
                                                            !isNaN(parseFloat(valuationResult.adoptedValue)) && 
                                                            !isNaN(parseFloat(sensitivityOutput.StaticValueWithFullyOptimised)) && 
                                                            parseFloat(valuationResult.adoptedValue) !== 0 &&
                                                            parseFloat(sensitivityOutput.StaticValueWithFullyOptimised) !== 0
                                                            ? ((parseFloat(10000 * Math.round(sensitivityOutput.StaticValueWithFullyOptimised / 10000)) - parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000))) / parseFloat(10000 * Math.round(valuationResult.adoptedValue / 10000)) * 100).toFixed(2) : 0}%</td>
                                                        </tr>
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    )}
                                    {user.role !== 'SuperAdmin' && valuationResult.adoptedValuation === 'BuildUp' && (
                                        <div className="col-xxl-8 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 mt-4 view">
                                            <div className="facilityTable table-responsive">
                                                <table className="table table-bordered table-sm">
                                                    <thead>

                                                        <tr>
                                                            <th className="bg-New" scope="col" style={{ color: "black!important" }}>Build Up</th>
                                                            <th scope="col">Adopted</th>
                                                            <th scope="col">High Occ.</th>
                                                            <th scope="col">High Fee Rate</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Occupancy</td>
                                                            <td>{valuationResult && !isNaN(valuationResult.occupancy) ? parseFloat(valuationResult.occupancy).toFixed(2) : 0}%</td>
                                                            <td>{buildupData && buildupData.higherOccupancyResponse && !isNaN(buildupData.higherOccupancyResponse.occupancy) ? parseFloat(buildupData.higherOccupancyResponse.occupancy).toFixed(2) : 0}%</td>
                                                            <td>{valuationResult && !isNaN(valuationResult.occupancy) ? parseFloat(valuationResult.occupancy).toFixed(2) : 0}%</td>
                                                        </tr>
                                                        <tr className="bg-table">
                                                            <td>Fee Rate</td>
                                                            <td>{valuationResult && !isNaN(buildupData.feeRate) ? formatterWithoutDecimals.format(parseFloat(valuationResult.feeRate)) : '$0'}</td>
                                                            <td>{buildupData && buildupData.higherOccupancyResponse && !isNaN(buildupData.higherOccupancyResponse.feeRate) ? formatterWithoutDecimals.format(parseFloat(buildupData.higherOccupancyResponse.feeRate)) : '$0'}</td>
                                                            <td>{buildupData && buildupData.higherFeeRateResponse && !isNaN(buildupData.higherFeeRateResponse.feeRate) ? formatterWithoutDecimals.format(parseFloat(buildupData.higherFeeRateResponse.feeRate)) : '$0'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Assessment</td>
                                                            <td>{valuationResult && !isNaN(parseFloat(valuationResult.adoptedValue)) ? formatterWithoutDecimals.format(parseFloat(valuationResult.adoptedValue)) : '$0'}</td>
                                                            <td>{buildupData && buildupData.higherOccupancyResponse && !isNaN(parseFloat(buildupData.higherOccupancyResponse.adoptedValue)) ? formatterWithoutDecimals.format(parseFloat(buildupData.higherOccupancyResponse.adoptedValue)) : '$0'}</td>
                                                            <td>{buildupData && buildupData.higherFeeRateResponse && !isNaN(parseFloat(buildupData.higherFeeRateResponse.adoptedValue)) ? formatterWithoutDecimals.format(parseFloat(buildupData.higherFeeRateResponse.adoptedValue)) : '$0'}</td>
                                                        </tr>
                                                        <tr className="bg-table">
                                                            <td>Uplift</td>
                                                            <td></td>
                                                            <td>{buildupData && buildupData.higherOccupancyResponse && !isNaN(buildupData.higherOccupancyResponse.overallPercentage) ? (parseFloat(buildupData.higherOccupancyResponse.overallPercentage)).toFixed(2) : 0}%</td>
                                                            <td>{buildupData && buildupData.higherFeeRateResponse && !isNaN(buildupData.higherFeeRateResponse.overallPercentage) ? (parseFloat(buildupData.higherFeeRateResponse.overallPercentage)).toFixed(2) : 0}%</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                        <div className="mb-3 mt-3">
                                            <h4 style={{ color: "#697a8d" }}>
                                                Please Select a
                                                {user.role === "SuperAdmin"
                                                    ? (selectedPortfolio === null ? " Portfolio!" : " Facility!")
                                                    : " Facility!"}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Layout>
        </>
    );
}